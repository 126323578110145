import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './MemoSection.css';
import defaultMemoTemplate from './assets/memo_templates/ary.txt';
import ChatPanel from './ChatPanel'; // Import the ChatPanel component
import { getBaseUrl } from './ApiContext';



const baseUrl = getBaseUrl();

const MemoSection = ({ dealId, initialMemo, onMemoUpdate, dealInfo }) => {
    const [memo, setMemo] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [showToolbar, setShowToolbar] = useState(false);
    const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
    const textareaRef = useRef(null);
    const toolbarRef = useRef(null);
    const contentRef = useRef(null);
    const previewRef = useRef(null);
    const [highlights, setHighlights] = useState([]);
    const [showChatPanel, setShowChatPanel] = useState(false); // State for chat panel

    const addHighlight = useCallback((selectedText) => {
        setHighlights(prev => {
            // Check if the selectedText already exists in the highlights array
            if (!prev.some(highlight => highlight === selectedText)) {
                return [...prev, selectedText];
            }
            return prev;
        });
    }, []);

    const saveMemo = useCallback(async () => {
        try {
            await axios.put(`${baseUrl}/api/deals/${dealId}`, { memo });
            onMemoUpdate(memo);
            console.log('Memo updated successfully');
        } catch (error) {
            console.error('Error updating memo:', error);
        }
    }, [dealId, memo, onMemoUpdate]);

    const handleBlur = () => {
        setIsEditing(false);
        setShowToolbar(false);
        saveMemo();
    };

    useEffect(() => {
        const loadInitialMemo = async () => {
            if (initialMemo) {
                setMemo(initialMemo);
            } else {
                try {
                    const response = await fetch(defaultMemoTemplate);
                    const text = await response.text();
                    setMemo(text);
                } catch (error) {
                    console.error('Error loading default memo template:', error);
                    setMemo(''); // Set an empty string if loading fails
                }
            }
        };

        loadInitialMemo();
    }, [initialMemo]);

    const handleMemoChange = (e) => {
        const { value } = e.target;
        setMemo(value);
    };

    const handleFocus = () => {
        setIsEditing(true);
    };

    const handlePreviewClick = (e) => {
        e.stopPropagation();
        setIsEditing(true);
        setTimeout(() => {
            if (textareaRef.current && contentRef.current) {
                const contentRect = contentRef.current.getBoundingClientRect();
                const clickX = e.clientX - contentRect.left;
                const clickY = e.clientY - contentRect.top;

                // Create a temporary hidden div to measure text
                const measurer = document.createElement('div');
                measurer.style.position = 'absolute';
                measurer.style.visibility = 'hidden';
                measurer.style.height = 'auto';
                measurer.style.width = 'auto';
                measurer.style.whiteSpace = 'pre-wrap';
                measurer.style.font = window.getComputedStyle(contentRef.current).font;
                document.body.appendChild(measurer);

                const lines = memo.split('\n');
                let totalHeight = 0;
                let cursorPosition = 0;

                for (let i = 0; i < lines.length; i++) {
                    measurer.textContent = lines[i];
                    const lineHeight = measurer.offsetHeight;
                    if (totalHeight + lineHeight > clickY) {
                        // Found the line, now find the character
                        let linePosition = 0;
                        while (linePosition < lines[i].length) {
                            measurer.textContent = lines[i].slice(0, linePosition + 1);
                            if (measurer.offsetWidth > clickX) {
                                break;
                            }
                            linePosition++;
                        }
                        cursorPosition += linePosition;
                        break;
                    }
                    totalHeight += lineHeight;
                    cursorPosition += lines[i].length + 1; // +1 for newline
                }

                document.body.removeChild(measurer);

                textareaRef.current.focus();
                textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }, 0);
    };

    const handleSelectionChange = useCallback(() => {
        if (!isEditing || !textareaRef.current) return;

        const textarea = textareaRef.current;
        const { selectionStart, selectionEnd } = textarea;

        if (selectionStart !== selectionEnd) {
            // Get the coordinates of the cursor
            const cursorCoords = textarea.getBoundingClientRect();
            const selectionCoords = textarea.getClientRects()[0];

            // Set toolbar position
            setToolbarPosition({
                top: selectionCoords.top - cursorCoords.top - 5, // 5px above cursor
                left: selectionCoords.left + cursorCoords.left + 5 // 5px to the right of cursor
            });
            // setShowToolbar(true);
        } else {
            setShowToolbar(false);
        }
    }, [isEditing]);

    const handleKeyDown = useCallback((e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
            e.preventDefault();
            let selectedText = '';

            if (isEditing && textareaRef.current) {
                const { selectionStart, selectionEnd } = textareaRef.current;
                selectedText = memo.substring(selectionStart, selectionEnd).trim();
            } else {
                selectedText = window.getSelection().toString().trim();
            }

            if (selectedText) {
                addHighlight(selectedText);
                setShowChatPanel(true);
            }
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
            e.preventDefault();
            document.execCommand('undo');
        }
    }, [isEditing, memo, addHighlight]);

    useEffect(() => {
        const handleGlobalKeyDown = (e) => {
            if (!isEditing) {
                handleKeyDown(e);
            }
        };

        document.addEventListener('keydown', handleGlobalKeyDown);

        return () => {
            document.removeEventListener('keydown', handleGlobalKeyDown);
        };
    }, [isEditing, handleKeyDown]);

    useEffect(() => {
        const textarea = textareaRef.current;
        const toolbar = toolbarRef.current;

        const handleGlobalClick = (e) => {
            if (isEditing && textarea && toolbar) {
                const isOutsideTextarea = !textarea.contains(e.target);
                const isOutsideToolbar = !toolbar.contains(e.target);
                const isInsideMemoSection = contentRef.current.contains(e.target);

                if (isOutsideTextarea && isOutsideToolbar && !isInsideMemoSection) {
                    setIsEditing(false);
                    setShowToolbar(false);
                    handleBlur(); // Call handleBlur to save changes
                }
            }
        };

        if (textarea) {
            textarea.addEventListener('select', handleSelectionChange);
            textarea.addEventListener('keyup', handleSelectionChange);
            textarea.addEventListener('click', handleSelectionChange);
            textarea.addEventListener('keydown', handleKeyDown);
            document.addEventListener('mousedown', handleGlobalClick);
        }

        return () => {
            if (textarea) {
                textarea.removeEventListener('select', handleSelectionChange);
                textarea.removeEventListener('keyup', handleSelectionChange);
                textarea.removeEventListener('click', handleSelectionChange);
                textarea.removeEventListener('keydown', handleKeyDown);
                document.removeEventListener('mousedown', handleGlobalClick);
            }
        };
    }, [handleSelectionChange, isEditing, handleKeyDown, handleBlur]);

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (contentRef.current && !contentRef.current.contains(e.target)) {
                if (isEditing) {
                    handleBlur();
                }
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isEditing, handleBlur]);

    const applyFormatting = (formatType) => {
        if (!textareaRef.current) return;

        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = memo.substring(start, end);
        let formattedText = '';

        switch (formatType) {
            case 'bold':
                formattedText = `**${selectedText}**`;
                break;
            case 'italic':
                formattedText = `*${selectedText}*`;
                break;
            case 'underline':
                formattedText = `<u>${selectedText}</u>`;
                break;
            case 'strikethrough':
                formattedText = `~~${selectedText}~~`;
                break;
            case 'link':
                const url = prompt('Enter the URL:');
                formattedText = url ? `[${selectedText}](${url})` : selectedText;
                break;
            default:
                formattedText = selectedText;
        }

        const newMemo = memo.substring(0, start) + formattedText + memo.substring(end);
        setMemo(newMemo);

        // Prevent default behavior and keep focus on textarea
        setTimeout(() => {
            textarea.focus();
            textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
        }, 0);

        // Ensure we stay in editing mode
        setIsEditing(true);
        setShowToolbar(true);
    };

    const renderToolbarButton = (label, formatType) => (
        <button
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                applyFormatting(formatType);
            }}
            onMouseDown={(e) => e.preventDefault()} // Prevent blur on mousedown
        >
            {label}
        </button>
    );

    const renderParagraph = useCallback(({ children }) => {
        const processNode = (node) => {
            if (typeof node === 'string') {
                return node.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                        {line}
                        <br />
                    </React.Fragment>
                ));
            }
            return node;
        };

        return (
            <React.Fragment>
                {Array.isArray(children)
                    ? children.map((child, index) => <React.Fragment key={index}>{processNode(child)}</React.Fragment>)
                    : processNode(children)}
            </React.Fragment>
        );
    }, []);

    const handleMemoDoubleClick = (e) => {
        e.stopPropagation();
        if (!isEditing && !e.target.closest('.chat-panel')) {
            setIsEditing(true);
        }
    };

    const handleTextareaInput = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    useEffect(() => {
        if (isEditing && textareaRef.current) {
            handleTextareaInput({ target: textareaRef.current });
        }
    }, [isEditing, memo]);

    const handleCloseChatPanel = () => {
        setShowChatPanel(false);
        setHighlights([]); // Reset highlights when closing the chat panel
    };

    return (
        <div className="memo-section" style={{ position: 'relative' }} ref={contentRef} onDoubleClick={handleMemoDoubleClick}>
            {showToolbar && isEditing && (
                <div
                    ref={toolbarRef}
                    className="floating-toolbar"
                    style={{
                        top: `${toolbarPosition.top}px`,
                        left: `${toolbarPosition.left}px`,
                    }}
                >
                    {renderToolbarButton('B', 'bold')}
                    {renderToolbarButton('I', 'italic')}
                    {renderToolbarButton('U', 'underline')}
                    {renderToolbarButton('S', 'strikethrough')}
                    {renderToolbarButton('Link', 'link')}
                </div>
            )}
            <div className="memo-container">
                {isEditing ? (
                    <textarea
                        ref={textareaRef}
                        className="content-area"
                        value={memo}
                        onChange={handleMemoChange}
                        onInput={handleTextareaInput}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        placeholder="Start typing"
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            width: '100%',
                            minHeight: '200px',
                            height: 'auto',
                            resize: 'none',
                            overflow: 'hidden',
                            boxSizing: 'border-box',
                            padding: '10px',
                            lineHeight: '1.5'
                        }}
                    />
                ) : (
                    <div
                        ref={previewRef}
                        className="content-area markdown-preview"
                        onDoubleClick={(e) => {
                            e.stopPropagation();
                            handlePreviewClick(e);
                        }}
                        style={{
                            minHeight: '200px',
                            height: 'auto',
                            overflow: 'visible'
                        }}
                    >
                        {memo ? (
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                    p: renderParagraph
                                }}
                            >
                                {memo}
                            </ReactMarkdown>
                        ) : (
                            <span className="placeholder">Start typing</span>
                        )}
                    </div>
                )}
            </div>
            {showChatPanel && (
                <ChatPanel
                    key={showChatPanel ? 'open' : 'closed'}
                    highlights={highlights}
                    onClose={handleCloseChatPanel}
                    dealInfo={dealInfo}
                    setMemo={setMemo}
                    saveMemo={saveMemo}
                    className="chat-panel"
                />
            )}
        </div>
    );
};

export default MemoSection;