import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './FilePanel.css'; // Import the CSS file
import { getBaseUrl } from './ApiContext';



const baseUrl = getBaseUrl();

const FilePanel = ({ id, type, onClose }) => { // Add 'type' prop
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null); // State for selected file
    const [uploading, setUploading] = useState(false);
    const filePanelRef = useRef(null); // Ref for file panel
    const fileInputRef = useRef(null); // Ref for file input

    useEffect(() => {
        fetchFiles();
    }, [id, type]); // Add 'type' to dependencies

    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/${type}/${id}/files`); // Adjust endpoint
            console.log('Files fetched:', response.data.files);
            setFiles(response.data.files);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        document.getElementById('file-name').textContent = event.target.files[0].name; // Display selected file name
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        setUploading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/${type}/${id}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('File uploaded:', response.data);
            fetchFiles();
            fileInputRef.current.value = ''; // Clear file input
            setSelectedFile(null); // Clear selected file
            document.getElementById('file-name').textContent = ''; // Clear displayed file name
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFileDownload = async (fileName) => {
        try {
            const response = await axios.get(`${baseUrl}/api/${type}/${id}/files/${fileName}`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleFileDelete = async (fileName) => {
        try {
            const response = await axios.delete(`${baseUrl}/api/${type}/${id}/files/${fileName}`);
            console.log('File deleted:', response.data);
            fetchFiles();
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    return (
        <div className={`file-panel-container ${id ? 'open' : ''}`}>
            <h2>Files</h2>
            <div className="file-input-wrapper">
                <label htmlFor="file-input" className="file-input-label">Select File</label>
                <input type="file" id="file-input" className="file-input" onChange={handleFileChange} ref={fileInputRef} />
                <div id="file-name" className="file-name"></div>
                <button className="file-input-button" onClick={handleFileUpload} disabled={uploading || !selectedFile}>
                    {uploading ? 'Uploading...' : 'Upload File'}
                </button>
            </div>
            <ul className="file-panel">
                {files.map(file => (
                    <li key={file}>
                        {file}
                        <div>
                            <button onClick={() => handleFileDownload(file)}>Download</button>
                            <button onClick={() => handleFileDelete(file)}>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
            <button className="close-button" onClick={onClose}>Close</button>
        </div>
    );
};

export default FilePanel;