import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { getBaseUrl } from './ApiContext';
import { useAuth } from './AuthContext';
import './OmniChat.css';
import NewMessageComposer from './NewMessageComposer';
import { FaLinkedin, FaComments, FaEnvelope, FaUserPlus, FaCircle, FaCheck } from 'react-icons/fa';
import LinkedinChat from './LinkedinChat';
import { Tooltip } from 'react-tooltip';

const baseUrl = getBaseUrl();

// Helper function to ensure URLs have a protocol
function ensureHttps(url) {
    if (!url) return '';
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
}

function OmniChat({ dealId, onOutreachTagUpdate, onSneakyRefresh }) {
    const { isAuthenticated, accessToken, user, logout } = useAuth();
    const [deal, setDeal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFounder, setSelectedFounder] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [expandedThreads, setExpandedThreads] = useState({});
    const [emailThreads, setEmailThreads] = useState([]);
    const [newMessageSubject, setNewMessageSubject] = useState('');
    const [newMessageBody, setNewMessageBody] = useState('');
    const [newThreadName, setNewThreadName] = useState('');
    const [isEditingThreadName, setIsEditingThreadName] = useState(false);
    const [newThreads, setNewThreads] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [toEmail, setToEmail] = useState('');
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [isLoadingThreads, setIsLoadingThreads] = useState(false);
    const [expandedMessages, setExpandedMessages] = useState({});
    const [replyingTo, setReplyingTo] = useState(null);
    const [forwardingMessage, setForwardingMessage] = useState(null);
    const [replyingToMessageId, setReplyingToMessageId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [showNewMessageComposer, setShowNewMessageComposer] = useState(false);
    const [introRequests, setIntroRequests] = useState([]);
    const [selectedIntroRequest, setSelectedIntroRequest] = useState(null);
    const [introRequestTemplate, setIntroRequestTemplate] = useState(null);
    const [isIntroRequestAreaOpen, setIsIntroRequestAreaOpen] = useState(false);
    const [isLoadingIntroRequests, setIsLoadingIntroRequests] = useState(false);

    const fetchDealTags = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/deals/${dealId}/tags`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            setDeal(prevDeal => ({
                ...prevDeal,
                founders: prevDeal.founders.map(founder => ({
                    ...founder,
                    outreach_sequence_tags: response.data.tags[founder.id] || []
                }))
            }));
        } catch (error) {
            console.error('Error fetching deal tags:', error);
        }
    }, [dealId, accessToken]);

    const fetchEmailThreads = useCallback(async (founderEmail) => {
        console.log('Attempting to fetch email threads');
        console.log('Auth state:', { isAuthenticated, user, accessToken: accessToken ? 'Present' : 'Missing' });

        if (!isAuthenticated || !user || !accessToken) {
            console.error('Authentication information is incomplete');
            return;
        }

        setIsLoadingThreads(true);
        try {
            const response = await axios.get(`${baseUrl}/api/email/threads`, {
                params: {
                    user1: user.email,
                    user2: founderEmail
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            console.log('Raw email threads data:', response.data.threads);

            const processedThreads = response.data.threads.map(thread => {
                console.log('Processing thread:', thread);
                return {
                    ...thread,
                    messages: thread.messages.map(message => {
                        console.log('Processing message:', message);
                        return {
                            ...message,
                            from: message.from || message.from_email || 'Unknown Sender',
                            to: message.to || message.to_email || 'Unknown Recipient',
                            subject: message.subject || 'No Subject'
                        };
                    })
                };
            });

            console.log('Processed email threads:', processedThreads);
            setEmailThreads(processedThreads || []);
        } catch (error) {
            console.error('Error fetching email threads:', error);
            console.error('Error response:', error.response);
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized: Token may be invalid or expired');
                logout();
            }
        } finally {
            setIsLoadingThreads(false);
        }
    }, [isAuthenticated, user, accessToken, logout]);

    const fetchIntroRequestThreads = useCallback(async (founderId) => {
        if (!isAuthenticated || !user || !accessToken) {
            console.error('Authentication information is incomplete');
            return;
        }

        setIsLoadingIntroRequests(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people/${founderId}/intro_requests`, {
                params: {
                    user_email: user.email
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            console.log('Fetched intro request threads:', response.data.intro_requests);
            const processedIntroRequests = response.data.intro_requests.map(thread => ({
                id: thread.id,
                messages: [{
                    id: thread.id,
                    subject: thread.subject || 'No Subject',
                    from: thread.from || 'Unknown',
                    to: thread.to || 'Unknown',
                    date: thread.date,
                    body: thread.body || ''
                }]
            }));
            setIntroRequests(processedIntroRequests || []);
        } catch (error) {
            console.error('Error fetching intro request threads:', error);
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized: Token may be invalid or expired');
                logout();
            }
        } finally {
            setIsLoadingIntroRequests(false);
        }
    }, [isAuthenticated, user, accessToken, logout, baseUrl]);

    const handleSend = useCallback((subject, body, to, newTag) => {
        setShowNewMessageComposer(false);
        setIntroRequestTemplate(null);
        if (selectedChannel === 'email') {
            setEmailThreads(prevThreads => [
                {
                    id: Date.now(),
                    messages: [{
                        subject,
                        body,
                        from: user?.email || '',
                        to,
                        date: new Date().toISOString()
                    }]
                },
                ...prevThreads
            ]);
        }
        if (newTag && selectedFounder) {
            onOutreachTagUpdate(dealId, selectedFounder.id, newTag);
        }
        // Fetch updated tags after sending a message
        fetchDealTags();
    }, [selectedChannel, user, setEmailThreads, selectedFounder, onOutreachTagUpdate, dealId, fetchDealTags]);

    const convertUrlsToLinks = (text) => {
        const urlRegex = /(\S+)\s*<(https?:\/\/[^>]+)>/g;
        return text.replace(urlRegex, (match, text, url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`);
    };

    const parseEmailBody = (body) => {
        const lines = body.split('\n');
        const cleanedLines = [];
        let replyFound = false;

        for (const line of lines) {
            if (line.match(/^>|^On .* wrote:|^-{3,}|^_{3,}/) ||
                line.match(/^On .*, .* at .*M .* <.*@.*> wrote:$/)) {
                replyFound = true;
                continue;
            }
            if (!replyFound) {
                cleanedLines.push(line);
            }
        }

        return convertUrlsToLinks(cleanedLines.join('\n').trim());
    };

    const getNames = (nameString) => {
        if (!nameString) return ''; // Return empty string if nameString is null or undefined
        return nameString.split(',').map(name => name.trim().split(' ')[0]).join(', ');
    };

    useEffect(() => {
        if (isIntroRequestAreaOpen && selectedFounder) {
            fetchIntroRequestThreads(selectedFounder.id);
        }
    }, [isIntroRequestAreaOpen, selectedFounder, fetchIntroRequestThreads]);

    const handleNewThread = () => {
        setShowNewMessageComposer(true);
        setSelectedChannel('email');
        setToEmail(selectedFounder.email);
    };

    const handleThreadNameSubmit = (e) => {
        e.preventDefault();
        setIsEditingThreadName(false);
        const newThread = { id: Date.now(), name: newThreadName };
        setNewThreads(prev => [...prev, newThread]);
        setSelectedThread(newThread);
        setNewMessageSubject(newThreadName);
        setSelectedChannel('email');
        setNewThreadName('');
    };

    const handleThreadClick = (thread) => {
        setSelectedThread(thread);
        setNewMessageSubject(thread.name);
    };

    useEffect(() => {
        const fetchDealDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/deals/${dealId}/details`);
                if (response.data) {
                    setDeal(response.data);
                    console.log('Fetched deal data:', response.data);
                } else {
                    console.error('Unexpected response structure:', response.data);
                }
            } catch (error) {
                console.error('Error fetching deal details:', error);
            }
            setIsLoading(false);
        };

        fetchDealDetails();
    }, [dealId]);

    useEffect(() => {
        if (isAuthenticated && user && selectedFounder) {
            console.log('Fetching email threads for:', selectedFounder.email);
            setToEmail(selectedFounder.email);
            fetchEmailThreads(selectedFounder.email);
            fetchDealTags(); // Add this line if you want to fetch tags when a founder is selected
        } else {
            console.log('Not fetching email threads. Auth state:', { isAuthenticated, user, selectedFounder });
        }
    }, [isAuthenticated, user, selectedFounder, fetchEmailThreads, fetchDealTags]); // Add fetchDealTags to the dependency array

    const toggleThreadExpansion = (founderId, channel) => {
        setExpandedThreads(prev => ({
            ...prev,
            [founderId]: {
                ...prev[founderId],
                [channel]: !prev[founderId]?.[channel]
            }
        }));
        if (channel === 'intro') {
            setIsIntroRequestAreaOpen(!expandedThreads[founderId]?.intro);
        }
    };

    // New function to get the most recent subject of a thread
    const getThreadSubject = (thread) => {
        if (thread.messages && thread.messages.length > 0) {
            return thread.messages[0].subject || 'No Subject';
        }
        return 'No Subject';
    };

    // New function to handle thread selection
    const handleThreadSelection = (thread) => {
        setSelectedThread(thread);
        setNewMessageSubject(getThreadSubject(thread));
        setSelectedChannel('email');
    };

    const getThreadName = () => {
        if (selectedFounder && selectedThread) {
            const fullName = `${selectedFounder.first_name} ${selectedFounder.last_name}`;
            const threadSubject = getThreadSubject(selectedThread);
            return `${fullName} - ${threadSubject}`;
        } else if (selectedFounder) {
            return `${selectedFounder.first_name} ${selectedFounder.last_name}`;
        }
        return '';
    };

    const toggleMessageExpansion = (messageId) => {
        setExpandedMessages(prevState => ({
            ...prevState,
            [messageId]: !prevState[messageId]
        }));
    };

    const getFirstName = (fullName, email) => {
        if (fullName) {
            return fullName.split(' ')[0];
        }
        return email ? email.split('@')[0] : 'Unknown';
    };

    const getMessagePreview = (body, maxLength = 50) => {
        const cleanBody = parseEmailBody(body);
        return cleanBody.length > maxLength ? cleanBody.substring(0, maxLength) + '...' : cleanBody;
    };

    const handleReply = (message) => {
        setReplyingToMessageId(message.id);
        setNewMessageSubject(`Re: ${message.subject}`);
        setReplyText('');
    };

    const handleForward = (message) => {
        setForwardingMessage(message);
        setNewMessageSubject(`Fwd: ${message.subject}`);
        setNewMessageBody(`\n\n---------- Forwarded message ---------\nFrom: ${message.from}\nDate: ${new Date(message.date).toLocaleDateString()}\nSubject: ${message.subject}\nTo: ${message.to}\n\n${message.body}`);
    };

    const formatMessageDate = (dateString) => {
        const messageDate = new Date(dateString);
        const today = new Date();

        if (messageDate.toDateString() === today.toDateString()) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return messageDate.toLocaleDateString([], { month: 'short', day: 'numeric' });
        }
    };

    const ReplyBox = ({ message }) => (
        <div className="reply-box">
            <div className="reply-line"></div>
            <div className="reply-content">
                <div className="reply-header">
                    <span>Re: {message.subject}</span>
                    <span className="reply-date">On {new Date().toLocaleDateString()}</span>
                </div>
                <textarea
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder="Type your reply here..."
                />
                <div className="reply-actions">
                    <button onClick={() => handleSend()}>Send</button>
                    <button onClick={() => setReplyingToMessageId(null)}>Cancel</button>
                </div>
            </div>
        </div>
    );

    const MessageContent = ({ message, onReply, onForward, onCollapse }) => (
        <div className="chat-message-container">
            <div className="message-content">
                <div className="message-header">
                    <div className="header-content">
                        <strong>{getFirstName(message.from, message.from_email)} to {getNames(message.to)}:</strong>
                    </div>
                    <div className="message-actions">
                        <button onClick={() => onReply(message)}>Reply</button>
                        <button onClick={() => onForward(message)}>Forward</button>
                        <button onClick={() => onCollapse(message.id)}>Collapse</button>
                    </div>
                </div>
                <div className="message-body" dangerouslySetInnerHTML={{ __html: parseEmailBody(message.body || '') }} />
            </div>
        </div>
    );

    const MessagePreview = ({ message, onClick }) => {
        const senderName = getFirstName(message.from, message.from_email);
        const recipientName = getFirstName(message.to, message.to_email);
        return (
            <div className="chat-message-container">
                <div className="message-preview" onClick={() => onClick(message.id)}>
                    <div className="message-preview-header">
                        <span className="message-preview-sender">{senderName} → {recipientName}</span>
                        <span className="message-preview-time">{formatMessageDate(message.date)}</span>
                    </div>
                    <div className="message-preview-body">
                        <strong>{message.subject}</strong>: {getMessagePreview(message.body)}
                    </div>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return <div>Loading chat...</div>;
    }

    if (!deal) {
        return <div>Error loading chat. Please try again.</div>;
    }

    const handleFounderClick = (founder) => {
        if (selectedFounder?.id === founder.id) {
            // If clicking on the already selected founder, deselect them
            setSelectedFounder(null);
            setSelectedChannel(null);
            setExpandedThreads(prev => ({
                ...prev,
                [founder.id]: {}
            }));
        } else {
            // If clicking on a different founder, select them
            setSelectedFounder(founder);
            setSelectedChannel(null);
        }
    };

    const handleChannelClick = (channel) => {
        setSelectedChannel(channel);
    };

    const handleEmailIconClick = (e, founder) => {
        e.stopPropagation();
        setSelectedFounder(founder);
        setSelectedChannel('email');
        toggleThreadExpansion(founder.id, 'email');
    };

    const handleIntroRequestClick = (founder) => {
        setSelectedFounder(founder);
        setSelectedChannel('intro');
        toggleThreadExpansion(founder.id, 'intro');
    };

    const handleNewIntroRequest = (founder) => {
        setShowNewMessageComposer(true);
        setSelectedChannel('intro');
        setSelectedFounder(founder);
        setToEmail(''); // Clear the toEmail state
    };

    const handleIntroRequestSelection = (thread) => {
        setSelectedIntroRequest(thread);
        setSelectedChannel('intro');
    };

    const getOutreachTagsTooltip = (founder) => {
        if (!founder || !founder.outreach_sequence_tags) {
            return '';
        }

        let tags = founder.outreach_sequence_tags;
        if (typeof tags === 'string') {
            try {
                tags = JSON.parse(tags);
            } catch (error) {
                console.error('Error parsing outreach_sequence_tags:', error);
                return tags;
            }
        }

        if (!Array.isArray(tags)) {
            console.warn('Unexpected type for outreach_sequence_tags:', typeof tags);
            return '';
        }

        const inContactTags = tags.filter(tag => tag.includes('In Contact'));
        const otherTags = tags.filter(tag => !tag.includes('In Contact'));

        return [
            ...inContactTags.map(tag => `<span style="color: #4CAF50;">${tag}</span>`),
            ...otherTags
        ].join(', ');
    };

    const hasInContactTag = (founder) => {
        if (!founder || !founder.outreach_sequence_tags) return false;
        const tags = Array.isArray(founder.outreach_sequence_tags)
            ? founder.outreach_sequence_tags
            : JSON.parse(founder.outreach_sequence_tags);
        return tags.some(tag => tag.includes('In Contact'));
    };

    const handleMarkInContact = async () => {
        if (!selectedFounder) return;

        const userFirstName = user.given_name || user.name.split(' ')[0] || 'User';
        const newTag = `${userFirstName} In Contact`;

        try {
            // Optimistically update the UI
            setDeal(prevDeal => ({
                ...prevDeal,
                founders: prevDeal.founders.map(founder =>
                    founder.id === selectedFounder.id
                        ? {
                            ...founder,
                            outreach_sequence_tags: [
                                ...(founder.outreach_sequence_tags || []),
                                newTag
                            ]
                        }
                        : founder
                )
            }));

            const response = await axios.post(
                `${baseUrl}/api/people/${selectedFounder.id}/add_outreach_tag`,
                {
                    user_given_name: user.name,
                    tag: newTag
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                // Call onOutreachTagUpdate to update the parent component's state
                onOutreachTagUpdate(dealId, selectedFounder.id, newTag);
            } else {
                // If the request failed, revert the optimistic update
                setDeal(prevDeal => ({
                    ...prevDeal,
                    founders: prevDeal.founders.map(founder =>
                        founder.id === selectedFounder.id
                            ? {
                                ...founder,
                                outreach_sequence_tags: founder.outreach_sequence_tags.filter(tag => tag !== newTag)
                            }
                            : founder
                    )
                }));
            }
        } catch (error) {
            console.error('Error marking founder as In Contact:', error);
            // Revert the optimistic update in case of an error
            setDeal(prevDeal => ({
                ...prevDeal,
                founders: prevDeal.founders.map(founder =>
                    founder.id === selectedFounder.id
                        ? {
                            ...founder,
                            outreach_sequence_tags: founder.outreach_sequence_tags.filter(tag => tag !== newTag)
                        }
                        : founder
                )
            }));
        }
    };

    const handleAddFounder = () => {
        console.log("Add founder button clicked");
        // We'll implement the functionality later
    };

    return (
        <div className="omnichannel-chat">
            <div className="chat-container">
                <div className="omni-left-panel">
                    <ul className="founder-list">
                        {deal.founders?.map(founder => (
                            <li key={founder.id}>
                                <div
                                    className={`founder-item ${selectedFounder?.id === founder.id ? 'selected' : ''}`}
                                    onClick={() => handleFounderClick(founder)}
                                >
                                    <span className="founder-name-container">
                                        {founder.first_name} {founder.last_name}
                                        {founder.outreach_sequence_tags &&
                                            (Array.isArray(founder.outreach_sequence_tags) ?
                                                founder.outreach_sequence_tags.length > 0 :
                                                founder.outreach_sequence_tags !== '') && (
                                                <>
                                                    <span
                                                        className="outreach-tag-indicator"
                                                        data-tooltip-id={`outreach-tooltip-${founder.id}`}
                                                    >
                                                        <FaCircle
                                                            color={hasInContactTag(founder) ? "#4CAF50" : "yellow"}
                                                            size={8}
                                                        />
                                                    </span>
                                                    <Tooltip
                                                        id={`outreach-tooltip-${founder.id}`}
                                                        html={getOutreachTagsTooltip(founder)}
                                                    />
                                                </>
                                            )}
                                    </span>
                                    <div className="founder-icons">
                                        {founder.email && (
                                            <span
                                                className="email-icon"
                                                onClick={(e) => handleEmailIconClick(e, founder)}
                                            >
                                                <FaEnvelope />
                                            </span>
                                        )}
                                        {founder.linkedin && (
                                            <a href={ensureHttps(founder.linkedin)} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
                                                <FaLinkedin className="linkedin-icon" />
                                            </a>
                                        )}
                                        <span
                                            className="intro-icon"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleIntroRequestClick(founder);
                                            }}
                                        >
                                            <FaUserPlus />
                                        </span>
                                    </div>
                                </div>
                                {selectedFounder?.id === founder.id && (
                                    <>
                                        <ul className="channel-list">
                                            {founder.email && (
                                                <>
                                                    <li
                                                        className={`channel-item ${selectedChannel === 'email' ? 'selected' : ''}`}
                                                        onClick={() => toggleThreadExpansion(founder.id, 'email')}
                                                    >
                                                        Email {expandedThreads[founder.id]?.email ? '▼' : '▶'}
                                                    </li>
                                                    {expandedThreads[founder.id]?.email && (
                                                        <>
                                                            <li className="new-thread-item">
                                                                {isEditingThreadName ? (
                                                                    <form onSubmit={handleThreadNameSubmit}>
                                                                        <input
                                                                            type="text"
                                                                            value={newThreadName}
                                                                            onChange={(e) => setNewThreadName(e.target.value)}
                                                                            placeholder="Thread name..."
                                                                            autoFocus
                                                                        />
                                                                    </form>
                                                                ) : (
                                                                    <button onClick={handleNewThread} className="new-thread-button">
                                                                        + New Thread
                                                                    </button>
                                                                )}
                                                            </li>
                                                            <ul className="email-threads">
                                                                {isLoadingThreads ? (
                                                                    <li className="loading-threads">Loading threads...</li>
                                                                ) : (
                                                                    <>
                                                                        {newThreads.map(thread => (
                                                                            <li key={thread.id} className="new-thread" onClick={() => handleThreadSelection(thread)}>
                                                                                {thread.name}
                                                                            </li>
                                                                        ))}
                                                                        {emailThreads.map(thread => (
                                                                            <li key={thread.id} onClick={() => handleThreadSelection(thread)}>
                                                                                {getThreadSubject(thread)}
                                                                            </li>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {founder.linkedin && (
                                                <li
                                                    className={`channel-item ${selectedChannel === 'linkedin' ? 'selected' : ''}`}
                                                    onClick={() => handleChannelClick('linkedin')}
                                                >
                                                    LinkedIn
                                                </li>
                                            )}
                                            <li
                                                className={`channel-item ${selectedChannel === 'intro' ? 'selected' : ''}`}
                                                onClick={() => toggleThreadExpansion(founder.id, 'intro')}
                                            >
                                                Intro Requests {expandedThreads[founder.id]?.intro ? '▼' : '▶'}
                                            </li>
                                            {expandedThreads[founder.id]?.intro && (
                                                <>
                                                    <li className="new-thread-item">
                                                        <button onClick={() => handleNewIntroRequest(founder)} className="new-thread-button">
                                                            + New Intro Request
                                                        </button>
                                                    </li>
                                                    <ul className="email-threads">
                                                        {isLoadingIntroRequests ? (
                                                            <li className="loading-threads">Loading intro requests...</li>
                                                        ) : introRequests.length > 0 ? (
                                                            introRequests.map(thread => (
                                                                <li key={thread.id} onClick={() => handleThreadSelection(thread)}>
                                                                    {getThreadSubject(thread)} - {new Date(thread.messages[0].date).toLocaleDateString()}
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li></li>
                                                        )}
                                                    </ul>
                                                </>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                        <li className="add-founder-item">
                            <button className="add-founder-button" onClick={() => handleAddFounder()}>
                                + Add Founder
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="right-panel">
                    <div className="founder-info">
                        {getThreadName()}
                        {selectedFounder && (
                            <button
                                className="mark-in-contact-button"
                                onClick={handleMarkInContact}
                                disabled={hasInContactTag(selectedFounder)}
                            >
                                <FaCheck /> {hasInContactTag(selectedFounder) ? 'In Contact' : 'Mark as In Contact'}
                            </button>
                        )}
                    </div>
                    <div className="chat-box">
                        {selectedFounder ? (
                            <div className="chat-messages">
                                {selectedChannel === 'email' && (
                                    <div className="email-threads">
                                        {showNewMessageComposer ? (
                                            <div className="new-message-container">
                                                <NewMessageComposer
                                                    toEmail={toEmail}
                                                    onSend={handleSend}
                                                    onCancel={() => {
                                                        setShowNewMessageComposer(false);
                                                        setIntroRequestTemplate(null);
                                                    }}
                                                    dealInfo={{
                                                        ...deal,
                                                        founders: [selectedFounder],
                                                        company: deal.company,
                                                    }}
                                                    messageType={selectedChannel === 'intro' ? 'intro_request' : 'outreach'}
                                                    onSneakyRefresh={onSneakyRefresh}
                                                />
                                            </div>
                                        ) : (
                                            selectedThread && selectedThread.messages && (
                                                <div className="email-thread">
                                                    {selectedThread.messages.map((message) => (
                                                        <div key={message.id} className="email-message-box">
                                                            {expandedMessages[message.id] ? (
                                                                <MessageContent
                                                                    message={message}
                                                                    onReply={handleReply}
                                                                    onForward={handleForward}
                                                                    onCollapse={toggleMessageExpansion}
                                                                />
                                                            ) : (
                                                                <MessagePreview
                                                                    message={message}
                                                                    onClick={toggleMessageExpansion}
                                                                />
                                                            )}
                                                            {replyingToMessageId === message.id && <ReplyBox message={message} />}
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                                {selectedChannel === 'linkedin' && (
                                    <LinkedinChat
                                        founderId={selectedFounder.id}
                                        accessToken={accessToken}
                                        baseUrl={baseUrl}
                                        dealInfo={{
                                            ...deal,
                                            founders: [selectedFounder],
                                            company: deal.company,
                                        }}
                                        onOutreachTagUpdate={onOutreachTagUpdate}
                                    />
                                )}
                                {selectedChannel === 'intro' && (
                                    <div className="intro-requests-content">
                                        {showNewMessageComposer ? (
                                            <div className="new-message-container">
                                                <NewMessageComposer
                                                    toEmail=""
                                                    onSend={(subject, body, to) => {
                                                        setShowNewMessageComposer(false);
                                                        setIntroRequestTemplate(null);
                                                        fetchIntroRequestThreads(selectedFounder.id);
                                                    }}
                                                    onCancel={() => {
                                                        setShowNewMessageComposer(false);
                                                        setIntroRequestTemplate(null);
                                                    }}
                                                    dealInfo={{
                                                        ...deal,
                                                        founders: [selectedFounder],
                                                        company: deal.company,
                                                    }}
                                                    messageType="intro_request"
                                                    onSneakyRefresh={onSneakyRefresh}
                                                />
                                            </div>
                                        ) : selectedThread ? (
                                            <div className="email-thread">
                                                {selectedThread.messages.map((message) => (
                                                    <div key={message.id} className="email-message-box">
                                                        {expandedMessages[message.id] ? (
                                                            <MessageContent
                                                                message={message}
                                                                onReply={handleReply}
                                                                onForward={handleForward}
                                                                onCollapse={toggleMessageExpansion}
                                                            />
                                                        ) : (
                                                            <MessagePreview
                                                                message={message}
                                                                onClick={toggleMessageExpansion}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p>Select an intro request or create a new one.</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p>Select a founder to start chatting.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OmniChat;