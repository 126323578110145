import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getBaseUrl } from './ApiContext';
import './Login.css';

function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const baseUrl = getBaseUrl();

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log(tokenResponse);

            const accessToken = tokenResponse.access_token;

            // Fetch the user's profile information from Google
            try {
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const userData = await userInfoResponse.json();

                if (userInfoResponse.ok) {
                    // Proceed with your login logic here
                    login(accessToken, userData);
                    navigate('/people');

                    // Wake up the server in the background
                    fetch(`${baseUrl}/api/hello`)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Failed to wake up the server');
                            }
                            return response.json();
                        })
                        .then(data => console.log(data.message))
                        .catch(error => console.error('Error waking up the server:', error));
                } else {
                    console.error('Failed to fetch user info:', userData);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: (error) => console.log('Login Failed:', error),
        flow: 'implicit',
    });

    return (
        <div className="login-container">
            <div className="background-image hero-back"></div>
            <div className="background-image hero-front"></div>
            <div className="login-content">
                <button className="login-button" onClick={() => googleLogin()}>
                    SIGN IN WITH GOOGLE
                </button>
            </div>
        </div>
    );
}

export default Login;
