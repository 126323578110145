import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './Deal.css';
import ReactMarkdown from 'react-markdown';
import MemoSection from './MemoSection';
import ChatPanel from './ChatPanel';
import Select from 'react-select';
import { getBaseUrl } from './ApiContext';



const baseUrl = getBaseUrl();

const STAGE_OPTIONS = [
    'Top of Funnel',
    'Radar Screen',
    'Digging In',
    'Hunting License',
    'Approved',
    'Closed',
    'Hold',
    'Passed',
    'Track',
    'Passed but Shareable',
    'Gave Term Sheet but Lost'
];

const parseNotes = (notes) => {
    if (!notes || notes === 'null' || notes === '') return [];
    try {
        const parsedNotes = JSON.parse(notes);
        return Array.isArray(parsedNotes) ? parsedNotes : [notes];
    } catch (error) {
        console.error('Error parsing notes:', error);
        return notes.split(',').map(note => note.trim());
    }
};

export const generateChatContext = (dealData) => {
    if (!dealData) return 'No deal data available.';

    let context = `Deal Information:\n`;
    context += `Company Name: ${dealData.name || dealData.company_name || 'N/A'}\n`;
    context += `Description: ${dealData.description || 'N/A'}\n\n`;

    if (dealData.company) {
        context += `Company Information:\n`;
        context += `Name: ${dealData.company.name || 'N/A'}\n`;
        context += `Description: ${dealData.company.description || 'N/A'}\n`;
        context += `Website: ${dealData.company.website || 'N/A'}\n`;
        context += `LinkedIn: ${dealData.company.linkedin || 'N/A'}\n`;
        context += `Location: ${dealData.company.location || 'N/A'}\n`;
        context += `Notes: ${dealData.company.notes || 'N/A'}\n\n`;
    }

    if (dealData.people && dealData.people.length > 0) {
        context += `People Information:\n`;
        dealData.people.forEach(person => {
            context += `- ${person.first_name || ''} ${person.last_name || ''}\n`;
            context += `  Email: ${person.email || 'N/A'}\n`;
            context += `  Title: ${person.title || 'N/A'}\n`;
            context += `  Location: ${person.location || 'N/A'}\n`;
            context += `  Notes: ${parseNotes(person.notes).join(', ') || 'N/A'}\n\n`;
        });
    }

    if (dealData.founders && dealData.founders.length > 0) {
        context += `Founders:\n`;
        dealData.founders.forEach(founder => {
            context += `- ${founder.first_name || ''} ${founder.last_name || ''}\n`;
            context += `  LinkedIn: ${founder.linkedin || 'N/A'}\n`;
            context += `  Email: ${founder.email || 'N/A'}\n`;
            context += `  Location: ${founder.location || 'N/A'}\n`;
            context += `  Notes: ${parseNotes(founder.notes).join(', ') || 'N/A'}\n\n`;
        });
    }

    return context;
};

const Deal = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [deal, setDeal] = useState(null);
    const [editableDeal, setEditableDeal] = useState(null);
    const [activeTab, setActiveTab] = useState('overview');
    const [isEditing, setIsEditing] = useState({ name: false, description: false });
    const [editingPersonId, setEditingPersonId] = useState(null);
    const [editPersonData, setEditPersonData] = useState({});
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [showChatPanel, setShowChatPanel] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [allPeople, setAllPeople] = useState([]);
    const [selectedPeople, setSelectedPeople] = useState([]);

    useEffect(() => {
        const fetchDealDetails = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/deals/${id}/details`);
                console.log('API response:', response.data);
                setDeal(response.data);
                setEditableDeal(response.data);
                console.log('Deal state set:', response.data);

                // Fetch companies
                const companiesResponse = await axios.get(`${baseUrl}/api/companies`, {
                    params: { fetch_all: true }
                });
                const companyOptions = companiesResponse.data.companies.map(company => ({
                    value: company.id,
                    label: company.name
                }));
                setCompanyOptions(companyOptions);
            } catch (error) {
                console.error('Error fetching deal details:', error);
            }
        };

        fetchDealDetails();
    }, [id]);

    useEffect(() => {
        console.log('Deal state updated:', deal);
    }, [deal]);

    useEffect(() => {
        if (activeTab === 'files') {
            fetchFiles();
        }
    }, [activeTab]);

    useEffect(() => {
        const fetchAllPeople = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/people`, {
                    params: { fetch_all: true }
                });
                setAllPeople(response.data.people);
            } catch (error) {
                console.error('Error fetching people:', error);
            }
        };

        fetchAllPeople();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        if (tab) {
            setActiveTab(tab);
        }
    }, [location.search]);

    const fetchFiles = async () => {
        try {
            const dealFilesResponse = await axios.get(`${baseUrl}/api/deals/${id}/files`);
            const companyFilesResponse = deal.company_id ? await axios.get(`${baseUrl}/api/companies/${deal.company_id}/files`) : { data: { files: [] } };

            // Add a check for deal.founders
            const founderFilesPromises = deal.founders && deal.founders.length > 0
                ? deal.founders.map(founder => axios.get(`${baseUrl}/api/people/${founder.id}/files`))
                : [];
            const founderFilesResponses = await Promise.all(founderFilesPromises);

            const allFiles = {
                deal: dealFilesResponse.data.files || [],
                company: companyFilesResponse.data.files || [],
                founders: deal.founders && deal.founders.length > 0
                    ? deal.founders.reduce((acc, founder, index) => {
                        acc[founder.id] = founderFilesResponses[index]?.data?.files || [];
                        return acc;
                    }, {})
                    : {}
            };

            console.log('Fetched files:', allFiles); // Add this line for debugging
            setFiles(allFiles);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableDeal((prevDeal) => ({
            ...prevDeal,
            [name]: value,
        }));
    };

    const handleBlur = async (field) => {
        setIsEditing((prev) => ({ ...prev, [field]: false }));
        try {
            await axios.put(`${baseUrl}/api/deals/${id}`, editableDeal);
            setDeal(editableDeal);
            console.log('Deal updated successfully');
        } catch (error) {
            console.error('Error updating deal:', error);
        }
    };

    const handlePersonInputChange = (e, personId) => {
        const { name, value } = e.target;
        setEditPersonData((prevData) => ({
            ...prevData,
            [personId]: {
                ...prevData[personId],
                [name]: value,
            },
        }));
    };

    const handlePersonEdit = (personId, field) => {
        setEditingPersonId(personId);
        setEditPersonData((prevData) => ({
            ...prevData,
            [personId]: {
                ...prevData[personId],
                isEditing: {
                    ...prevData[personId]?.isEditing,
                    [field]: true,
                },
            },
        }));
    };

    const handlePersonBlur = async (personId, field) => {
        setEditPersonData((prevData) => ({
            ...prevData,
            [personId]: {
                ...prevData[personId],
                isEditing: {
                    ...prevData[personId]?.isEditing,
                    [field]: false,
                },
            },
        }));
        try {
            const updatedPerson = editPersonData[personId];
            await axios.put(`${baseUrl}/api/people/${personId}`, updatedPerson);
            setDeal((prevDeal) => ({
                ...prevDeal,
                people: (prevDeal.people || []).map((person) =>
                    person.id === personId ? { ...person, ...updatedPerson } : person
                ),
            }));
            console.log('Person updated successfully');
        } catch (error) {
            console.error('Error updating person:', error);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        setUploading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/deals/${id}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('File uploaded:', response.data);
            fetchFiles();
            fileInputRef.current.value = ''; // Clear file input
            setSelectedFile(null); // Clear selected file
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFileDownload = async (fileName, category, entityId = null) => {
        try {
            let url;
            if (category === 'deal') {
                url = `${baseUrl}/api/deals/${id}/files/${fileName}`;
            } else if (category === 'company') {
                url = `${baseUrl}/api/companies/${deal.company_id}/files/${fileName}`;
            } else if (category === 'founders') {
                url = `${baseUrl}/api/people/${entityId}/files/${fileName}`;
            }

            const response = await axios.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleFileDelete = async (fileName, category, entityId = null) => {
        try {
            let url;
            if (category === 'deal') {
                url = `${baseUrl}/api/deals/${id}/files/${fileName}`;
            } else if (category === 'company') {
                url = `${baseUrl}/api/companies/${deal.company_id}/files/${fileName}`;
            } else if (category === 'founders') {
                url = `${baseUrl}/api/people/${entityId}/files/${fileName}`;
            }

            const response = await axios.delete(url);
            console.log('File deleted:', response.data);
            fetchFiles();
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    const handleMemoUpdate = (updatedMemo) => {
        setDeal(prevDeal => ({ ...prevDeal, memo: updatedMemo }));
        setEditableDeal(prevDeal => ({ ...prevDeal, memo: updatedMemo }));
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: '#0f0d2e',
            borderColor: '#625896',
            color: '#e0e0e0',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#0f0d2e',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#1a1433' : '#0f0d2e',
            color: '#e0e0e0',
        }),
    };

    const handleAddCompany = async () => {
        try {
            // Log the current state of deal.people to ensure it contains the necessary information
            console.log('deal.people:', deal.people);

            // Ensure deal.people contains the necessary information
            const peopleData = deal.founders.map(person => ({
                id: person.id,
                email: person.email,
                linkedin: person.linkedin,
                first_name: person.first_name,
                last_name: person.last_name,
                title: person.title,
                location: person.location,
                notes: person.notes,
                // Add any other necessary fields here
            }));

            // Log the peopleData to ensure it is correctly populated
            console.log('peopleData:', peopleData);

            const response = await axios.post(`${baseUrl}/api/deals/${id}/add_company`, {
                company_id: editableDeal.company_id,
                people: peopleData
            });

            if (response.data.success) {
                // Update the deal state with the new company information
                setDeal(prevDeal => ({
                    ...prevDeal,
                    company: response.data.company,
                    people: response.data.people
                }));
                setEditableDeal(prevDeal => ({
                    ...prevDeal,
                    company: response.data.company,
                    people: response.data.people
                }));
            } else {
                console.error('Failed to add company to deal:', response.data.message);
            }
        } catch (error) {
            console.error('Error adding company to deal:', error);
        }
    };

    const handleRemoveCompany = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/deals/${id}/remove_company`);
            if (response.data.success) {
                setDeal(prevDeal => ({
                    ...prevDeal,
                    company: null,
                    company_id: null,
                    company_name: null
                }));
                setEditableDeal(prevDeal => ({
                    ...prevDeal,
                    company: null,
                    company_id: null,
                    company_name: null
                }));
                console.log('Company removed successfully');
            }
        } catch (error) {
            console.error('Error removing company from deal:', error);
        }
    };

    const handleAddPeopleToDeal = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/deals/${id}/add_people`, {  // Updated endpoint
                people_ids: selectedPeople.map(person => person.value)  // Send multiple people IDs
            });
            if (response.data.success) {
                setSelectedPeople([]);
                navigate(`${location.pathname}?tab=people`);  // Update the URL with the query parameter
                window.location.reload();  // Reload the page
            } else {
                console.error('Failed to add people to deal:', response.data.message);
            }
        } catch (error) {
            console.error('Error adding people to deal:', error);
        }
    };

    const handleRemovePersonFromDeal = async (personId) => {
        try {
            const response = await axios.post(`${baseUrl}/api/deals/${id}/remove_person`, { person_id: personId });
            if (response.data.success) {
                setDeal((prevDeal) => ({
                    ...prevDeal,
                    people: (prevDeal.people || []).filter((person) => person.id !== personId),
                    founders: (prevDeal.founders || []).filter((founder) => founder.id !== personId),
                }));
                setEditableDeal((prevDeal) => ({
                    ...prevDeal,
                    people: (prevDeal.people || []).filter((person) => person.id !== personId),
                    founders: (prevDeal.founders || []).filter((founder) => founder.id !== personId),
                }));
                console.log('Person removed successfully');
                // Remove the navigation and page reload
                // navigate(`${location.pathname}?tab=people`);
                // window.location.reload();
            } else {
                console.error('Failed to remove person from deal:', response.data.message);
            }
        } catch (error) {
            console.error('Error removing person from deal:', error);
        }
    };

    const handleStageChange = async (newStage) => {
        const now = new Date().toISOString();

        setEditableDeal(prevDeal => ({
            ...prevDeal,
            stage: newStage,
            last_updated: now
        }));

        try {
            await axios.put(`${baseUrl}/api/deals/${id}`, {
                stage: newStage,
                last_updated: now,
            });
            setDeal(prevDeal => ({
                ...prevDeal,
                stage: newStage,
                last_updated: now
            }));
            console.log('Stage updated successfully');
        } catch (error) {
            console.error('Error updating stage:', error);
            // Revert the change if the API call fails
            setEditableDeal(prevDeal => ({
                ...prevDeal,
                stage: deal.stage,
                last_updated: deal.last_updated
            }));
        }
    };

    console.log('Rendering Deal component, deal:', deal, 'showChatPanel:', showChatPanel);

    if (!deal || !editableDeal) {
        return <div>Loading...</div>;
    }

    return (
        <div className="deal-page">
            <div className="deal-header">
                {isEditing.name ? (
                    <input
                        type="text"
                        name="company_name"
                        value={editableDeal.company_name || ''}
                        onChange={handleInputChange}
                        onBlur={() => handleBlur('name')}
                        className="deal-title"
                        autoFocus
                    />
                ) : (
                    <div
                        className="deal-title"
                        onClick={() => setIsEditing((prev) => ({ ...prev, name: true }))}
                    >
                        {editableDeal.company_name || 'Click to edit'}
                    </div>
                )}
                <div className="deal-stage">
                    <select
                        value={editableDeal.stage || ''}
                        onChange={(e) => handleStageChange(e.target.value)}
                        className="stage-dropdown"
                    >
                        <option value="">Select Stage</option>
                        {STAGE_OPTIONS.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="deal-toolbar">
                <button className={activeTab === 'overview' ? 'active' : ''} onClick={() => setActiveTab('overview')}>Overview</button>
                <button className={activeTab === 'company' ? 'active' : ''} onClick={() => setActiveTab('company')}>Company</button>
                <button className={activeTab === 'people' ? 'active' : ''} onClick={() => setActiveTab('people')}>People</button>
                <button className={activeTab === 'files' ? 'active' : ''} onClick={() => setActiveTab('files')}>Files</button>
            </div>
            <div className="tab-content">
                {activeTab === 'overview' && (
                    <div className="overview-info">
                        <h3>Memo</h3>
                        <MemoSection
                            dealId={id}
                            initialMemo={deal.memo}
                            onMemoUpdate={handleMemoUpdate}
                            dealInfo={deal}
                        />
                    </div>
                )}
                {activeTab === 'company' && (
                    <div className="company-info">
                        {deal.company ? (
                            <div>
                                <h3>Company Information</h3>
                                <p>Name: {deal.company.name}</p>
                                <p>Website: {deal.company.website}</p>
                                <p>LinkedIn: {deal.company.linkedin}</p>
                                <button onClick={handleRemoveCompany}>Remove Company from Deal</button>
                            </div>
                        ) : (
                            <div>
                                <h3>Add Company to Deal</h3>
                                <Select
                                    options={companyOptions}
                                    onChange={(selectedOption) => setEditableDeal(prevDeal => ({ ...prevDeal, company_id: selectedOption.value }))}
                                    placeholder="Select a company"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                />
                                <button onClick={handleAddCompany}>Add Company to Deal</button>
                            </div>
                        )}
                    </div>
                )}
                {activeTab === 'people' && (
                    <div className="people-info">
                        <h3>People Information</h3>
                        {deal.people && deal.people.length > 0 ? (
                            <ul>
                                {deal.people.map((person) => (
                                    <li key={person.id}>
                                        <div>
                                            {editPersonData[person.id]?.isEditing?.first_name ? (
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={editPersonData[person.id]?.first_name ?? person.first_name}
                                                    onChange={(e) => handlePersonInputChange(e, person.id)}
                                                    onBlur={() => handlePersonBlur(person.id, 'first_name')}
                                                    autoFocus
                                                />
                                            ) : (
                                                <h4 onClick={() => handlePersonEdit(person.id, 'first_name')}>
                                                    {person.first_name} {person.last_name}
                                                </h4>
                                            )}
                                            {editPersonData[person.id]?.isEditing?.last_name ? (
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={editPersonData[person.id]?.last_name ?? person.last_name}
                                                    onChange={(e) => handlePersonInputChange(e, person.id)}
                                                    onBlur={() => handlePersonBlur(person.id, 'last_name')}
                                                    autoFocus
                                                />
                                            ) : (
                                                <h4 onClick={() => handlePersonEdit(person.id, 'last_name')}>
                                                    {person.last_name}
                                                </h4>
                                            )}
                                            {editPersonData[person.id]?.isEditing?.email ? (
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={editPersonData[person.id]?.email ?? person.email}
                                                    onChange={(e) => handlePersonInputChange(e, person.id)}
                                                    onBlur={() => handlePersonBlur(person.id, 'email')}
                                                    autoFocus
                                                />
                                            ) : (
                                                <p onClick={() => handlePersonEdit(person.id, 'email')}>
                                                    Email: {person.email || 'Click to add'}
                                                </p>
                                            )}
                                            {editPersonData[person.id]?.isEditing?.title ? (
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={editPersonData[person.id]?.title ?? person.title}
                                                    onChange={(e) => handlePersonInputChange(e, person.id)}
                                                    onBlur={() => handlePersonBlur(person.id, 'title')}
                                                    autoFocus
                                                />
                                            ) : (
                                                <p onClick={() => handlePersonEdit(person.id, 'title')}>
                                                    Title: {person.title || 'Click to add'}
                                                </p>
                                            )}
                                            {editPersonData[person.id]?.isEditing?.location ? (
                                                <input
                                                    type="text"
                                                    name="location"
                                                    value={editPersonData[person.id]?.location ?? person.location}
                                                    onChange={(e) => handlePersonInputChange(e, person.id)}
                                                    onBlur={() => handlePersonBlur(person.id, 'location')}
                                                    autoFocus
                                                />
                                            ) : (
                                                <p onClick={() => handlePersonEdit(person.id, 'location')} style={{ color: 'grey', fontSize: 'small' }}>
                                                    {person.location || 'Click to add'}
                                                </p>
                                            )}
                                            {editPersonData[person.id]?.isEditing?.notes ? (
                                                <textarea
                                                    name="notes"
                                                    value={editPersonData[person.id]?.notes ?? person.notes ?? ''}
                                                    onChange={(e) => handlePersonInputChange(e, person.id)}
                                                    onBlur={() => handlePersonBlur(person.id, 'notes')}
                                                    autoFocus
                                                />
                                            ) : (
                                                <p onClick={() => handlePersonEdit(person.id, 'notes')}>
                                                    Notes:
                                                    <ul>
                                                        {parseNotes(person.notes).map((note, index) => (
                                                            <li key={index}>{note}</li>
                                                        ))}
                                                    </ul>
                                                </p>
                                            )}
                                            <button onClick={() => handleRemovePersonFromDeal(person.id)}>Remove</button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No people associated with this deal.</p>
                        )}
                        {deal.founders && deal.founders.length > 0 && (
                            <div className="founders-info">
                                <h3>Founders</h3>
                                <ul>
                                    {deal.founders.map((founder) => (
                                        <li key={founder.id}>
                                            <div>
                                                <h4>
                                                    <a href={`http://${founder.linkedin}`} target="_blank" rel="noopener noreferrer">
                                                        {founder.first_name} {founder.last_name}
                                                    </a>
                                                </h4>
                                                <p style={{ color: 'grey', fontSize: 'small' }}>{founder.location}</p>
                                                <p>Email: {founder.email}</p>
                                                <p>Notes:</p>
                                                <ul>
                                                    {parseNotes(founder.notes).map((note, index) => (
                                                        <li key={index}>{note}</li>
                                                    ))}
                                                </ul>
                                                <button onClick={() => handleRemovePersonFromDeal(founder.id)}>Remove</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="add-people-section">
                            <h4>Add Existing People</h4>
                            <Select
                                options={allPeople.map(person => ({
                                    value: person.id,
                                    label: `${person.first_name} ${person.last_name}`
                                }))}
                                isMulti
                                value={selectedPeople}
                                onChange={setSelectedPeople}
                                placeholder="Select people to add"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                styles={customStyles}
                            />
                            <button onClick={handleAddPeopleToDeal} className="add-people-button">Add People</button>
                        </div>
                    </div>
                )}
                {activeTab === 'files' && (
                    <div className="files-info">
                        <h3>Files</h3>
                        <div className="file-input-wrapper">
                            <label htmlFor="file-input" className="file-input-label">Select File</label>
                            <input type="file" id="file-input" className="file-input" onChange={handleFileChange} ref={fileInputRef} />
                            <button className="file-input-button" onClick={handleFileUpload} disabled={uploading || !selectedFile}>
                                {uploading ? 'Uploading...' : 'Upload File'}
                            </button>
                        </div>
                        <div className="file-category">
                            <ul className="file-list">
                                {files.deal && files.deal.map(file => (
                                    <li key={file}>
                                        {file}
                                        <div>
                                            <button onClick={() => handleFileDownload(file, 'deal')}>Download</button>
                                            <button onClick={() => handleFileDelete(file, 'deal')}>Delete</button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {deal.company_id && (
                            <div className="file-category">
                                <ul className="file-list">
                                    {files.company && files.company.map(file => (
                                        <li key={file}>
                                            {file}
                                            <div>
                                                <button onClick={() => handleFileDownload(file, 'company')}>Download</button>
                                                <button onClick={() => handleFileDelete(file, 'company')}>Delete</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {deal.founders && deal.founders.length > 0 && (
                            <div className="file-category">
                                {deal.founders.map(founder => (
                                    <div key={founder.id}>
                                        <ul className="file-list">
                                            {files.founders && files.founders[founder.id] && files.founders[founder.id].map(file => (
                                                <li key={file}>
                                                    {file}
                                                    <div>
                                                        <button onClick={() => handleFileDownload(file, 'founders', founder.id)}>Download</button>
                                                        <button onClick={() => handleFileDelete(file, 'founders', founder.id)}>Delete</button>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {showChatPanel && deal && Object.keys(deal).length > 0 && (
                <ChatPanel
                    dealInfo={deal}
                    onClose={() => setShowChatPanel(false)}
                />
            )}
        </div>
    );
};

export default Deal;