import React, { useState, useCallback } from 'react';
import './NewMessageComposer.css';
import { FaPaperPlane, FaTimes, FaMagic } from 'react-icons/fa';
import { getBaseUrl } from './ApiContext';
import { useAuth } from './AuthContext';

function NewMessageComposer({ toEmail, onSend, onCancel, dealInfo, messageType, onSneakyRefresh }) {
    const { user } = useAuth();
    const [subject, setSubject] = useState(messageType === 'intro_request' ? 'Founder Intro?' : 'Intro to Kyber Knight Capital');
    const [body, setBody] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [to, setTo] = useState(toEmail || '');
    const [isSending, setIsSending] = useState(false);

    const addIntroRequestToPerson = async (personId, threadId) => {
        try {
            const baseUrl = getBaseUrl();
            const response = await fetch(`${baseUrl}/api/people/${personId}/add_intro_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    thread_id: threadId,
                    sender_email: user?.email,
                    user_given_name: user?.given_name || user?.name,
                    deal_id: dealInfo?.id,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to add intro request to person');
            }

            const result = await response.json();
            console.log('Intro request added to person:', result);
        } catch (error) {
            console.error('Error adding intro request to person:', error);
        }
    };

    const handleSend = useCallback(async () => {
        console.log('handleSend called');
        if (!to) {
            console.error('No recipient specified');
            return;
        }

        if (isSending) return;
        setIsSending(true);
        try {
            const baseUrl = getBaseUrl();
            const requestBody = {
                from: user?.email,
                fromName: `${user?.given_name || ''} ${user?.family_name || ''}`.trim() || user?.name || '',
                to: to,
                subject: subject,
                body: body,
            };
            console.log('Sending email with data:', requestBody);

            const response = await fetch(`${baseUrl}/api/email/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', response.status, errorText);
                throw new Error(`Failed to send email: ${response.status} ${errorText}`);
            }

            const result = await response.json();
            console.log('Email sent successfully:', result);

            let newTag = null;
            if (dealInfo?.founders && dealInfo.founders.length > 0) {
                const userName = user?.given_name || user?.name || 'Unknown User';
                newTag = messageType === 'intro_request'
                    ? `${userName} Requested Intro`
                    : `${userName} Sent Outreach`;

                const founder = dealInfo.founders[0];
                await fetch(`${baseUrl}/api/people/${founder.id}/add_outreach_tag`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_given_name: userName,
                        tag: newTag
                    }),
                });
                console.log(`Added tag: ${newTag} to founder: ${founder.id}`);

                // Add this block to save intro requests
                if (messageType === 'intro_request' && result.threadId) {
                    await addIntroRequestToPerson(founder.id, result.threadId);
                }
            } else {
                console.error('No founders associated with this deal');
            }

            onSend(subject, body, to, newTag);

            // Call onSneakyRefresh after successful send
            if (onSneakyRefresh) {
                await onSneakyRefresh();
            }
        } catch (error) {
            console.error('Error sending email:', error);
        } finally {
            setIsSending(false);
        }
    }, [to, subject, body, user, onSend, messageType, dealInfo, onSneakyRefresh]);

    const handleGenerateMessage = async () => {
        setIsGenerating(true);
        const baseUrl = getBaseUrl();
        let generatedMessage = '';

        // Safely access dealInfo properties
        const company = dealInfo?.company || {};
        const founder = dealInfo?.founders && dealInfo.founders.length > 0 ? dealInfo.founders[0] : {};

        let prompt = '';

        if (messageType === 'intro_request') {
            setSubject('Founder Intro?');
            prompt = `
            Craft a brief, friendly intro request message using the following information:
            Founder: ${founder.first_name || ''} ${founder.last_name || ''}
            Founder LinkedIn: ${founder.linkedin || '#'}
            Company: ${company.name || dealInfo?.company_name || 'N/A'}
            Industry: ${company.description || ''}

            The message should:
            1. Start with "Hey [Placeholder],"
            2. If there's an industry, mention you've been looking into it. If not, just say you came across the founder.
            3. Include the founder's name as a hyperlink to their LinkedIn profile
            4. Ask if the recipient would be willing to make an intro
             5. Keep the message under 100 words
            6. Sign off with "Best," followed by "${user?.name || 'Kyber Knight Capital'}"

            Here's an example of the desired format:
            Hey [Placeholder],

            Hope things are all good. I've been diving into [industry] recently and came across [Founder Name] ([Founder LinkedIn]). It looks like you two might be connected. Would you be open to helping me with an introduction to them? Thank you so much!

            If there's no industry, use something like:
            Hope things are all good. I recently came across [Founder Name] ([Founder LinkedIn]) and their work. It looks like you two might be connected. Would you be open to helping me with an introduction to them? Thank you so much!

            Only respond with the email body, following this format closely and ensuring the founder's name is a hyperlink.
            `;
        } else {
            // Use the existing prompt for cold outreach
            prompt = `
            Craft a personalized outreach message for the following person:
            Name: ${founder.first_name || ''} ${founder.last_name || ''}
            Title: ${founder.title || 'N/A'}
            Company: ${company.name || dealInfo?.company_name || 'N/A'}
            Company Website: ${company.website || 'N/A'}
            Company LinkedIn: ${company.linkedin || 'N/A'}
            Founder LinkedIn: ${founder.linkedin || 'N/A'}
            Company Description: ${company.description || 'N/A'}
            Founder Notes: ${parseNotes(founder.notes).join(', ') || 'N/A'}
            Deal Stage: ${dealInfo?.stage || 'N/A'}
            Signal Received Date: ${dealInfo?.date_created ? new Date(dealInfo.date_created).toLocaleDateString() : 'N/A'}

            Context: You're reaching out from Kyber Knight Capital, an early-stage venture capital firm that helps companies accelerate their go-to-market and sales processes.
            The firm's LPs include CEOs and founders of Fortune 100 companies, such as:

            - John Hennessy (Chairman of Google, ex-President of Stanford)
            - Wendy White (co-head of Google X)
            - Joe White (British ambassador to the US for innovation)
            - Meg Whitman (ex-CEO of eBay & HP, board member at P&G, GM)
            - Michael Eisner (ex-CEO and Chairman of The Walt Disney Company)
            - Kevin Tsujihara (ex-CEO and Chairman of Warner Bros)
            - Gary Barber (ex-CEO and Chairman of MGM Studios)
            - Chris DeFaria (ex-President of DreamWorks Animation)
            - Toby Emmerich (producer of the Lord of the Rings and Hobbit film trilogies)
            - Mark Pincus (founder of Zynga)
            - Bernard Kim (President of Zynga)
            - David Ko (ex-COO of Zynga, early Yahoo, ex-CEO of Rally Health)
            - Ex-Chief Digital Officer of Nike
            - Owen Mahoney (CEO of Nexon)
            - Kevin Lin (co-founder of Twitch)
            - Joe Gebbia (co-founder of Airbnb)
            - Ryan Roslansky (CEO of LinkedIn)
            - Michael Vaughn (COO of Venmo)
            - David Sacks (ex-COO @ Paypal, CEO of Yammer, VC @ Craft Ventures)
            - Bill Lee (first angel investor in all of Elon Musk's companies and his best friend)
            - Vivek Sharma (global head of gaming at Facebook)
            - Kent Wakeford (co-founder of Kabam)
            - Gil Penchina (co-founder of Fastly, early eBay / PayPal)
            - Sam Shank (founder of Hotel Tonight, senior AirBnB)
            - Greg Harper (head of US for Supercell)
            - Terence Fung (Chief Strategy Officer @ Storm8)
            - Thomas Gewecke (ex-Chief Digital Officer of Warner Bros)
            - Justin Choi (founder of Cie Games)
            - Josh Resnick (co-founder of Bioware Pandemic & Sugarfina)
            - Moiz Ali (founder of Native Deodorant)
            - Steve King (co-founder of Manscaped)
            - Isabelle Parize (ex-CEO of Douglass)
            - Eros Resmini (ex-CMO of Discord)
            - Avni Yerli (CEO/founder of Crytek)
            - Daniel Kan (co-founder of Cruise Automation)
            - Jason Tan (founder of Sift Science)
            - Harpreet Rai (founder of Oura)
            - Managing partner of Towerbrook (George Soros spun out PE fund)
            - Alex Dibelius (managing partner at CVC private equity)
            - Jenny Baxter Moser (managing partner at TSG Consumer Growth Partners)
            - Jon Green (founding partner at Luxor Capital)
            - Jean Luc Allavena (senior partner at Apollo Europe)
            - Larry Berg (senior west coast partner at Apollo)
            - Mike Michaelson (former head of US private equity at KKR)
            - Ken Denman (board member at Costco, VMWare, Motorola)

            The message should:
            1. Be friendly and personalized
            2. Mention something specific about their background or company
            3. Briefly highlight Kyber Knight Capital's impressive network
            4. Ask to get on a call to see how Kyber Knight Capital might be helpful to their business
            5. Make the message hyper-personalized and extremely genuine
            6. Do not include special characters such as tm, ©, etc.
            7. Do not include links
            8. Keep the message under 250 words.
            9. Address the recipient by their first name
            10. Bring up the LPs or their companies, if it's relevant. Otherwise, just speak broadly about them.

            Here is an example of a good message:

            Name,

            I wanted to reach out because I heard that you and your team at [Company Name] closed your preseed round a few months ago. I'm part of the investment team at Kyber Knight. We invest at the early inflection points of a company's journey and connect them with our active LPs such as John Hennessy (Google), Michael Eisner (Disney), Meg Whitman (Ebay), etc to rapidly accelerate their go to market strategy.

            We have spent the past 9 years investing in and helping to build startups across a bunch of different sectors such as Cruise (acquired by GM), Tenor (acquired by Google), Sendoso, Manscaped, etc.

            At a high level, it sounds like you're building a flexible platform that utilizes external data and AI to clean and structure CRM data, with seamless integration into Salesforce and HubSpot. Your product seems customized to solve specific business challenges by applying machine intelligence across all business functions. If that's the case, I think we might be valuable partners. Let me know if you have time to chat, I'd love to learn more.

            Best,
            Sal

            If we do not have enough information to personalize the email, don't make anything up. Only respond with the email body and sign off with "${user?.name || 'Kyber Knight Capital'}".
            `;
        }

        try {
            const response = await fetch(`${baseUrl}/api/chat`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    message: prompt,
                    chatHistory: [],
                    founder_ids: founder.id ? [founder.id] : [],
                    company_id: company.id || dealInfo?.company_id,
                    deal_id: dealInfo?.id,
                }),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n\n');

                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const content = line.slice(6).trim();
                        if (content === '[DONE]') break;
                        if (content) {
                            try {
                                const jsonData = JSON.parse(content);
                                if (jsonData.type === 'content') {
                                    generatedMessage += jsonData.content;
                                    setBody(generatedMessage);
                                }
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error generating message:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    // Add this helper function
    const parseNotes = (notes) => {
        if (!notes || notes === 'null' || notes === '') return [];
        try {
            const parsedNotes = JSON.parse(notes);
            return Array.isArray(parsedNotes) ? parsedNotes : [notes];
        } catch (error) {
            console.error('Error parsing notes:', error);
            return notes.split(',').map(note => note.trim());
        }
    };

    return (
        <div className="new-message-composer" style={{ maxHeight: '90vh', position: 'relative' }}>
            <button className="cancel-button" onClick={onCancel}>
                <FaTimes />
            </button>
            <div className="composer-content">
                <div className="composer-field">
                    <label>To:</label>
                    <input
                        type="text"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        placeholder="Enter email address"
                    />
                </div>
                <div className="composer-field">
                    <label>Subject:</label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="Enter subject"
                    />
                </div>
                <div className="composer-body">
                    <textarea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        placeholder="Write your message here..."
                    />
                </div>
            </div>
            <div className="composer-footer">
                <button
                    className="generate-button"
                    onClick={handleGenerateMessage}
                    disabled={isGenerating}
                >
                    <FaMagic /> <span>
                        {isGenerating
                            ? 'Generating...'
                            : messageType === 'intro_request'
                                ? 'Generate Intro Request'
                                : 'Generate Outreach Message'}
                    </span>
                </button>
                <button
                    className="send-button"
                    onClick={handleSend}
                    disabled={isSending}
                >
                    <FaPaperPlane /> <span>{isSending ? 'Sending...' : 'Send'}</span>
                </button>
            </div>
        </div>
    );
}

export default NewMessageComposer;