import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './ChatPanel.css';
import { generateChatContext } from './Deal';
import { getBaseUrl } from './ApiContext';

const baseUrl = getBaseUrl();

const ChatPanel = ({ dealInfo, onClose, highlights, setMemo, saveMemo }) => {
    const [chatContext, setChatContext] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [responseText, setResponseText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sessionHistory, setSessionHistory] = useState([]);
    const highlightsRef = useRef(null);
    const [replaceSections, setReplaceSections] = useState([]);
    const inputRef = useRef(null);
    const [lastHighlight, setLastHighlight] = useState(null);
    const [processedHighlights, setProcessedHighlights] = useState([]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        console.log('ChatPanel received dealInfo:', dealInfo);
        console.log('Highlights:', highlights);
        if (dealInfo) {
            const context = generateChatContext(dealInfo);
            setChatContext(context);
            console.log('Generated chat context:', context);
        } else {
            console.error('dealInfo is undefined in ChatPanel');
        }
    }, [dealInfo]);

    useEffect(() => {
        const addNewHighlights = () => {
            const uniqueHighlights = [...new Set(highlights)];
            const newHighlights = uniqueHighlights.filter(highlight => !processedHighlights.includes(highlight));

            if (newHighlights.length > 0) {
                setSessionHistory(prev => [
                    ...prev,
                    ...newHighlights.map(highlight => ({ type: 'highlight', content: highlight }))
                ]);
                setProcessedHighlights(prev => [...prev, ...newHighlights]);
                setLastHighlight(newHighlights[newHighlights.length - 1]);
            }
        };

        addNewHighlights();
    }, [highlights]);

    useEffect(() => {
        console.log('Session History:', sessionHistory);
    }, [sessionHistory]);

    const renderParagraph = useCallback(({ children }) => {
        const processNode = (node) => {
            if (typeof node === 'string') {
                return node.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                        {line}
                        <br />
                    </React.Fragment>
                ));
            }
            return node;
        };

        return (
            <React.Fragment>
                {Array.isArray(children)
                    ? children.map((child, index) => <React.Fragment key={index}>{processNode(child)}</React.Fragment>)
                    : processNode(children)}
            </React.Fragment>
        );
    }, []);

    const renderSessionHistory = () => {
        // Skip the first entry if it's a highlight
        const startIndex = sessionHistory.length > 0 && sessionHistory[0].type === 'highlight' ? 1 : 0;

        return sessionHistory.slice(startIndex).map((item, index) => {
            if (item.type === 'highlight' && item.content.trim() === '') {
                return null; // Skip empty highlights
            }
            return (
                <div key={index} className={`message ${item.type}`}>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            p: renderParagraph
                        }}
                    >
                        {item.content}
                    </ReactMarkdown>
                </div>
            );
        }).filter(Boolean); // Remove null entries
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage(inputMessage);
        }
    };

    const detectMarkdownSections = (response) => {
        const sectionRegex = /^#+\s+(.+)$/gm;
        const sections = [];
        let match;
        while ((match = sectionRegex.exec(response)) !== null) {
            sections.push({
                title: match[1],
                content: response.slice(match.index),
                selected: false
            });
        }
        return sections;
    };

    const handleReplaceSection = (index) => {
        setReplaceSections(prev =>
            prev.map((section, i) =>
                i === index ? { ...section, selected: !section.selected } : section
            )
        );
    };

    const applyReplacements = () => {
        setMemo(prevMemo => {
            let newMemo = prevMemo;
            replaceSections.forEach(section => {
                if (section.selected) {
                    const sectionRegex = new RegExp(`#+\\s+${section.title}[\\s\\S]*?(?=\\n#+\\s+|$)`, 'i');
                    newMemo = newMemo.replace(sectionRegex, section.content.trim());
                }
            });
            return newMemo;
        });
        setReplaceSections([]);
        saveMemo(); // Call the saveMemo function after applying replacements
    };

    const sendMessage = async (message) => {
        setIsLoading(true);
        let accumulatedResponse = '';

        setSessionHistory(prev => [...prev, { type: 'user', content: message }]);

        // Use the lastHighlight state in the message
        const fullMessage = `
Company and People Information:
${JSON.stringify(dealInfo, null, 2)}

${lastHighlight ? `Last Highlight:\n${lastHighlight}\n\n` : ''}
User message:
${message}
        `.trim();

        try {
            const founderIds = dealInfo?.founders?.map(founder => founder.id) || [];

            const response = await fetch(`${baseUrl}/api/chat`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    message: fullMessage,
                    chatHistory,
                    founder_ids: founderIds,
                    company_id: dealInfo?.id, // Assuming the company ID is stored in dealInfo.id
                    files: dealInfo?.files || [],
                    deal_id: dealInfo?.id // Assuming deal_id is the same as company_id
                }),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                console.log('Received chunk:', chunk); // Log the raw chunk

                const lines = chunk.split('\n\n');

                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const content = line.slice(6).trim();
                        if (content === '[DONE]') {
                            console.log('Stream completed');
                            break;
                        }
                        if (content) {
                            try {
                                const jsonData = JSON.parse(content);
                                if (jsonData.type === 'content') {
                                    accumulatedResponse += jsonData.content;
                                    setResponseText(accumulatedResponse);
                                } else {
                                    console.warn('Unexpected data type:', jsonData.type);
                                }
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        }
                    }
                }
            }

            console.log('Final accumulated response:', accumulatedResponse);

            const detectedSections = detectMarkdownSections(accumulatedResponse);
            setReplaceSections(detectedSections);

            setChatHistory(prev => [
                ...prev,
                { role: 'user', content: message },
                { role: 'assistant', content: accumulatedResponse }
            ]);
            setSessionHistory(prev => [...prev, { type: 'assistant', content: accumulatedResponse }]);
            setResponseText('');
            setInputMessage('');
        } catch (error) {
            console.error('Error:', error);
            setResponseText('An error occurred while fetching the response.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="chat-panel">
            <div className="chat-panel-header">
                <h3>Chat</h3>
                <button onClick={onClose}>Close</button>
            </div>
            <div className="chat-history">
                {renderSessionHistory()}
                {responseText && (
                    <div className="message assistant">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                p: renderParagraph
                            }}
                        >
                            {responseText}
                        </ReactMarkdown>
                    </div>
                )}
                {replaceSections.length > 0 && (
                    <div className="replace-sections">
                        <h4>Replace sections:</h4>
                        {replaceSections.map((section, index) => (
                            <div key={index}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={section.selected}
                                        onChange={() => handleReplaceSection(index)}
                                    />
                                    {section.title}
                                </label>
                            </div>
                        ))}
                        <button onClick={applyReplacements}>Apply Replacements</button>
                    </div>
                )}
            </div>
            <div className="chat-input-area">
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Type your message..."
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button onClick={() => sendMessage(inputMessage)}>Send</button>
            </div>
        </div>
    );
};

export default ChatPanel;