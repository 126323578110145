import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getBaseUrl } from './ApiContext';



const baseUrl = getBaseUrl();

function Company() {
    const { id } = useParams();
    const [companyData, setCompanyData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/companies/${id}/details`);
                setCompanyData(response.data);
            } catch (error) {
                console.error('Error fetching company data:', error);
                setError('Failed to load company data. Please try again later.');
            }
        };

        fetchCompanyData();
    }, [id]);

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!companyData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Company Details</h1>
            <h2>{companyData.companyName || companyData.displayName}</h2>
            <p>Website: {companyData.website || 'N/A'}</p>
            <p>LinkedIn: {companyData.linkedin || 'N/A'}</p>
            <p>Location: {companyData.location || 'N/A'}</p>

            <h3>Founders</h3>
            {companyData.founders && companyData.founders.length > 0 ? (
                companyData.founders.map(founder => (
                    <div key={founder.id}>
                        <h4>{founder.first_name} {founder.last_name}</h4>
                        <p>Email: {founder.email || 'N/A'}</p>
                        <p>LinkedIn: {founder.linkedin || 'N/A'}</p>
                    </div>
                ))
            ) : (
                <p>No founder information available.</p>
            )}
        </div>
    );
}

export default Company;