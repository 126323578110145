import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import './App.css'; // Ensure this import is present
import { getBaseUrl } from './ApiContext';



const baseUrl = getBaseUrl();

const FounderPanel = ({ founders, onClose, onAddFounder, onRemoveFounder, currentCompanyId }) => {
    const [allPeople, setAllPeople] = useState([]);
    const [founderOptions, setFounderOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFounders, setSelectedFounders] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]); // Add state for selected options
    const [companyInfo, setCompanyInfo] = useState({}); // Add state for company info

    useEffect(() => {
        console.log('FounderPanel currentCompanyId:', currentCompanyId); // Debugging log
        fetchAllPeople();
        fetchCompanyInfo(); // Fetch company info when component mounts or currentCompanyId changes
    }, [currentCompanyId]);

    const fetchAllPeople = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people`, {
                params: { fetch_all: true }
            });
            const people = Array.isArray(response.data.people) ? response.data.people : [];
            setAllPeople(people);
            setFounderOptions(people.map(person => ({
                value: person.id,
                label: formatPersonLabel(person), // Ensure label is set
                email: person.email || '',
                linkedin: person.linkedin || '',
                location: person.location || '',
                title: person.title || ''
            })));
        } catch (error) {
            console.error('Error fetching people:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCompanyInfo = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/companies/${currentCompanyId}`);
            setCompanyInfo(response.data);
        } catch (error) {
            console.error('Error fetching company info:', error);
        }
    };

    const formatPersonLabel = (person) => {
        const name = `${person.first_name || ''} ${person.last_name || ''}`.trim();
        const email = person.email ? `(${person.email})` : '';
        const location = person.location ? `[${person.location}]` : '';
        const title = person.title ? `- ${truncateTitle(person.title)}` : '';
        return `${name} ${email} ${location} ${title}`.trim();
    };

    const truncateTitle = (title) => {
        if (title.length <= 20) return title;
        return title.substring(0, 20) + '...';
    };

    const handleFounderSelect = (selectedOptions) => {
        console.log('currentCompanyId in handleFounderSelect:', currentCompanyId); // Debugging log
        console.log('selectedOptions:', selectedOptions); // Debugging log

        const selectedFounders = selectedOptions.map(option => {
            const person = allPeople.find(p => p.id === option.value);
            if (!person) {
                console.error('Person not found for option:', option); // Debugging log
                return null;
            }

            return {
                first_name: person.first_name,
                last_name: person.last_name,
                email: person.email,
                linkedin: person.linkedin,
                location: person.location,
                title: person.title
            };
        }).filter(founder => founder !== null);

        console.log('selectedFounders:', selectedFounders); // Debugging log
        setSelectedFounders(selectedFounders);
        setSelectedOptions(selectedOptions); // Update selected options state
    };

    const handleAddFounders = async () => {
        try {
            const addFounderPromises = selectedFounders.map(async (founder) => {
                const founderWithCompanyInfo = {
                    first_name: founder.first_name,
                    last_name: founder.last_name,
                    email: founder.email,
                    linkedin: founder.linkedin,
                    location: founder.location,
                    title: founder.title,
                    company_website: companyInfo.website, // Set company_website from company info
                    company_linkedin: companyInfo.linkedin // Set company_linkedin from company info
                };
                console.log('Sending founder data:', founderWithCompanyInfo); // Log the data being sent
                const response = await axios.post(`${baseUrl}/api/companies/${currentCompanyId}/add_person`, founderWithCompanyInfo);
                console.log('Response from adding founder:', response.data);
            });

            await Promise.all(addFounderPromises);

            setSelectedFounders([]); // Clear selected founders after adding
            setSelectedOptions([]); // Clear selected options in react-select
            fetchAllPeople(); // Re-fetch all people to update the panel
            await fetchCompanyFounders(); // Fetch updated list of company founders
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setSelectedFounders([]); // Clear selected founders after adding
            setSelectedOptions([]); // Clear selected options in react-select
            fetchAllPeople(); // Re-fetch all people to update the panel
            await fetchCompanyFounders(); // Fetch updated list of company founders
            console.error('Error adding founders:', error);
        }
    };

    const fetchCompanyFounders = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/companies/${currentCompanyId}/founders`);
            onAddFounder(response.data.founders); // Update the founders list
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.error('Company founders endpoint not found:', error);
            } else {
                console.error('Error fetching company founders:', error);
            }
        }
    };

    const handleRemoveFounder = async (founderId) => {
        try {
            await axios.delete(`${baseUrl}/api/companies/${currentCompanyId}/remove_person/${founderId}`);
            onRemoveFounder(founderId);
            fetchAllPeople(); // Re-fetch all people to update the panel
        } catch (error) {
            console.error('Error removing founder:', error);
        }
    };

    return (
        <div className="founder-panel">
            <h2>Founders</h2>
            {founders.map((founder, index) => (
                <div key={index} className="founder-item">
                    <div className="founder-content">
                        <p><strong>Name:</strong> {founder.first_name} {founder.last_name}</p>
                        <p><strong>Email:</strong> {founder.email}</p>
                        <p><strong>LinkedIn:</strong> {founder.linkedin ? (
                            <a href={founder.linkedin.startsWith('http') ? founder.linkedin : `https://${founder.linkedin}`} target="_blank" rel="noopener noreferrer">
                                {founder.linkedin}
                            </a>
                        ) : 'N/A'}
                        </p>
                        <p><strong>Location:</strong> {founder.location || 'N/A'}</p>
                        <p><strong>Title:</strong> {founder.title || 'N/A'}</p>
                    </div>
                    <button onClick={() => handleRemoveFounder(founder.id)}>Remove</button>
                </div>
            ))}
            <Select
                isMulti
                name="founders"
                options={founderOptions}
                value={selectedOptions} // Set the value to selectedOptions
                onChange={handleFounderSelect}
                placeholder="Search and select founders"
                className="react-select-container"
                classNamePrefix="react-select"
                isLoading={isLoading}
                loadingMessage={() => "Loading..."}
                noOptionsMessage={({ inputValue }) => inputValue.length < 2 ? "Type to search" : "No options"}
            />
            <button onClick={handleAddFounders} disabled={selectedFounders.length === 0}>Add Selected Founders</button>
            <button onClick={onClose}>Close</button>
        </div>
    );
};

export default FounderPanel;