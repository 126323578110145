import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getBaseUrl } from './ApiContext';
import OmniChat from './OmniChat';
import './OutboundEngine.css';
import { FaGlobe, FaLinkedin, FaFire, FaCopy, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { useAuth } from './AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const baseUrl = getBaseUrl();

function ExternalLink({ href, children }) {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
}

function DealRow({ deal, isExpanded, onToggle, onStageUpdate, onOutreachTagUpdate, onSneakyRefresh }) {
    const [isDisappearing, setIsDisappearing] = useState(false);
    const [isMovingToPipeline, setIsMovingToPipeline] = useState(false);
    const [showAllStatuses, setShowAllStatuses] = useState(false);

    const getLastStatusDate = (founders) => {
        if (!founders || founders.length === 0) return null;

        const allTimes = founders.flatMap(founder =>
            founder.outreach_sequence_tag_times || []
        ).filter(time => time);

        if (allTimes.length === 0) return null;

        return new Date(Math.max(...allTimes.map(time => new Date(time))));
    };

    const handleRowClick = (e) => {
        if (!e.target.closest('a') && !e.target.closest('.action-button-container')) {
            onToggle();
        }
    };

    const isStealthCompany = deal.company_name.toLowerCase().includes('stealth');
    const interestedFundNotes = deal.interested_fund_notes;
    const showFlame = interestedFundNotes && interestedFundNotes.length > 0;

    console.log(`Deal: ${deal.company_name}, Interested Fund Notes:`, interestedFundNotes, `Show Flame:`, showFlame);

    const copyOutreachMessage = (message) => {
        navigator.clipboard.writeText(message).then(() => {
            console.log('Outreach message copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleStageUpdate = async (newStage) => {
        if (newStage === 'Passed') {
            setIsDisappearing(true);
        } else if (newStage === 'Top of Funnel') {
            setIsMovingToPipeline(true);
        }

        setTimeout(() => {
            onStageUpdate(deal.id, newStage);
        }, 300);
    };

    const getOutreachStatuses = (founders) => {
        if (!founders || founders.length === 0) return ['Signal Received'];

        const allTags = founders.flatMap(founder => {
            const tags = founder.outreach_sequence_tags || [];
            const times = founder.outreach_sequence_tag_times || [];
            return tags.map((tag, index) => ({
                tag,
                time: times[index] ? new Date(times[index]) : null
            }));
        });

        allTags.sort((a, b) => (b.time || 0) - (a.time || 0));

        const uniqueTags = [...new Set(allTags.map(item => item.tag))];
        return uniqueTags.length > 0 ? uniqueTags : ['Signal Received'];
    };

    const isReachedOutTag = (tag) => {
        return tag.includes("Requested LinkedIn Connection") ||
            tag.includes("Sent Outreach") ||
            tag.includes("Requested Intro");
    };

    const renderStatuses = () => {
        const statuses = getOutreachStatuses(deal.founders);
        const reachedOut = statuses.some(isReachedOutTag);
        const lastStatusDate = getLastStatusDate(deal.founders);
        const isStale = lastStatusDate && (new Date() - lastStatusDate) / (1000 * 60 * 60 * 24) >= 3;
        const inContact = statuses.some(tag => tag.includes('In Contact'));

        if (isStale) {
            return (
                <span className="signal-received-bubble red">
                    Stale: Action Required
                </span>
            );
        } else if (inContact) {
            return (
                <span className="signal-received-bubble green">
                    In Contact
                </span>
            );
        } else if (statuses.includes('Signal Received')) {
            return (
                <span className="signal-received-bubble">
                    Signal Received
                </span>
            );
        } else if (reachedOut) {
            return (
                <span className="signal-received-bubble yellow">
                    Reached Out
                </span>
            );
        } else if (statuses.length <= 2 || showAllStatuses) {
            return statuses.map((tag, index) => (
                <span key={index} className="signal-received-bubble">
                    {tag}
                </span>
            ));
        } else {
            return (
                <>
                    <span className="signal-received-bubble">
                        {statuses[0]}
                    </span>
                    <span
                        className="signal-received-bubble more-statuses"
                        onMouseEnter={() => setShowAllStatuses(true)}
                        onMouseLeave={() => setShowAllStatuses(false)}
                    >
                        ...
                    </span>
                </>
            );
        }
    };

    const renderActions = () => (
        <div className="action-button-container">
            <button
                className="action-button send-to-pipeline-button"
                onClick={(e) => {
                    e.stopPropagation();
                    handleStageUpdate('Top of Funnel');
                }}
            >
                Send to Pipeline
            </button>
            <button
                className="action-button pass-button"
                onClick={(e) => {
                    e.stopPropagation();
                    handleStageUpdate('Passed');
                }}
            >
                Pass
            </button>
        </div>
    );

    console.log('Founders:', deal.founders);

    return (
        <>
            <tr
                id={`deal-${deal.id}`}
                onClick={handleRowClick}
                className={`
                    ${isExpanded ? 'expanded' : ''} 
                    ${isDisappearing ? 'disappearing' : ''}
                    ${isMovingToPipeline ? 'moving-to-pipeline' : ''}
                `}
            >
                <td>
                    {deal.company_name}
                    {!isStealthCompany ? (
                        <>
                            {deal.company_website && (
                                <ExternalLink href={ensureHttps(deal.company_website)}>
                                    <FaGlobe className="icon-link" />
                                </ExternalLink>
                            )}
                            {deal.company_linkedin && (
                                <ExternalLink href={ensureHttps(deal.company_linkedin)}>
                                    <FaLinkedin className="icon-link" />
                                </ExternalLink>
                            )}
                        </>
                    ) : (
                        deal.founders && deal.founders.map((founder, index) => (
                            founder.linkedin && (
                                <span key={index}>
                                    <ExternalLink
                                        href={ensureHttps(founder.linkedin)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (founder.outreach_message) {
                                                copyOutreachMessage(founder.outreach_message);
                                            }
                                        }}
                                    >
                                        <FaLinkedin className="icon-link" />
                                    </ExternalLink>
                                </span>
                            )
                        ))
                    )}
                    {showFlame && (
                        <>
                            <span
                                className="icon-link"
                                data-tooltip-id={`flame-tooltip-${deal.id}`}
                                data-tooltip-html={`<strong class="tooltip-title">Strategic Signal</strong><br>${interestedFundNotes.map(note => note.replace(/["\[\]]/g, '')).join('<br>')}`}
                                data-tooltip-place="right"
                            >
                                <FaFire className="flame-icon" />
                            </span>
                            <Tooltip id={`flame-tooltip-${deal.id}`} className="react-tooltip" />
                        </>
                    )}
                </td>
                <td>
                    {renderStatuses()}
                </td>
                <td>{new Date(deal.date_created).toLocaleDateString()}</td>
                <td>
                    {renderActions()}
                </td>
            </tr>
            {isExpanded && (
                <tr className="chat-row">
                    <td colSpan="4">
                        <OmniChat
                            dealId={deal.id}
                            onOutreachTagUpdate={(founderId, newTag) => onOutreachTagUpdate(deal.id, founderId, newTag)}
                            onSneakyRefresh={onSneakyRefresh}
                        />
                    </td>
                </tr>
            )}
        </>
    );
}

function ensureHttps(url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
}

function StatusGroupHeader({ status, count, isExpanded, onToggle }) {
    return (
        <tr className="status-header" onClick={onToggle}>
            <td colSpan="4">
                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                {status} ({count})
            </td>
        </tr>
    );
}

function OutboundEngine() {
    const [deals, setDeals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [columnWidths, setColumnWidths] = useState({
        company: 30,
        status: 25,
        date: 15,
        actions: 30
    });

    const { user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const [expandedDealId, setExpandedDealId] = useState(queryParams.get('expandedDeal'));
    const [expandedGroups, setExpandedGroups] = useState(() => {
        const groups = queryParams.get('expandedGroups');
        return groups ? JSON.parse(groups) : {
            'Stale: Action Required': true,
            'Signal Received': true,
            'Reached Out': true,
            'In Contact': true,
            'Other': true
        };
    });

    const getOutreachStatuses = (founders) => {
        if (!founders || founders.length === 0) return ['Signal Received'];

        const allTags = founders.flatMap(founder => {
            const tags = founder.outreach_sequence_tags || [];
            const times = founder.outreach_sequence_tag_times || [];
            return tags.map((tag, index) => ({
                tag,
                time: times[index] ? new Date(times[index]) : null
            }));
        });

        allTags.sort((a, b) => (b.time || 0) - (a.time || 0));

        const uniqueTags = [...new Set(allTags.map(item => item.tag))];
        return uniqueTags.length > 0 ? uniqueTags : ['Signal Received'];
    };

    const isReachedOutTag = (tag) => {
        return tag.includes("Requested LinkedIn Connection") ||
            tag.includes("Sent Outreach") ||
            tag.includes("Requested Intro");
    };

    const getLastStatusDate = (founders) => {
        if (!founders || founders.length === 0) return null;

        const allTimes = founders.flatMap(founder =>
            founder.outreach_sequence_tag_times || []
        ).filter(time => time);

        if (allTimes.length === 0) return null;

        return new Date(Math.max(...allTimes.map(time => new Date(time))));
    };

    const getDealStatus = (deal) => {
        const statuses = getOutreachStatuses(deal.founders);
        const lastStatusDate = getLastStatusDate(deal.founders);
        const isStale = lastStatusDate && (new Date() - lastStatusDate) / (1000 * 60 * 60 * 24) >= 3;
        const inContact = statuses.some(tag => tag.includes('In Contact'));

        if (isStale) return 'Stale: Action Required';
        if (inContact) return 'In Contact';
        if (statuses.includes('Signal Received')) return 'Signal Received';
        if (statuses.some(isReachedOutTag)) return 'Reached Out';
        return 'Other';
    };

    const sortAndGroupDeals = (deals) => {
        const statusOrder = ['Stale: Action Required', 'Signal Received', 'Reached Out', 'In Contact', 'Other'];

        return deals.sort((a, b) => {
            const statusA = getDealStatus(a);
            const statusB = getDealStatus(b);
            if (statusA !== statusB) {
                return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
            }
            return new Date(b.date_created) - new Date(a.date_created);
        });
    };

    useEffect(() => {
        fetchOutboundDeals();
    }, []);

    useEffect(() => {
        // Update URL when expandedDealId or expandedGroups change
        const newParams = new URLSearchParams(location.search);
        if (expandedDealId) {
            newParams.set('expandedDeal', expandedDealId);
        } else {
            newParams.delete('expandedDeal');
        }
        newParams.set('expandedGroups', JSON.stringify(expandedGroups));
        navigate(`${location.pathname}?${newParams.toString()}`, { replace: true });
    }, [expandedDealId, expandedGroups, location.pathname, navigate]);

    const fetchOutboundDeals = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/deals`, {
                params: {
                    filter: `
                        deals.source IN ('Specter', 'Harmonic') 
                        AND deals.stage IN ('Top of Funnel', 'Awaiting Response')
                        AND deals.kkc_lead = 'Kyber Drive'
                    `,
                    fields: `
                        deals.id, 
                        deals.company_name, 
                        deals.founder_ids,
                        deals.date_created,
                        company.website AS company_website,
                        company.linkedin AS company_linkedin,
                        company.notes AS company_notes,
                        JSON_ARRAYAGG(
                            JSON_OBJECT(
                                'id', people.id,
                                'first_name', people.first_name,
                                'last_name', people.last_name,
                                'linkedin', people.linkedin,
                                'last_contacted', people.last_contacted,
                                'company_linkedin', people.company_linkedin,
                                'company_website', people.company_website,
                                'title', people.title,
                                'poc', people.poc,
                                'twitter', people.twitter,
                                'email', people.email,
                                'location', people.location,
                                'harmonic_url', people.harmonic_url,
                                'outreach_message', people.outreach_message,
                                'notes', IFNULL(people.notes, 'null'),
                                'date_created', people.date_created,
                                'last_updated', people.last_updated,
                                'messages_id', people.messages_id,
                                'outreach_sequence_tags', IFNULL(people.outreach_sequence_tags, 'null'),
                                'outreach_sequence_tag_times', IFNULL(people.outreach_sequence_tag_times, 'null')
                            )
                        ) AS founders_data
                    `,
                    join: `
                        LEFT JOIN company ON deals.company_id = company.id
                        LEFT JOIN JSON_TABLE(
                            deals.founder_ids,
                            '$[*]' COLUMNS (founder_id INT PATH '$')
                        ) AS ft ON 1=1
                        LEFT JOIN people ON ft.founder_id = people.id
                    `,
                    group_by: 'deals.id'
                }
            });
            if (response.data && response.data.deals) {
                const dealsWithFounders = response.data.deals.map(deal => {
                    console.log('Raw founders_data:', deal.founders_data);
                    let founders;
                    try {
                        founders = JSON.parse(deal.founders_data).map(founder => ({
                            ...founder,
                            notes: founder.notes === 'null' ? null : JSON.parse(founder.notes),
                            outreach_sequence_tags: founder.outreach_sequence_tags === 'null' ? [] : JSON.parse(founder.outreach_sequence_tags),
                            outreach_sequence_tag_times: founder.outreach_sequence_tag_times === 'null' ? [] : JSON.parse(founder.outreach_sequence_tag_times)
                        }));
                    } catch (error) {
                        console.error('Error parsing founders_data:', error);
                        founders = [];
                    }
                    console.log('Processed founders:', founders);

                    const companyNotes = parseInterestedFundNotes(deal.company_notes ? [deal.company_notes] : []);
                    console.log(`Company notes for ${deal.company_name}:`, companyNotes);

                    const founderNotes = founders.flatMap(founder => {
                        const notes = parseInterestedFundNotes(founder.notes || []);
                        console.log(`Notes for founder ${founder.first_name} ${founder.last_name}:`, notes);
                        return notes;
                    });

                    const allInterestedNotes = [...companyNotes, ...founderNotes];
                    console.log(`All interested notes for ${deal.company_name}:`, allInterestedNotes);

                    return {
                        ...deal,
                        founders,
                        interested_fund_notes: allInterestedNotes
                    };
                });
                setDeals(sortAndGroupDeals(dealsWithFounders));
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching outbound deals:', error);
        }
        setIsLoading(false);
    };

    const sneakyRefresh = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/deals`, {
                params: {
                    filter: `
                        deals.source IN ('Specter', 'Harmonic') 
                        AND deals.stage IN ('Top of Funnel')
                        AND deals.kkc_lead = 'Kyber Drive'
                        AND deals.stage NOT IN ('Passed')
                    `,
                    fields: `
                        deals.id, 
                        deals.company_name, 
                        deals.founder_ids,
                        deals.date_created,
                        company.website AS company_website,
                        company.linkedin AS company_linkedin,
                        company.notes AS company_notes,
                        JSON_ARRAYAGG(
                            JSON_OBJECT(
                                'id', people.id,
                                'first_name', people.first_name,
                                'last_name', people.last_name,
                                'linkedin', people.linkedin,
                                'last_contacted', people.last_contacted,
                                'company_linkedin', people.company_linkedin,
                                'company_website', people.company_website,
                                'title', people.title,
                                'poc', people.poc,
                                'twitter', people.twitter,
                                'email', people.email,
                                'location', people.location,
                                'harmonic_url', people.harmonic_url,
                                'outreach_message', people.outreach_message,
                                'notes', IFNULL(people.notes, 'null'),
                                'date_created', people.date_created,
                                'last_updated', people.last_updated,
                                'messages_id', people.messages_id,
                                'outreach_sequence_tags', IFNULL(people.outreach_sequence_tags, 'null'),
                                'outreach_sequence_tag_times', IFNULL(people.outreach_sequence_tag_times, 'null')
                            )
                        ) AS founders_data
                    `,
                    join: `
                        LEFT JOIN company ON deals.company_id = company.id
                        LEFT JOIN JSON_TABLE(
                            deals.founder_ids,
                            '$[*]' COLUMNS (founder_id INT PATH '$')
                        ) AS ft ON 1=1
                        LEFT JOIN people ON ft.founder_id = people.id
                    `,
                    group_by: 'deals.id'
                }
            });

            if (response.data && response.data.deals) {
                const updatedDeals = response.data.deals.map(deal => {
                    console.log('Raw founders_data:', deal.founders_data);
                    let founders;
                    try {
                        founders = JSON.parse(deal.founders_data).map(founder => ({
                            ...founder,
                            notes: founder.notes === 'null' ? null : JSON.parse(founder.notes),
                            outreach_sequence_tags: founder.outreach_sequence_tags === 'null' ? [] : JSON.parse(founder.outreach_sequence_tags),
                            outreach_sequence_tag_times: founder.outreach_sequence_tag_times === 'null' ? [] : JSON.parse(founder.outreach_sequence_tag_times)
                        }));
                    } catch (error) {
                        console.error('Error parsing founders_data:', error);
                        founders = [];
                    }
                    console.log('Processed founders:', founders);

                    const companyNotes = parseInterestedFundNotes(deal.company_notes ? [deal.company_notes] : []);
                    console.log(`Company notes for ${deal.company_name}:`, companyNotes);

                    const founderNotes = founders.flatMap(founder => {
                        const notes = parseInterestedFundNotes(founder.notes || []);
                        console.log(`Notes for founder ${founder.first_name} ${founder.last_name}:`, notes);
                        return notes;
                    });

                    const allInterestedNotes = [...companyNotes, ...founderNotes];
                    console.log(`All interested notes for ${deal.company_name}:`, allInterestedNotes);

                    return {
                        ...deal,
                        founders,
                        interested_fund_notes: allInterestedNotes
                    };
                });

                setDeals(prevDeals => {
                    const newDeals = sortAndGroupDeals(updatedDeals);

                    return newDeals.map(newDeal => {
                        const oldDeal = prevDeals.find(d => d.id === newDeal.id);
                        if (oldDeal) {
                            return {
                                ...newDeal,
                                isExpanded: oldDeal.isExpanded,
                                selectedFounderId: oldDeal.selectedFounderId
                            };
                        }
                        return newDeal;
                    });
                });
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error during sneaky refresh:', error);
        }
    }, []);

    const toggleDealExpansion = (dealId) => {
        setExpandedDealId(prevId => prevId === dealId ? null : dealId);
    };

    const toggleGroupExpansion = (status) => {
        setExpandedGroups(prev => ({
            ...prev,
            [status]: !prev[status]
        }));
    };

    const handleStageUpdate = async (dealId, newStage) => {
        try {
            let kkcLead = '';
            if (newStage === 'Top of Funnel') {
                switch (user?.email) {
                    case 'sal@kyberknight.com':
                        kkcLead = 'SS';
                        break;
                    case 'ary@kyberknight.com':
                        kkcLead = 'AV';
                        break;
                    case 'linus@kyberknight.com':
                        kkcLead = 'LL';
                        break;
                    case 'sunny@kyberknight.com':
                        kkcLead = 'SD';
                        break;
                    default:
                        console.warn('Unknown user, KKC lead not set');
                        console.log('User email:', user?.email);
                }
            }

            setDeals(prevDeals =>
                prevDeals.map(deal =>
                    deal.id === dealId
                        ? { ...deal, stage: newStage, kkc_lead: kkcLead }
                        : deal
                )
            );

            const response = await axios.put(`${baseUrl}/api/deals/${dealId}`, {
                stage: newStage,
                kkc_lead: kkcLead
            });

            if (response.status === 200) {
                setDeals(prevDeals =>
                    prevDeals.filter(deal => deal.id !== dealId)
                );
                await sneakyRefresh();
            } else {
                console.error('Failed to update deal stage');
                setDeals(prevDeals =>
                    prevDeals.map(deal =>
                        deal.id === dealId
                            ? { ...deal, stage: deal.stage, kkc_lead: deal.kkc_lead }
                            : deal
                    )
                );
            }
        } catch (error) {
            console.error('Error updating deal stage:', error);
            setDeals(prevDeals =>
                prevDeals.map(deal =>
                    deal.id === dealId
                        ? { ...deal, stage: deal.stage, kkc_lead: deal.kkc_lead }
                        : deal
                )
            );
        }
    };

    const handleOutreachTagUpdate = async (dealId, founderId, newTag) => {
        console.log('Updating outreach tag:', { dealId, founderId, newTag });
        setDeals(prevDeals => {
            const newDeals = prevDeals.map(deal =>
                deal.id === dealId
                    ? {
                        ...deal,
                        founders: deal.founders.map(founder =>
                            founder.id === founderId
                                ? {
                                    ...founder,
                                    outreach_sequence_tags: [...(founder.outreach_sequence_tags || []), newTag],
                                    outreach_sequence_tag_times: [...(founder.outreach_sequence_tag_times || []), new Date().toISOString()]
                                }
                                : founder
                        )
                    }
                    : deal
            );
            console.log('Updated deals:', newDeals);
            return newDeals;
        });

        await sneakyRefresh();
        console.log('Sneaky refresh completed');
    };

    const parseInterestedFundNotes = (notes) => {
        if (!notes || !Array.isArray(notes) || notes.length === 0) return [];

        const regex = /([^,.]+(?:is|are) interested in [^,.]+)/gi;

        return notes.flatMap(note => {
            if (typeof note !== 'string') return [];
            const matches = note.match(regex) || [];
            return matches.map(match => match.trim());
        });
    };

    const handleColumnResize = (column, event) => {
        const startX = event.clientX;
        const startWidth = columnWidths[column];

        const handleMouseMove = (moveEvent) => {
            const dx = moveEvent.clientX - startX;
            const newWidth = Math.max(10, startWidth + dx / 10);
            setColumnWidths(prev => ({ ...prev, [column]: newWidth }));
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const renderDealGroups = () => {
        const groupedDeals = deals.reduce((acc, deal) => {
            const status = getDealStatus(deal);
            acc[status] = acc[status] || [];
            acc[status].push(deal);
            return acc;
        }, {});

        return Object.entries(groupedDeals).map(([status, deals]) => (
            <React.Fragment key={status}>
                <StatusGroupHeader
                    status={status}
                    count={deals.length}
                    isExpanded={expandedGroups[status]}
                    onToggle={() => toggleGroupExpansion(status)}
                />
                {expandedGroups[status] && deals.map(deal => (
                    <DealRow
                        key={deal.id}
                        deal={deal}
                        isExpanded={expandedDealId === deal.id.toString()}
                        onToggle={() => toggleDealExpansion(deal.id.toString())}
                        onStageUpdate={handleStageUpdate}
                        onOutreachTagUpdate={handleOutreachTagUpdate}
                        onSneakyRefresh={sneakyRefresh}
                    />
                ))}
            </React.Fragment>
        ));
    };

    return (
        <div className="outbound-engine">
            <h1 className="outbound-engine-title">
                <span className="title-text">Kyber Knight Outbound Engine</span>
                <div className="title-underline"></div>
            </h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="table-container">
                    <table className="deals-table">
                        <thead>
                            <tr>
                                <th
                                    style={{ width: `${columnWidths.company}%` }}
                                    onMouseDown={(e) => handleColumnResize('company', e)}
                                >
                                    Company
                                </th>
                                <th
                                    style={{ width: `${columnWidths.status}%` }}
                                    onMouseDown={(e) => handleColumnResize('status', e)}
                                >
                                    Status
                                </th>
                                <th
                                    style={{ width: `${columnWidths.date}%` }}
                                    onMouseDown={(e) => handleColumnResize('date', e)}
                                >
                                    Date Created
                                </th>
                                <th
                                    style={{ width: `${columnWidths.actions}%` }}
                                    onMouseDown={(e) => handleColumnResize('actions', e)}
                                >
                                    Actions
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div className="table-body-container">
                        <table className="deals-table">
                            <tbody>
                                {renderDealGroups()}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OutboundEngine;
