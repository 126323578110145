import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './assets/images/kkc-logo.png'; // Adjust the path as needed
import SignOutButton from './SignOutButton';
import './LeftPanel.css';

function LeftPanel({ isVisible, setIsVisible }) {
    const location = useLocation();
    const timeoutRef = useRef(null);

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsVisible(false);
        }, 300); // Delay hiding by 300ms
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isVisible && e.clientX <= 10) {
                setIsVisible(true);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isVisible, setIsVisible]);

    return (
        <div
            className={`left-panel ${isVisible ? 'visible' : 'hidden'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                transition: 'transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)',
                transform: isVisible ? 'translateX(0)' : 'translateX(-100%)'
            }}
        >
            <div className="logo-container">
                <img src={logo} alt="Kyber Knight Logo" className="logo" />
            </div>
            <nav>
                <h2 className="panel-header">Explore</h2>
                <ul>
                    <li className={location.pathname === '/people' ? 'active' : ''}>
                        <Link to="/people">People</Link>
                    </li>
                    <li className={location.pathname === '/companies' ? 'active' : ''}>
                        <Link to="/companies">Companies</Link>
                    </li>
                    <li className={location.pathname === '/deals' ? 'active' : ''}>
                        <Link to="/deals">Deals</Link>
                    </li>
                </ul>
                <h2 className="panel-header">Pipeline</h2>
                <ul>
                    <li className={location.pathname === '/outbound-engine' ? 'active' : ''}>
                        <Link to="/outbound-engine">Outbound Engine</Link>
                    </li>
                </ul>
            </nav>
            <SignOutButton />
        </div>
    );
}

export default LeftPanel;