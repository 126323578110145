import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './App.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import './ColumnSelector.css';
import { FaSearch, FaColumns, FaObjectGroup, FaCaretDown, FaGlobe, FaLinkedin, FaBuilding, FaMapMarkerAlt, FaFileAlt, FaEnvelope, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './AddCompanyModal.css';
import debounce from 'lodash/debounce';
import FounderPanel from './FounderPanel'; // Import the FounderPanel component
import FilePanel from './FilePanel'; // Import the FilePanel component
import FileManager from './FileManager'; // Import the FileManager component
import { getBaseUrl } from './ApiContext';
import LoadingIndicator from './LoadingIndicator'; // Import the LoadingIndicator component


const baseUrl = getBaseUrl();

Modal.setAppElement('#root');

const ALL_COLUMNS = ['id', 'name', 'website', 'linkedin', 'location', 'description', 'date_created', 'email', 'founders', 'notes', 'files'];
const DEFAULT_COLUMNS = ['name', 'website', 'linkedin', 'founders', 'files', 'notes', 'description'];

function ColumnSelector({ isOpen, onClose, columns, selectedColumns, onApply }) {
    const [search, setSearch] = useState('');
    const [visibleColumns, setVisibleColumns] = useState(selectedColumns);

    const filteredColumns = columns.filter(col =>
        col.toLowerCase().replace(/_/g, ' ').includes(search.toLowerCase())
    );

    const handleToggle = (column) => {
        setVisibleColumns(prev =>
            prev.includes(column) ? prev.filter(c => c !== column) : [...prev, column]
        );
    };

    const moveColumn = (index, direction) => {
        const newVisibleColumns = [...visibleColumns];
        const column = newVisibleColumns[index];
        newVisibleColumns.splice(index, 1);
        newVisibleColumns.splice(index + direction, 0, column);
        setVisibleColumns(newVisibleColumns);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="column-selector-modal">
            <h2>Edit columns</h2>
            <input
                type="text"
                placeholder="Search columns..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="search-input"
            />
            <div className="columns-container">
                <div className="all-columns">
                    <h3>All Columns</h3>
                    {filteredColumns.map(column => (
                        <label key={column} className="column-item">
                            <input
                                type="checkbox"
                                checked={visibleColumns.includes(column)}
                                onChange={() => handleToggle(column)}
                            />
                            {column.replace(/_/g, ' ')}
                        </label>
                    ))}
                </div>
                <div className="visible-columns">
                    <h3>Visible Columns</h3>
                    {visibleColumns.map((column, index) => (
                        <div key={column} className="selected-column">
                            {column.replace(/_/g, ' ')}
                            <div className="column-controls">
                                <button onClick={() => moveColumn(index, -1)} disabled={index === 0}>↑</button>
                                <button onClick={() => moveColumn(index, 1)} disabled={index === visibleColumns.length - 1}>↓</button>
                                <button onClick={() => handleToggle(column)}>×</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="action-buttons">
                <button className="clear-button" onClick={() => setVisibleColumns([])}>Clear</button>
                <button className="cancel-button" onClick={onClose}>Cancel</button>
                <button className="apply-button" onClick={() => onApply(visibleColumns)}>Apply</button>
            </div>
        </Modal>
    );
}

function AddCompanyModal({ isOpen, onClose, onAdd }) {
    const [formData, setFormData] = useState({
        website: '',
        linkedin: '',
        name: '',
        location: '',
        description: '',
        email: '',
        founders: [],
        createDeal: false
    });
    const [error, setError] = useState('');
    const [allPeople, setAllPeople] = useState([]);
    const [founderOptions, setFounderOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchAllPeople();
        }
    }, [isOpen]);

    const fetchAllPeople = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people`, {
                params: { fetch_all: true }
            });
            console.log('API response:', response.data);
            const people = Array.isArray(response.data.people) ? response.data.people : [];
            setAllPeople(people);
            console.log('All people set:', people);
            setFounderOptions(people.map(person => ({
                value: person.id,
                label: formatPersonLabel(person),
                email: person.email || '',
                linkedin: person.linkedin || '',
                location: person.location || '',
                title: person.title || ''
            })));
        } catch (error) {
            console.error('Error fetching people:', error);
            setError('Failed to load people data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatPersonLabel = (person) => {
        const name = `${person.first_name || ''} ${person.last_name || ''}`.trim();
        const email = person.email ? `(${person.email})` : '';
        const location = person.location ? `[${person.location}]` : '';
        const title = person.title ? `- ${truncateTitle(person.title)}` : '';
        return `${name} ${email} ${location} ${title}`.trim();
    };

    const truncateTitle = (title) => {
        if (title.length <= 20) return title;
        return title.substring(0, 20) + '...';
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFounderSelect = (selectedOptions) => {
        setFormData(prev => ({
            ...prev,
            founders: selectedOptions || []
        }));
    };

    const searchPeople = (inputValue) => {
        console.log('Search input:', inputValue);
        if (inputValue.length < 2) {
            setFounderOptions([]);
            return;
        }
        console.log('All people:', allPeople);
        const filteredOptions = allPeople.filter(person => {
            const fullName = `${person.first_name || ''} ${person.last_name || ''}`.toLowerCase();
            const searchTerm = inputValue.toLowerCase();
            const isMatch = fullName.includes(searchTerm) ||
                (person.email && person.email.toLowerCase().includes(searchTerm)) ||
                (person.first_name && person.first_name.toLowerCase().includes(searchTerm)) ||
                (person.last_name && person.last_name.toLowerCase().includes(searchTerm)) ||
                (person.location && person.location.toLowerCase().includes(searchTerm)) ||
                (person.title && person.title.toLowerCase().includes(searchTerm));
            console.log(`Checking ${fullName}: ${isMatch}`);
            return isMatch;
        }).map(person => ({
            value: person.id,
            label: formatPersonLabel(person),
            email: person.email || '',
            linkedin: person.linkedin || '',
            location: person.location || '',
            title: person.title || ''
        }));
        console.log('Filtered options:', filteredOptions);
        setFounderOptions(filteredOptions);
    };

    useEffect(() => {
        console.log('Founder options updated:', founderOptions);
    }, [founderOptions]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.website && !formData.linkedin) {
            setError('Please enter either a website or LinkedIn URL.');
            return;
        }

        // Extract founder emails and LinkedIn profiles
        const founder_email_list = formData.founders.map(founder => founder.email);
        const founder_linkedin_list = formData.founders.map(founder => founder.linkedin);

        const newCompany = {
            ...formData,
            founder_email_list,
            founder_linkedin_list,
            founders: undefined // Remove the founders field as it's not needed in the backend
        };

        // Log the selected founder details
        console.log('Selected founder emails:', founder_email_list);
        console.log('Selected founder LinkedIn profiles:', founder_linkedin_list);

        // Log the data being sent to the backend
        console.log('Submitting new company:', newCompany);

        try {
            const response = await axios.post(`${baseUrl}/api/companies`, newCompany);
            const addedCompany = response.data.company;
            if (addedCompany) {
                onAdd(addedCompany);
                if (formData.createDeal) {
                    await handleCreateDeal(addedCompany);
                }
                // Add founders to the company
                if (formData.founders.length > 0) {
                    await addFoundersToCompany(addedCompany.id, formData.founders);
                }
                onClose();
                // Reset form state after successful submission
                setFormData({
                    website: '',
                    linkedin: '',
                    name: '',
                    location: '',
                    description: '',
                    email: '',
                    founders: [],
                    createDeal: false
                });
            } else {
                setError('Failed to add company. Please try again.');
            }
        } catch (error) {
            console.error('Error adding company:', error);
            setError('Failed to add company. Please try again.');
        }
    };

    const handleCreateDeal = async (company) => {
        try {
            const response = await axios.post(`${baseUrl}/api/companies/${company.id}/create_deal`, {
                founder_ids: formData.founders.map(founder => founder.value)
            });
            console.log('Deal created:', response.data);
        } catch (error) {
            console.error('Error creating deal:', error);
            setError('Failed to create deal. Please try again.');
        }
    };

    const addFoundersToCompany = async (companyId, founders) => {
        try {
            for (const founder of founders) {
                const founderData = {
                    first_name: founder.first_name,
                    last_name: founder.last_name,
                    linkedin: founder.linkedin,
                    email: founder.email,
                    location: founder.location,
                    title: founder.title
                };
                await axios.post(`${baseUrl}/api/companies/${companyId}/add_person`, founderData);
                console.log(`Founder ${founder.first_name} ${founder.last_name} added to company successfully`);
            }
        } catch (error) {
            console.error('Error adding founders to company:', error);
            setError('Failed to add founders to company. Please try again.');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="add-company-modal"
            overlayClassName="add-company-modal-overlay"
            contentLabel="Add New Company"
        >
            <h2>Add New Company</h2>
            {isLoading ? (
                <LoadingIndicator message="Loading people data..." />
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <FaGlobe className="input-icon" />
                        <input
                            type="text"
                            name="website"
                            placeholder="Website"
                            value={formData.website}
                            onChange={handleInputChange}
                            style={{ borderColor: '#625896' }}
                        />
                    </div>
                    <div className="input-group">
                        <FaLinkedin className="input-icon" />
                        <input
                            type="text"
                            name="linkedin"
                            placeholder="LinkedIn URL"
                            value={formData.linkedin}
                            onChange={handleInputChange}
                            style={{ borderColor: '#625896' }}
                        />
                    </div>
                    <div className="input-group">
                        <FaBuilding className="input-icon" />
                        <input
                            type="text"
                            name="name"
                            placeholder="Company Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            style={{ borderColor: '#625896' }}
                        />
                    </div>
                    <div className="input-group">
                        <FaMapMarkerAlt className="input-icon" />
                        <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            value={formData.location}
                            onChange={handleInputChange}
                            style={{ borderColor: '#625896' }}
                        />
                    </div>
                    <div className="input-group">
                        <FaFileAlt className="input-icon" />
                        <textarea
                            name="description"
                            placeholder="Description"
                            value={formData.description}
                            onChange={handleInputChange}
                            style={{ borderColor: '#625896' }}
                        />
                    </div>
                    <div className="input-group">
                        <FaEnvelope className="input-icon" />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            style={{ borderColor: '#625896' }}
                        />
                    </div>
                    <div className="input-group">
                        <FaUser className="input-icon" />
                        <Select
                            isMulti
                            name="founders"
                            options={founderOptions}
                            value={formData.founders}
                            onChange={handleFounderSelect}
                            onInputChange={(value) => {
                                console.log('Input changed:', value);
                                searchPeople(value);
                            }}
                            placeholder="Search and select founders"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isLoading={isLoading}
                            loadingMessage={() => "Loading..."}
                            noOptionsMessage={({ inputValue }) => inputValue.length < 2 ? "Type to search" : "No options"}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: '45px',
                                    backgroundColor: '#1a1433',
                                    border: '1px solid #625896',
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1px solid #625896',
                                    },
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    padding: '0 0 0 40px',
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                    margin: '0',
                                    padding: '0',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#a0a0a0',
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                }),
                                multiValue: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#625896',
                                }),
                                multiValueLabel: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                }),
                                multiValueRemove: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                    ':hover': {
                                        backgroundColor: '#7a6eb8',
                                        color: 'white',
                                    },
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#1a1433',
                                    border: '1px solid #7a6eb8',
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused ? '#625896' : '#1a1433',
                                    color: 'white',
                                }),
                            }}
                        />
                    </div>
                    <div className="create-deal-container">
                        <div className="create-deal-group">
                            <input
                                type="checkbox"
                                name="createDeal"
                                checked={formData.createDeal}
                                onChange={handleInputChange}
                                className="create-deal-checkbox"
                            />
                            <label className="create-deal-label">Create Deal</label>
                        </div>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-btn" style={{ backgroundColor: '#080125', color: 'white' }}>Cancel</button>
                        <button type="submit" className="add-btn" disabled={isLoading} style={{ backgroundColor: '#625896', color: 'white' }}>Add Company</button>
                    </div>
                </form>
            )}
        </Modal>
    );
}

function Companies() {
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [groupBy, setGroupBy] = useState('');
    const [columnWidths, setColumnWidths] = useState({});
    const [editingCell, setEditingCell] = useState({ id: null, field: null });
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedCell, setSelectedCell] = useState({ id: null, field: null });
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchResults, setSearchResults] = useState({ fields: 0, cells: 0, records: 0 });
    const [columns, setColumns] = useState([]);
    const [sortColumn, setSortColumn] = useState('last_updated');
    const [sortDirection, setSortDirection] = useState('desc');
    const [availableColumns, setAvailableColumns] = useState(ALL_COLUMNS);
    const [selectedColumns, setSelectedColumns] = useState(DEFAULT_COLUMNS);
    const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
    const [isGroupingOpen, setIsGroupingOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(0);
    const [initialSort, setInitialSort] = useState(true);
    const [editValue, setEditValue] = useState('');
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [isNotePanelOpen, setIsNotePanelOpen] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [currentCompanyId, setCurrentCompanyId] = useState(null);
    const notePanelRef = useRef(null);
    const navigate = useNavigate();
    const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
    const [existingPeople, setExistingPeople] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const contextMenuRef = useRef(null);
    const [selectedFounders, setSelectedFounders] = useState([]);
    const [isFounderPanelOpen, setIsFounderPanelOpen] = useState(false);
    const founderPanelRef = useRef(null);
    const [isFilePanelOpen, setIsFilePanelOpen] = useState(false); // State for file panel
    const filePanelRef = useRef(null); // Ref for file panel
    const [isLoading, setIsLoading] = useState(true); // Add this line
    const [isAddingCompany, setIsAddingCompany] = useState(false); // New state for adding company

    useEffect(() => {
        function handleClickOutside(event) {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                handleCloseContextMenu();
            }
            if (notePanelRef.current && !notePanelRef.current.contains(event.target)) {
                setIsNotePanelOpen(false);
            }
            if (founderPanelRef.current && !founderPanelRef.current.contains(event.target)) {
                setIsFounderPanelOpen(false);
            }
            if (filePanelRef.current && !filePanelRef.current.contains(event.target)) {
                setIsFilePanelOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contextMenuRef, notePanelRef, founderPanelRef, filePanelRef]);

    const handleContextMenu = (event, companyId) => {
        event.preventDefault();
        console.log('Context menu triggered for company ID:', companyId);
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        setSelectedCompanyId(companyId);
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleDeleteCompany = async () => {
        console.log('handleDeleteCompany function called');
        console.log('handleDeleteCompany called with selectedCompanyId:', selectedCompanyId);
        if (selectedCompanyId) {
            try {
                await axios.delete(`${baseUrl}/api/companies/${selectedCompanyId}`);
                console.log('Company deleted successfully');
                setData(prevData => prevData.filter(company => company.id !== selectedCompanyId));
                setFilteredData(prevData => prevData.filter(company => company.id !== selectedCompanyId));
                handleCloseContextMenu();
            } catch (error) {
                console.error('Error deleting company:', error);
            }
        } else {
            console.error('No company selected for deletion');
        }
    };

    const handleMouseEnter = (id) => {
        setHoveredRow(id);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const handleClick = (id, field) => {
        setSelectedCell({ id, field });
    };

    const handleDoubleClick = (id, field, value) => {
        if (field !== 'id' && field !== 'notes') {
            setEditingCell({ id, field });
            setEditValue(value || '');
        }
    };

    const handleKeyDown = (e, id, field) => {
        if (e.key === 'Enter') {
            handleBlur(id, field, editValue);
        } else if (e.key === 'Escape') {
            setEditingCell({ id: null, field: null });
        }
    };

    const handleBlur = async (id, field, value) => {
        setEditingCell({ id: null, field: null });

        const now = new Date().toISOString();

        setData(prevData =>
            prevData.map(company =>
                company.id === id ? { ...company, [field]: value, last_updated: now } : company
            )
        );
        setFilteredData(prevData =>
            prevData.map(company =>
                company.id === id ? { ...company, [field]: value, last_updated: now } : company
            )
        );

        try {
            await axios.put(`${baseUrl}/api/companies/${id}`, {
                [field]: value,
                last_updated: now,
            });
            console.log('Data updated successfully');
        } catch (error) {
            console.error('Error updating data:', error);
            setData(prevData =>
                prevData.map(company =>
                    company.id === id ? { ...company, [field]: company[field], last_updated: company.last_updated } : company
                )
            );
            setFilteredData(prevData =>
                prevData.map(company =>
                    company.id === id ? { ...company, [field]: company[field], last_updated: company.last_updated } : company
                )
            );
        }
    };

    const highlightText = useCallback((text) => {
        if (!searchTerm || text == null) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text.toString().split(regex);
        if (parts.length > 100) {
            return text;
        }
        return (
            <span>
                {parts.map((part, index) =>
                    regex.test(part) ? (
                        <span key={index} className="highlight">{part}</span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    }, [searchTerm]);

    const renderCellContent = (column, value, companyId) => {
        if (['linkedin', 'company_linkedin', 'company_website'].includes(column)) {
            return value ? (
                <a href={value.startsWith('http') ? value : `https://${value}`} target="_blank" rel="noopener noreferrer">
                    {highlightText(value)}
                </a>
            ) : null;
        } else if (column === 'notes') {
            return (
                <button onClick={() => handleViewNotes(value, companyId)}>
                    View Notes
                </button>
            );
        } else if (column === 'files') {
            return (
                <button onClick={() => handleViewFiles(companyId)}>
                    View Files
                </button>
            );
        } else if (column === 'founders') {
            return (
                <button onClick={() => handleViewFounders(companyId)}>
                    View Founders
                </button>
            );
        }
        return highlightText(value);
    };

    const renderTableHeader = () => (
        <thead className={isColumnSelectorOpen ? 'greyed-out-header' : ''}>
            <tr>
                <th className="checkbox-column" style={{ width: '15px', minWidth: '15px', maxWidth: '15px', textAlign: 'center' }}>
                    {/* Empty header cell for the checkbox column */}
                </th>
                {selectedColumns.map((column) => (
                    <th
                        key={column}
                        className="table-cell"
                        style={{ width: columnWidths[column] || 150, cursor: 'pointer' }}
                        onClick={() => handleSort(column)}
                    >
                        {column.replace(/_/g, ' ').toUpperCase()}
                        {sortColumn === column && (
                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );

    const renderTableBody = (dataToRender) => (
        <tbody>
            {dataToRender.map((company, index) => (
                <tr
                    key={`${company.id || company.name || 'company'}-${index}`}
                    onMouseEnter={() => handleMouseEnter(company.id)}
                    onMouseLeave={handleMouseLeave}
                    className={hoveredRow === company.id ? 'hovered-row' : ''}
                    onContextMenu={(event) => handleContextMenu(event, company.id)}
                >
                    <td className="table-cell checkbox-column">
                        <input
                            type="checkbox"
                            checked={selectedCompanies.includes(company.id)}
                            onChange={() => handleSelectCompany(company.id)}
                        />
                    </td>
                    {selectedColumns.map((column) => (
                        <td
                            key={column}
                            className={`table-cell ${selectedCell.id === company.id && selectedCell.field === column ? 'selected-cell' : ''}`}
                            style={{ width: columnWidths[column] || 150 }}
                            onClick={() => handleClick(company.id, column)}
                            onDoubleClick={() => handleDoubleClick(company.id, column, company[column])}
                        >
                            {editingCell.id === company.id && editingCell.field === column ? (
                                <input
                                    type="text"
                                    value={editValue}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur(company.id, column, editValue)}
                                    onKeyDown={(e) => handleKeyDown(e, company.id, column)}
                                    autoFocus
                                />
                            ) : (
                                renderCellContent(column, company[column], company.id)
                            )}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );

    const handleSelectCompany = (companyId) => {
        setSelectedCompanies(prev =>
            prev.includes(companyId)
                ? prev.filter(id => id !== companyId)
                : [...prev, companyId]
        );
    };

    const sortData = useCallback((data, column, direction) => {
        return [...data].sort((a, b) => {
            if (column === 'last_updated') {
                const dateA = a[column] ? new Date(a[column]) : new Date(0);
                const dateB = b[column] ? new Date(b[column]) : new Date(0);
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            }
            if (a[column] == null) return 1;
            if (b[column] == null) return -1;
            if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, []);

    const handleSort = (column) => {
        const newDirection = column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
        const sortedData = sortData(data, column, newDirection);
        setData(sortedData);
    };

    const fetchAllData = async () => {
        setIsLoading(true);
        setIsLoadingAll(true);
        try {
            const response = await axios.get(`${baseUrl}/api/companies`, {
                params: { fetch_all: true, search: searchTerm }
            });
            if (response.data && response.data.companies) {
                let fetchedData = response.data.companies;

                if (fetchedData.length > 0) {
                    const dataColumns = Object.keys(fetchedData[0]);
                    setAvailableColumns(dataColumns);
                    setSelectedColumns(prevSelected => {
                        const newSelected = prevSelected.filter(col => dataColumns.includes(col));
                        if (!newSelected.includes('files')) {
                            newSelected.push('files'); // Ensure 'files' column is included
                        }
                        if (!newSelected.includes('founders')) {
                            newSelected.push('founders'); // Ensure 'founders' column is included
                        }
                        // Ensure founders is to the left of notes
                        const orderedColumns = DEFAULT_COLUMNS.filter(col => newSelected.includes(col));
                        return orderedColumns.length > 0 ? orderedColumns : dataColumns.slice(0, 5);
                    });
                }

                // Always sort by last_updated in descending order
                fetchedData = sortData(fetchedData, 'last_updated', 'desc');
                setSortColumn('last_updated');
                setSortDirection('desc');

                setData(fetchedData);
                setFilteredData(fetchedData);
                filterAndHighlightData(fetchedData);

                setColumnWidths(prevWidths => {
                    const newWidths = { ...prevWidths };
                    availableColumns.forEach(column => {
                        if (!newWidths[column]) {
                            newWidths[column] = 150;
                        }
                    });
                    return newWidths;
                });
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching all data:', error);
        }
        setIsLoadingAll(false);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAllData();
    }, [searchTerm, sortColumn, sortDirection, initialSort]);

    const filterAndHighlightData = (dataToFilter) => {
        const searchTerms = searchTerm.toLowerCase().split(' ');
        const results = dataToFilter.filter(item =>
            searchTerms.every(term =>
                Object.values(item).some(value =>
                    value != null && value.toString().toLowerCase().includes(term)
                )
            )
        );

        setFilteredData(results);

        let fields = 0;
        let cells = 0;
        let records = 0;

        results.forEach(item => {
            let recordMatched = false;
            Object.entries(item).forEach(([key, value]) => {
                if (value != null && value.toString().toLowerCase().includes(searchTerm)) {
                    cells++;
                    fields++;
                    recordMatched = true;
                }
            });
            if (recordMatched) {
                records++;
            }
        });

        setSearchResults({ fields, cells, records });
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        setCurrentPage(1);

        const filtered = data.filter(item =>
            Object.values(item).some(value =>
                value != null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );

        setFilteredData(filtered);
    };

    const handleGroupByChange = (e) => {
        setGroupBy(e.target.value);
    };

    const handleResize = (column, width) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [column]: width,
        }));
    };

    const groupData = (data, groupBy) => {
        if (!groupBy) return data;
        return data.reduce((acc, item) => {
            const key = item[groupBy] || 'Unknown';
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});
    };

    const toggleGroup = (group) => {
        setCollapsedGroups(prev => ({
            ...prev,
            [group]: !prev[group]
        }));
    };

    const renderGroupedData = () => {
        if (!groupBy) {
            return renderTableBody(filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage));
        }

        const groupedData = groupData(filteredData, groupBy);

        return Object.entries(groupedData).map(([group, groupData]) => (
            <React.Fragment key={group}>
                <tr
                    className="group-header"
                    onClick={() => toggleGroup(group)}
                >
                    <td colSpan={selectedColumns.length}>
                        {collapsedGroups[group] ? '▶' : '▼'} {group} ({groupData.length})
                    </td>
                </tr>
                {!collapsedGroups[group] && renderTableBody(groupData)}
            </React.Fragment>
        ));
    };

    const handleApplyColumns = (newColumns) => {
        setSelectedColumns(newColumns);
        setIsColumnSelectorOpen(false);
        // Reapply sorting
        const sortedData = sortData(data, sortColumn, sortDirection);
        setData(sortedData);
        setFilteredData(sortedData);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleInputChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleViewNotes = (notes, companyId) => {
        try {
            const parsedNotes = notes ? JSON.parse(notes) : [];
            setSelectedNotes(Array.isArray(parsedNotes) ? parsedNotes.map((note, index) => ({ id: index + 1, content: note })) : []);
            setIsNotePanelOpen(true);
            setCurrentCompanyId(companyId);
        } catch (error) {
            console.error('Error parsing notes:', error);
            setSelectedNotes(notes ? [{ id: 1, content: notes }] : []);
            setIsNotePanelOpen(true);
            setCurrentCompanyId(companyId);
        }
    };

    const handleAddNote = () => {
        const newNoteId = Date.now();
        const newNote = { id: newNoteId, content: '' };
        setSelectedNotes(prevNotes => [...prevNotes, newNote]);
        setEditingNoteId(newNoteId);
    };

    const handleNoteChange = (id, content) => {
        setSelectedNotes(prevNotes =>
            prevNotes.map(note => note.id === id ? { ...note, content } : note)
        );
    };

    const handleNoteBlur = async () => {
        setEditingNoteId(null);
        if (currentCompanyId) {
            try {
                await saveNotesToDatabase(currentCompanyId, selectedNotes);
                console.log('Notes updated successfully');
            } catch (error) {
                console.error('Error saving notes:', error);
            }
        }
    };

    const saveNotesToDatabase = async (companyId, notes) => {
        try {
            await axios.put(`${baseUrl}/api/companies/${companyId}`, {
                notes: JSON.stringify(notes.map(note => note.content))
            });
            setData(prevData =>
                prevData.map(company =>
                    company.id === companyId ? { ...company, notes: JSON.stringify(notes.map(note => note.content)) } : company
                )
            );
            setFilteredData(prevData =>
                prevData.map(company =>
                    company.id === companyId ? { ...company, notes: JSON.stringify(notes.map(note => note.content)) } : company
                )
            );
        } catch (error) {
            throw error;
        }
    };

    const handleNoteDoubleClick = (id) => {
        setEditingNoteId(id);
    };

    const handleDeleteNote = async (noteId) => {
        const updatedNotes = selectedNotes.filter(note => note.id !== noteId);
        setSelectedNotes(updatedNotes);

        if (currentCompanyId) {
            try {
                await saveNotesToDatabase(currentCompanyId, updatedNotes);
                console.log('Note deleted successfully');
            } catch (error) {
                console.error('Error deleting note:', error);
            }
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (notePanelRef.current && !notePanelRef.current.contains(event.target)) {
                setIsNotePanelOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notePanelRef]);

    const handleAddCompany = async (newCompany) => {
        setIsAddingCompany(true);
        try {
            console.log('Submitting new company:', newCompany);
            const formattedCompany = {
                ...newCompany,
                founders: (newCompany.founders || []).map(founder => ({
                    name: `${founder.first_name} ${founder.last_name}`,
                    email: founder.email,
                    linkedin: founder.linkedin
                }))
            };

            const response = await axios.post(`${baseUrl}/api/companies`, formattedCompany);
            console.log('Server response:', response.data);
            const addedCompany = response.data.company;
            if (addedCompany) {
                setData(prevData => [...prevData, addedCompany]);
                setFilteredData(prevData => [...prevData, addedCompany]);
                console.log('Company added successfully:', addedCompany);
                setIsAddCompanyModalOpen(false);
            } else {
                console.error('Server did not return the added company');
            }
        } catch (error) {
            console.error('Error adding company:', error.response ? error.response.data : error.message);
        }
        setIsAddingCompany(false);
    };

    useEffect(() => {
        const fetchExistingPeople = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/people`);
                setExistingPeople(response.data || []); // Ensure it's an array
            } catch (error) {
                console.error('Error fetching existing people:', error);
                setExistingPeople([]); // Set to empty array on error
            }
        };
        fetchExistingPeople();
    }, []);

    const handleDeleteSelected = async () => {
        if (window.confirm(`Are you sure you want to delete ${selectedCompanies.length} selected companies?`)) {
            try {
                for (const companyId of selectedCompanies) {
                    await axios.delete(`${baseUrl}/api/companies/${companyId}`);
                }
                setData(prevData => prevData.filter(company => !selectedCompanies.includes(company.id)));
                setFilteredData(prevData => prevData.filter(company => !selectedCompanies.includes(company.id)));
                setSelectedCompanies([]);
                console.log('Selected companies deleted successfully');
            } catch (error) {
                console.error('Error deleting selected companies:', error);
            }
        }
    };

    const handleViewFounders = async (companyId) => {
        console.log('handleViewFounders called with companyId:', companyId); // Debugging log
        try {
            const response = await axios.get(`${baseUrl}/api/companies/${companyId}/details`);
            console.log('API response:', response.data); // Debugging log
            const companyDetails = response.data;
            setSelectedFounders(companyDetails.founders || []);
            setIsFounderPanelOpen(true);
            setCurrentCompanyId(companyId);
            console.log('Founders set:', companyDetails.founders); // Debugging log
        } catch (error) {
            console.error('Error fetching company details:', error);
        }
    };

    const handleAddFounder = (selectedOptions) => {
        const newFounders = selectedOptions.map(option => {
            if (!option.label) {
                console.error('Option label is undefined:', option); // Debugging log
                const firstName = option.first_name || '';
                const lastName = option.last_name || '';
                return {
                    id: option.value,
                    first_name: firstName,
                    last_name: lastName,
                    email: option.email,
                    linkedin: option.linkedin,
                    location: option.location,
                    title: option.title
                };
            }

            const nameParts = option.label.split(' ');
            const firstName = nameParts[0] || '';
            const lastName = nameParts.slice(1).join(' ') || '';

            return {
                id: option.value,
                first_name: firstName,
                last_name: lastName,
                email: option.email,
                linkedin: option.linkedin,
                location: option.location,
                title: option.title
            };
        }).filter(founder => founder !== null);

        setSelectedFounders(newFounders);
    };

    const handleRemoveFounder = (founderId) => {
        setSelectedFounders(prevFounders => prevFounders.filter(founder => founder.id !== founderId));
    };

    useEffect(() => {
        console.log('isFounderPanelOpen:', isFounderPanelOpen); // Debugging log
        console.log('selectedFounders:', selectedFounders); // Debugging log
    }, [isFounderPanelOpen, selectedFounders]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (founderPanelRef.current && !founderPanelRef.current.contains(event.target)) {
                setIsFounderPanelOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [founderPanelRef]);

    const handleViewFiles = (companyId) => {
        setCurrentCompanyId(companyId);
        setIsFilePanelOpen(true);
    };

    return (
        <div className="companies-container">
            <div style={{ color: 'grey', fontSize: '14px', marginBottom: '5px' }}>
                Explore /
            </div>
            <h1>Companies</h1>
            <div className="toolbar" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 15px',
                borderTop: '1px solid #625896',
                borderBottom: '1px solid #625896',
                marginBottom: '20px',
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ToolbarButton icon={<FaColumns />} text="Edit Columns" onClick={() => setIsColumnSelectorOpen(true)} />
                    <div style={{ position: 'relative', marginLeft: '10px' }}>
                        <ToolbarButton
                            text={groupBy ? groupBy.replace(/_/g, ' ') : "No grouping"}
                            icon={<FaCaretDown />}
                            onClick={() => setIsGroupingOpen(!isGroupingOpen)}
                        />
                        {isGroupingOpen && (
                            <div style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                backgroundColor: '#0f0d2e',
                                border: '1px solid #625896',
                                borderRadius: '4px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                zIndex: 1000,
                            }}>
                                <div
                                    onClick={() => {
                                        handleGroupByChange({ target: { value: '' } });
                                        setIsGroupingOpen(false);
                                    }}
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        hover: { backgroundColor: '#1a1745' }
                                    }}
                                >
                                    No grouping
                                </div>
                                {selectedColumns.map(column => (
                                    <div
                                        key={column}
                                        onClick={() => {
                                            handleGroupByChange({ target: { value: column } });
                                            setIsGroupingOpen(false);
                                        }}
                                        style={{
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            hover: { backgroundColor: '#1a1745' }
                                        }}
                                    >
                                        {column.replace(/_/g, ' ')}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <ToolbarButton
                        text="Add Company"
                        onClick={() => setIsAddCompanyModalOpen(true)}
                        style={{ marginLeft: '10px' }}
                    />
                    {selectedCompanies.length > 0 && (
                        <ToolbarButton
                            text={`Delete Selected (${selectedCompanies.length})`}
                            onClick={handleDeleteSelected}
                            style={{ marginLeft: '10px', backgroundColor: '#ff4d4d', color: 'white' }}
                        />
                    )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        style={{
                            padding: '5px 10px',
                            height: '20px',
                            fontSize: '14px',
                            border: '1px solid #625896',
                            borderRadius: '4px'
                        }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PaginationControls
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        totalRows={filteredData.length}
                        onPageChange={paginate}
                        onRowsPerPageChange={(newRowsPerPage) => {
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(1);
                        }}
                    />
                </div>
            </div>
            <ColumnSelector
                isOpen={isColumnSelectorOpen}
                onClose={() => setIsColumnSelectorOpen(false)}
                columns={availableColumns}
                selectedColumns={selectedColumns}
                onApply={handleApplyColumns}
            />
            {isLoadingAll ? (
                <LoadingIndicator message="Loading companies..." />
            ) : (
                <div className="table-container">
                    <div className="table-scroll">
                        <table>
                            {renderTableHeader()}
                            {renderGroupedData()}
                        </table>
                    </div>
                </div>
            )}
            {isNotePanelOpen && (
                <div className="note-panel" ref={notePanelRef}>
                    <h2>Notes</h2>
                    {selectedNotes.map((note) => (
                        <div key={note.id} className="note-item">
                            <div className="note-content">
                                {editingNoteId === note.id ? (
                                    <textarea
                                        value={note.content}
                                        onChange={(e) => handleNoteChange(note.id, e.target.value)}
                                        onBlur={handleNoteBlur}
                                        autoFocus
                                    />
                                ) : (
                                    <p onDoubleClick={() => handleNoteDoubleClick(note.id)}>
                                        {note.content || 'Double-click to edit'}
                                    </p>
                                )}
                            </div>
                            <button className="delete-note" onClick={() => handleDeleteNote(note.id)}>
                                ×
                            </button>
                        </div>
                    ))}
                    <button onClick={handleAddNote}>Add Note</button>
                </div>
            )}
            <AddCompanyModal
                isOpen={isAddCompanyModalOpen}
                onClose={() => setIsAddCompanyModalOpen(false)}
                onAdd={handleAddCompany}
            />
            {contextMenu !== null && (
                <div
                    ref={contextMenuRef}
                    style={{
                        position: 'fixed',
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX,
                        backgroundColor: '#0f0d2e',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                        borderRadius: '4px',
                        zIndex: 1001,
                        padding: '5px',
                    }}
                >
                    <div
                        style={{
                            padding: '8px 16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log('Delete option clicked');
                            console.log('Delete clicked for company:', selectedCompanyId);
                            console.log('Attempting to call handleDeleteCompany');
                            handleDeleteCompany();
                        }}
                    >
                        Delete Company
                    </div>
                </div>
            )}
            {isFounderPanelOpen && (
                <div className="founder-panel-container" ref={founderPanelRef}>
                    <FounderPanel
                        founders={selectedFounders}
                        onClose={() => setIsFounderPanelOpen(false)}
                        onAddFounder={handleAddFounder}
                        onRemoveFounder={handleRemoveFounder}
                        currentCompanyId={currentCompanyId}
                    />
                </div>
            )}
            {isFilePanelOpen && (
                <div className="file-panel-container" ref={filePanelRef} onClick={(e) => e.stopPropagation()}>
                    <FilePanel
                        id={currentCompanyId}
                        type="companies"
                        onClose={() => setIsFilePanelOpen(false)}
                    />
                </div>
            )}
        </div>
    );
}

const ToolbarButton = ({ icon, text, onClick }) => (
    <button
        onClick={onClick}
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            backgroundColor: '#0f0d2e',
            border: '1px solid #625896',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            color: 'white',
            transition: 'background-color 0.3s'
        }}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
    >
        {icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
        {text}
        {text === "No grouping" && <FaCaretDown style={{ marginLeft: '5px' }} />}
    </button>
);

const PaginationControls = ({ currentPage, rowsPerPage, totalRows, onPageChange, onRowsPerPageChange }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const startRow = (currentPage - 1) * rowsPerPage + 1;
    const endRow = Math.min(currentPage * rowsPerPage, totalRows);

    return (
        <div className="pagination-controls">
            <span>Rows per page:</span>
            <select value={rowsPerPage} onChange={(e) => onRowsPerPageChange(Number(e.target.value))}>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </select>
            <span>{`${startRow} - ${endRow} of ${totalRows} companies`}</span>
            <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
            <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
        </div>
    );
};

export default Companies;