import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './App.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import './ColumnSelector.css';
import { FaSearch, FaColumns, FaObjectGroup, FaCaretDown, FaUser, FaBuilding, FaLinkedin, FaEnvelope, FaMapMarkerAlt, FaBriefcase } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './AddPersonModal.css';
import debounce from 'lodash/debounce';
import FilePanel from './FilePanel'; // Import the FilePanel component
import { getBaseUrl } from './ApiContext';
import LoadingIndicator from './LoadingIndicator';


const baseUrl = getBaseUrl();

const ALL_COLUMNS = ['id', 'first_name', 'last_name', 'company_name', 'linkedin', 'notes', 'email', 'location', 'company_linkedin', 'company_website'];
const DEFAULT_COLUMNS = ['first_name', 'last_name', 'company_name', 'linkedin', 'notes'];

function ColumnSelector({ isOpen, onClose, columns, selectedColumns, onApply }) {
    const [search, setSearch] = useState('');
    const [visibleColumns, setVisibleColumns] = useState(selectedColumns);

    const filteredColumns = columns.filter(col =>
        col.toLowerCase().replace(/_/g, ' ').includes(search.toLowerCase())
    );

    const handleToggle = (column) => {
        setVisibleColumns(prev =>
            prev.includes(column) ? prev.filter(c => c !== column) : [...prev, column]
        );
    };

    const moveColumn = (index, direction) => {
        const newVisibleColumns = [...visibleColumns];
        const column = newVisibleColumns[index];
        newVisibleColumns.splice(index, 1);
        newVisibleColumns.splice(index + direction, 0, column);
        setVisibleColumns(newVisibleColumns);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="column-selector-modal">
            <h2>Edit columns</h2>
            <input
                type="text"
                placeholder="Search columns..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="search-input"
            />
            <div className="columns-container">
                <div className="all-columns">
                    <h3>All Columns</h3>
                    {filteredColumns.map(column => (
                        <label key={column} className="column-item">
                            <input
                                type="checkbox"
                                checked={visibleColumns.includes(column)}
                                onChange={() => handleToggle(column)}
                            />
                            {column.replace(/_/g, ' ')}
                        </label>
                    ))}
                </div>
                <div className="visible-columns">
                    <h3>Visible Columns</h3>
                    {visibleColumns.map((column, index) => (
                        <div key={column} className="selected-column">
                            {column.replace(/_/g, ' ')}
                            <div className="column-controls">
                                <button onClick={() => moveColumn(index, -1)} disabled={index === 0}>↑</button>
                                <button onClick={() => moveColumn(index, 1)} disabled={index === visibleColumns.length - 1}>↓</button>
                                <button onClick={() => handleToggle(column)}>×</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="action-buttons">
                <button className="clear-button" onClick={() => setVisibleColumns([])}>Clear</button>
                <button className="cancel-button" onClick={onClose}>Cancel</button>
                <button className="apply-button" onClick={() => onApply(visibleColumns)}>Apply</button>
            </div>
        </Modal>
    );
}

function AddPersonModal({ isOpen, onClose, onAdd }) {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        linkedin: '',
        email: '',
        location: '',
        title: '',
        company: null,
        createDeal: false,
        notes: '[]' // Initialize notes as '[]'
    });
    const [error, setError] = useState('');
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchCompanies();
        }
    }, [isOpen]);

    const fetchCompanies = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/companies`, {
                params: { fetch_all: true }
            });
            const companyOptions = response.data.companies.map(company => ({
                value: company.id,
                label: company.name
            }));
            setCompanies(companyOptions);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setError('Failed to load companies. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleCompanyChange = (selectedOption) => {
        setFormData(prev => ({ ...prev, company: selectedOption }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.linkedin && !formData.email) {
            setError('Please enter either a LinkedIn URL or an email.');
            return;
        }
        const newPerson = {
            ...formData,
            company_id: formData.company ? formData.company.value : null,
            company_name: formData.company ? formData.company.label : null,
            notes: '[]' // Set notes to '[]' when adding a new person
        };
        onAdd(newPerson);
        onClose();
        setFormData({
            first_name: '',
            last_name: '',
            linkedin: '',
            email: '',
            location: '',
            title: '',
            company: null,
            createDeal: false,
            notes: '[]' // Add this line
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="add-person-modal"
            overlayClassName="add-person-modal-overlay"
            contentLabel="Add New Person"
        >
            <h2>Add New Person</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <FaUser className="input-icon" />
                    <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="input-group">
                    <FaUser className="input-icon" />
                    <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="input-group">
                    <FaLinkedin className="input-icon" />
                    <input
                        type="text"
                        name="linkedin"
                        placeholder="LinkedIn URL"
                        value={formData.linkedin}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-group">
                    <FaEnvelope className="input-icon" />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-group">
                    <FaMapMarkerAlt className="input-icon" />
                    <input
                        type="text"
                        name="location"
                        placeholder="Location"
                        value={formData.location}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-group">
                    <FaBriefcase className="input-icon" />
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="company"
                        value={formData.company}
                        onChange={handleCompanyChange}
                        options={companies}
                        isClearable
                        placeholder="Select Company"
                        isLoading={isLoading}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                paddingLeft: '28px',
                            }),
                        }}
                    />
                </div>
                <div className="input-group create-deal-container">
                    <input
                        type="checkbox"
                        name="createDeal"
                        checked={formData.createDeal}
                        onChange={handleInputChange}
                        className="create-deal-checkbox"
                    />
                    <label htmlFor="createDeal" className="create-deal-label">Create Deal</label>
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="modal-actions">
                    <button type="button" className="cancel-btn" onClick={onClose}>Cancel</button>
                    <button type="submit" className="add-btn">Add Person</button>
                </div>
            </form>
        </Modal>
    );
}

function People() {
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [groupBy, setGroupBy] = useState(''); // State for grouping
    const [columnWidths, setColumnWidths] = useState({
        id: 150,
        first_name: 150,
        last_name: 150,
        linkedin: 150,
        email: 150,
        location: 150,
    });
    const [editingCell, setEditingCell] = useState({ id: null, field: null });
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedCell, setSelectedCell] = useState({ id: null, field: null });
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchResults, setSearchResults] = useState({ fields: 0, cells: 0, records: 0 });
    const [columns, setColumns] = useState([]);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [isNotePanelOpen, setIsNotePanelOpen] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [currentPersonId, setCurrentPersonId] = useState(null);
    const notePanelRef = useRef(null);
    const [availableColumns, setAvailableColumns] = useState(ALL_COLUMNS);
    const [selectedColumns, setSelectedColumns] = useState(DEFAULT_COLUMNS);
    const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
    const [isGroupingOpen, setIsGroupingOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(0);
    const [initialSort, setInitialSort] = useState(true);
    const navigate = useNavigate();
    const [isAddPersonModalOpen, setIsAddPersonModalOpen] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const contextMenuRef = useRef(null);
    const [isFilePanelOpen, setIsFilePanelOpen] = useState(false);

    // Create a ref to store the debounced function
    const debouncedSearchRef = useRef();

    // Create the debounced function once
    useEffect(() => {
        debouncedSearchRef.current = debounce((term) => {
            console.log('Performing search for:', term);
            setCurrentPage(1);
            if (term) {
                fetchSearchResults(1, rowsPerPage, term);
            } else {
                fetchPaginatedData(1, rowsPerPage);
            }
        }, 1000);

        // Cleanup function to cancel any pending debounced calls when component unmounts
        return () => {
            debouncedSearchRef.current.cancel();
        };
    }, [rowsPerPage]); // Re-create if rowsPerPage changes

    const handleOpenFilePanel = (personId) => {
        setCurrentPersonId(personId);
        setIsFilePanelOpen(true);
    };

    const handleCloseFilePanel = () => {
        setIsFilePanelOpen(false);
        setCurrentPersonId(null);
    };

    const handleMouseEnter = (id) => {
        setHoveredRow(id);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const handleClick = (id, field) => {
        setSelectedCell({ id, field });
    };

    const handleDoubleClick = (id, field, value) => {
        if (field === 'company_name') {
            // Navigate to company page using company_id instead of id
            const person = data.find(p => p.id === id);
            if (person && person.company_id) {
                navigate(`/company/${person.company_id}`);
            } else {
                console.error('Company ID not found for this person');
            }
        } else {
            console.log('Double-clicked:', id, field, value);
            setEditingCell({ id, field });
            setEditValue(value || '');
        }
    };

    const handleKeyDown = (e, id, field) => {
        if (e.key === 'Enter') {
            handleBlur(id, field, editValue);
        } else if (e.key === 'Escape') {
            setEditingCell({ id: null, field: null });
        }
    };

    const handleBlur = async (id, field, value) => {
        console.log('Blur:', id, field, value);
        setEditingCell({ id: null, field: null });

        const now = new Date().toISOString();

        // Immediately update the local state
        setData(prevData =>
            prevData.map(person =>
                person.id === id ? { ...person, [field]: value, last_updated: now } : person
            )
        );
        setFilteredData(prevData =>
            prevData.map(person =>
                person.id === id ? { ...person, [field]: value, last_updated: now } : person
            )
        );

        try {
            await axios.put(`${baseUrl}/api/people/${id}`, {
                [field]: value,
                last_updated: now,
            });
            console.log('Data updated successfully');
        } catch (error) {
            console.error('Error updating data:', error);
            // Revert the change if the API call fails
            setData(prevData =>
                prevData.map(person =>
                    person.id === id ? { ...person, [field]: person[field], last_updated: person.last_updated } : person
                )
            );
            setFilteredData(prevData =>
                prevData.map(person =>
                    person.id === id ? { ...person, [field]: person[field], last_updated: person.last_updated } : person
                )
            );
        }
    };

    const highlightText = useCallback((text) => {
        if (!searchTerm || text == null) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text.toString().split(regex);
        if (parts.length > 100) {
            // If there are too many matches, return the original text
            return text;
        }
        return (
            <span>
                {parts.map((part, index) =>
                    regex.test(part) ? (
                        <span key={index} className="highlight">{part}</span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    }, [searchTerm]);

    const handleViewNotes = (notes, personId) => {
        try {
            const parsedNotes = notes ? JSON.parse(notes) : [];
            setSelectedNotes(Array.isArray(parsedNotes) ? parsedNotes.map((note, index) => ({ id: index + 1, content: note })) : []);
            setIsNotePanelOpen(true);
            setCurrentPersonId(personId);
        } catch (error) {
            console.error('Error parsing notes:', error);
            setSelectedNotes(notes ? [{ id: 1, content: notes }] : []);
            setIsNotePanelOpen(true);
            setCurrentPersonId(personId);
        }
    };

    const handleAddNote = () => {
        const newNoteId = Date.now(); // Use timestamp as a unique id
        const newNote = { id: newNoteId, content: '' };
        setSelectedNotes(prevNotes => {
            // Ensure prevNotes is an array, if not, start with an empty array
            const notesArray = Array.isArray(prevNotes) ? prevNotes : [];
            return [...notesArray, newNote];
        });
        setEditingNoteId(newNoteId); // Immediately set the new note to edit mode
    };

    const handleNoteChange = (id, content) => {
        setSelectedNotes(prevNotes => {
            // Ensure prevNotes is an array, if not, start with an empty array
            const notesArray = Array.isArray(prevNotes) ? prevNotes : [];
            return notesArray.map(note => note.id === id ? { ...note, content } : note);
        });
    };

    const handleNoteBlur = async () => {
        setEditingNoteId(null);
        if (currentPersonId) {
            try {
                await saveNotesToDatabase(currentPersonId, selectedNotes);
                console.log('Notes updated successfully');
            } catch (error) {
                console.error('Error saving notes:', error);
            }
        }
    };

    const saveNotesToDatabase = async (personId, notes) => {
        try {
            await axios.put(`${baseUrl}/api/people/${personId}`, {
                notes: JSON.stringify(notes.map(note => note.content))
            });
            // Update local data state
            setData(prevData =>
                prevData.map(person =>
                    person.id === personId ? { ...person, notes: JSON.stringify(notes.map(note => note.content)) } : person
                )
            );
            setFilteredData(prevData =>
                prevData.map(person =>
                    person.id === personId ? { ...person, notes: JSON.stringify(notes.map(note => note.content)) } : person
                )
            );
        } catch (error) {
            throw error;
        }
    };

    const handleNoteDoubleClick = (id) => {
        setEditingNoteId(id);
    };

    const handleDeleteNote = async (noteId) => {
        const updatedNotes = selectedNotes.filter(note => note.id !== noteId);
        setSelectedNotes(updatedNotes);

        if (currentPersonId) {
            try {
                await saveNotesToDatabase(currentPersonId, updatedNotes);
                console.log('Note deleted successfully');
            } catch (error) {
                console.error('Error deleting note:', error);
            }
        }
    };

    const renderCellContent = (column, value, personId) => {
        if (['linkedin', 'company_linkedin', 'company_website'].includes(column)) {
            return value ? (
                <a href={value.startsWith('http') ? value : `https://${value}`} target="_blank" rel="noopener noreferrer">
                    {highlightText(value)}
                </a>
            ) : null;
        } else if (column === 'notes') {
            return (
                <button onClick={() => handleViewNotes(value, personId)}>
                    View Notes
                </button>
            );
        }
        return highlightText(value);
    };

    const renderTableHeader = () => (
        <thead className={isColumnSelectorOpen ? 'greyed-out-header' : ''}>
            <tr>
                {/* Keep the checkbox column header but make it thinner */}
                <th className="checkbox-column" style={{ width: '15px', minWidth: '15px', maxWidth: '15px', textAlign: 'center' }}>
                    {/* Empty header cell for the checkbox column */}
                </th>
                {selectedColumns.map((column) => (
                    <th
                        key={column}
                        className="table-cell"
                        style={{ width: columnWidths[column] || 150, cursor: 'pointer' }}
                        onClick={() => handleSort(column)}
                    >
                        {column.replace(/_/g, ' ').toUpperCase()}
                        {sortColumn === column && (
                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                    </th>
                ))}
                <th className="table-cell" style={{ width: 150, cursor: 'pointer' }}>
                    FILES
                </th>
            </tr>
        </thead>
    );

    const renderTableBody = (dataToRender) => (
        <tbody>
            {dataToRender.map(person => (
                <tr
                    key={person.id}
                    onMouseEnter={() => handleMouseEnter(person.id)}
                    onMouseLeave={handleMouseLeave}
                    className={hoveredRow === person.id ? 'hovered-row' : ''}
                    onContextMenu={(event) => handleContextMenu(event, person.id)}
                >
                    <td className="checkbox-column" style={{ width: '15px', minWidth: '15px', maxWidth: '15px', textAlign: 'center' }}>
                        <input
                            type="checkbox"
                            checked={selectedPeople.includes(person.id)}
                            onChange={() => handleSelectPerson(person.id)}
                            style={{ margin: '0 auto' }}
                        />
                    </td>
                    {selectedColumns.map(column => (
                        <td
                            key={`${person.id}-${column}`}
                            className={`table-cell ${selectedCell.id === person.id && selectedCell.field === column ? 'selected-cell' : ''}`}
                            style={{ width: columnWidths[column] || 150 }}
                            onClick={() => handleClick(person.id, column)}
                            onDoubleClick={() => handleDoubleClick(person.id, column, person[column])}
                        >
                            {editingCell.id === person.id && editingCell.field === column ? (
                                <input
                                    type="text"
                                    value={editValue}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur(person.id, column, editValue)}
                                    onKeyDown={(e) => handleKeyDown(e, person.id, column)}
                                    autoFocus
                                />
                            ) : (
                                renderCellContent(column, person[column], person.id)
                            )}
                        </td>
                    ))}
                    <td className="table-cell">
                        <button onClick={() => handleOpenFilePanel(person.id)}>View Files</button>
                    </td>
                </tr>
            ))}
        </tbody>
    );

    const sortData = useCallback((data, column, direction) => {
        return [...data].sort((a, b) => {
            if (a[column] == null) return 1;
            if (b[column] == null) return -1;
            if (column === 'last_updated') {
                return direction === 'asc'
                    ? new Date(a[column]) - new Date(b[column])
                    : new Date(b[column]) - new Date(a[column]);
            }
            if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, []);

    const handleSort = (column) => {
        const newDirection = column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
        fetchPaginatedData(currentPage, rowsPerPage);
    };

    const fetchPaginatedData = async (page, rowsPerPage) => {
        setIsLoadingAll(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people`, {
                params: {
                    page: page,
                    per_page: rowsPerPage,
                    sort_by: sortColumn,
                    sort_direction: sortDirection
                }
            });
            if (response.data && response.data.people) {
                const fetchedData = response.data.people;
                setData(fetchedData);
                setFilteredData(fetchedData);
                setTotalRows(response.data.total_count);  // Use total_count here
                setTotalPages(Math.ceil(response.data.total_count / rowsPerPage));
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching paginated data:', error);
        } finally {
            setIsLoadingAll(false);
        }
    };

    const fetchSearchResults = async (page, rowsPerPage, searchTerm) => {
        setIsLoadingAll(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people/search`, {
                params: {
                    query: searchTerm,
                    page: page,
                    per_page: rowsPerPage
                }
            });
            if (response.data && response.data.people) {
                const fetchedData = response.data.people;
                setData(fetchedData);
                setFilteredData(fetchedData);
                setTotalRows(response.data.total_count);
                setTotalPages(response.data.total_pages);
                setCurrentPage(response.data.page);
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            setIsLoadingAll(false);
        }
    };

    useEffect(() => {
        if (searchTerm) {
            fetchSearchResults(currentPage, rowsPerPage, searchTerm);
        } else {
            fetchPaginatedData(currentPage, rowsPerPage);
        }
    }, [currentPage, rowsPerPage, sortColumn, sortDirection, searchTerm]);

    const filterAndHighlightData = (dataToFilter) => {
        const searchTerms = searchTerm.toLowerCase().split(' ');
        const results = dataToFilter.filter(item =>
            searchTerms.every(term =>
                Object.values(item).some(value =>
                    value != null && value.toString().toLowerCase().includes(term)
                )
            )
        );

        setFilteredData(results);

        // Count matches
        let fields = 0;
        let cells = 0;
        let records = 0;

        results.forEach(item => {
            let recordMatched = false;
            Object.entries(item).forEach(([key, value]) => {
                if (value != null && value.toString().toLowerCase().includes(searchTerm)) {
                    cells++;
                    fields++;
                    recordMatched = true;
                }
            });
            if (recordMatched) {
                records++;
            }
        });

        setSearchResults({ fields, cells, records });
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        console.log('Search term updated:', searchTerm);
        debouncedSearchRef.current(searchTerm);
    };

    const handleGroupByChange = (e) => {
        setGroupBy(e.target.value);
    };

    const handleResize = (column, width) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [column]: width,
        }));
    };

    const groupData = (data, groupBy) => {
        if (!groupBy) return data;
        return data.reduce((acc, item) => {
            const key = item[groupBy] || 'Unknown';
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});
    };

    const toggleGroup = (group) => {
        setCollapsedGroups(prev => ({
            ...prev,
            [group]: !prev[group]
        }));
    };

    const renderGroupedData = () => {
        if (!groupBy) {
            return renderTableBody(data);
        }

        const groupedData = groupData(data, groupBy);

        return Object.entries(groupedData).map(([group, groupData]) => (
            <React.Fragment key={group}>
                <tr
                    className="group-header"
                    onClick={() => toggleGroup(group)}
                >
                    <td colSpan={selectedColumns.length + 2}>
                        {collapsedGroups[group] ? '▶' : '▼'} {group} ({groupData.length})
                    </td>
                </tr>
                {!collapsedGroups[group] && renderTableBody(groupData)}
            </React.Fragment>
        ));
    };

    const handleColumnToggle = (column) => {
        setSelectedColumns(prevColumns => {
            if (prevColumns.includes(column)) {
                return prevColumns.filter(col => col !== column);
            } else {
                return [...prevColumns, column];
            }
        });
    };

    const renderColumnSelector = () => (
        <div className="column-selector">
            <h3>Select Columns to Display</h3>
            {availableColumns.map(column => (
                <label key={column}>
                    <input
                        type="checkbox"
                        checked={selectedColumns.includes(column)}
                        onChange={() => handleColumnToggle(column)}
                    />
                    {column.replace(/_/g, ' ')}
                </label>
            ))}
        </div>
    );

    useEffect(() => {
        function handleClickOutside(event) {
            if (notePanelRef.current && !notePanelRef.current.contains(event.target)) {
                setIsNotePanelOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notePanelRef]);

    const handleApplyColumns = (newColumns) => {
        setSelectedColumns(newColumns);
        setIsColumnSelectorOpen(false);
    };

    const PaginationControls = ({ currentPage, rowsPerPage, totalRows, totalPages, onPageChange, onRowsPerPageChange }) => {
        const startRow = (currentPage - 1) * rowsPerPage + 1;
        const endRow = Math.min(currentPage * rowsPerPage, totalRows);

        return (
            <div className="pagination-controls">
                <span>Rows per page:</span>
                <select value={rowsPerPage} onChange={(e) => {
                    const newRowsPerPage = Number(e.target.value);
                    onRowsPerPageChange(newRowsPerPage);
                    if (searchTerm) {
                        fetchSearchResults(1, newRowsPerPage, searchTerm);
                    } else {
                        fetchPaginatedData(1, newRowsPerPage);
                    }
                }}>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <span>{`${startRow} - ${endRow} of ${totalRows} people`}</span>
                <button onClick={() => {
                    const newPage = currentPage - 1;
                    onPageChange(newPage);
                    if (searchTerm) {
                        fetchSearchResults(newPage, rowsPerPage, searchTerm);
                    } else {
                        fetchPaginatedData(newPage, rowsPerPage);
                    }
                }} disabled={currentPage === 1}>&lt;</button>
                <button onClick={() => {
                    const newPage = currentPage + 1;
                    onPageChange(newPage);
                    if (searchTerm) {
                        fetchSearchResults(newPage, rowsPerPage, searchTerm);
                    } else {
                        fetchPaginatedData(newPage, rowsPerPage);
                    }
                }} disabled={currentPage === totalPages}>&gt;</button>
            </div>
        );
    };

    const handleInputChange = (e) => {
        setEditValue(e.target.value);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                handleCloseContextMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleContextMenu = (event, personId) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        setSelectedPersonId(personId);
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleDeletePerson = async () => {
        if (selectedPersonId) {
            try {
                await axios.delete(`${baseUrl}/api/people/${selectedPersonId}`);
                setData(prevData => prevData.filter(person => person.id !== selectedPersonId));
                setFilteredData(prevData => prevData.filter(person => person.id !== selectedPersonId));
                handleCloseContextMenu();
            } catch (error) {
                console.error('Error deleting person:', error);
            }
        }
    };

    const handleSelectPerson = (personId) => {
        setSelectedPeople(prev =>
            prev.includes(personId)
                ? prev.filter(id => id !== personId)
                : [...prev, personId]
        );
    };

    const handleDeleteSelected = async () => {
        if (window.confirm(`Are you sure you want to delete ${selectedPeople.length} selected people?`)) {
            try {
                for (const personId of selectedPeople) {
                    await axios.delete(`${baseUrl}/api/people/${personId}`);
                }
                setData(prevData => prevData.filter(person => !selectedPeople.includes(person.id)));
                setFilteredData(prevData => prevData.filter(person => !selectedPeople.includes(person.id)));
                setSelectedPeople([]);
            } catch (error) {
                console.error('Error deleting selected people:', error);
            }
        }
    };

    const handleAddPerson = async (newPerson) => {
        try {
            // The newPerson object should already include notes: '[]'
            // but we can ensure it here as well
            const personToAdd = { ...newPerson, notes: newPerson.notes || '[]' };

            const response = await axios.post(`${baseUrl}/api/people`, personToAdd);
            const addedPerson = response.data.person;
            if (addedPerson) {
                // Add the new person to the beginning of the data array
                setData(prevData => [addedPerson, ...prevData]);
                setFilteredData(prevData => [addedPerson, ...prevData]);

                // Reset to the first page to show the new person
                setCurrentPage(1);

                // If sorting is active, re-sort the data
                if (sortColumn) {
                    const sortedData = sortData([addedPerson, ...data], sortColumn, sortDirection);
                    setData(sortedData);
                    setFilteredData(sortedData);
                }

                // Refresh the current view
                filterAndHighlightData([addedPerson, ...data]);

                // Create a deal if the createDeal flag is set
                if (newPerson.createDeal) {
                    try {
                        const dealResponse = await axios.post(`${baseUrl}/api/people/${addedPerson.id}/create_deal`, {
                            company_id: newPerson.company_id,
                            source: 'Manual Entry',
                            kkc_lead: 'Default Lead'
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        console.log('Deal created:', dealResponse.data);
                    } catch (error) {
                        console.error('Error creating deal:', error);
                    }
                }
            }
            setIsAddPersonModalOpen(false);
        } catch (error) {
            console.error('Error adding person:', error);
        }
    };

    const renderToolbarButtons = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ToolbarButton icon={<FaColumns />} text="Edit Columns" onClick={() => setIsColumnSelectorOpen(true)} />
            <div style={{ position: 'relative', marginLeft: '10px' }}>
                <ToolbarButton
                    text={groupBy ? groupBy.replace(/_/g, ' ') : "No grouping"}
                    icon={<FaCaretDown />}
                    onClick={() => setIsGroupingOpen(!isGroupingOpen)}
                />
                {isGroupingOpen && (
                    <div style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        backgroundColor: '#0f0d2e',
                        border: '1px solid #625896',
                        borderRadius: '4px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                        zIndex: 1000,
                    }}>
                        <div
                            onClick={() => {
                                handleGroupByChange({ target: { value: '' } });
                                setIsGroupingOpen(false);
                            }}
                            style={{
                                padding: '5px 10px',
                                cursor: 'pointer',
                                color: 'white',
                                backgroundColor: '#0f0d2e',
                                transition: 'background-color 0.3s',
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                        >
                            No grouping
                        </div>
                        {selectedColumns.map(column => (
                            <div
                                key={column}
                                onClick={() => {
                                    handleGroupByChange({ target: { value: column } });
                                    setIsGroupingOpen(false);
                                }}
                                style={{
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    color: 'white',
                                    backgroundColor: '#0f0d2e',
                                    transition: 'background-color 0.3s',
                                }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                            >
                                {column.replace(/_/g, ' ')}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <ToolbarButton
                text="Add Person"
                onClick={() => {
                    console.log("Add Person button clicked");
                    setIsAddPersonModalOpen(true);
                }}
            />
            {selectedPeople.length > 0 && (
                <ToolbarButton text={`Delete Selected (${selectedPeople.length})`} onClick={handleDeleteSelected} />
            )}
        </div>
    );

    return (
        <div>
            <div style={{ color: 'grey', fontSize: '14px', marginBottom: '5px' }}>
                Explore /
            </div>
            <h1>People</h1>
            <div className="toolbar" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 15px',
                borderTop: '1px solid #625896',
                borderBottom: '1px solid #625896',
                marginBottom: '20px',
            }}>
                {renderToolbarButtons()}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        style={{
                            padding: '5px 10px',
                            height: '20px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PaginationControls
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                        onRowsPerPageChange={(newRowsPerPage) => {
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(1);
                        }}
                    />
                </div>
            </div>
            <ColumnSelector
                isOpen={isColumnSelectorOpen}
                onClose={() => setIsColumnSelectorOpen(false)}
                columns={availableColumns}
                selectedColumns={selectedColumns}
                onApply={handleApplyColumns}
            />
            {isColumnSelectorOpen && <div className="edit-columns-modal-overlay"></div>}
            {isLoadingAll ? (
                <LoadingIndicator message="Loading people..." />
            ) : (
                <div className="table-container">
                    <table>
                        {renderTableHeader()}
                        {renderGroupedData()}
                    </table>
                </div>
            )}
            {isNotePanelOpen && (
                <div className="note-panel" ref={notePanelRef}>
                    <h2>Notes</h2>
                    {selectedNotes.map((note) => (
                        <div key={note.id} className="note-item">
                            <div className="note-content">
                                {editingNoteId === note.id ? (
                                    <textarea
                                        value={note.content}
                                        onChange={(e) => handleNoteChange(note.id, e.target.value)}
                                        onBlur={handleNoteBlur}
                                        autoFocus
                                    />
                                ) : (
                                    <p onDoubleClick={() => handleNoteDoubleClick(note.id)}>
                                        {note.content || 'Double-click to edit'}
                                    </p>
                                )}
                            </div>
                            <button className="delete-note" onClick={() => handleDeleteNote(note.id)}>
                                ×
                            </button>
                        </div>
                    ))}
                    <button onClick={handleAddNote}>Add Note</button>
                </div>
            )}
            <AddPersonModal
                isOpen={isAddPersonModalOpen}
                onClose={() => setIsAddPersonModalOpen(false)}
                onAdd={handleAddPerson}
            />
            {contextMenu !== null && (
                <div
                    ref={contextMenuRef}
                    style={{
                        position: 'fixed',
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX,
                        backgroundColor: '#0f0d2e',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                        borderRadius: '4px',
                        zIndex: 1001,
                        padding: '5px',
                    }}
                >
                    <div
                        style={{
                            padding: '8px 16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                        onClick={handleDeletePerson}
                    >
                        Delete Person
                    </div>
                </div>
            )}
            {isFilePanelOpen && (
                <FilePanel
                    id={currentPersonId}
                    type="people"
                    onClose={handleCloseFilePanel}
                />
            )}
        </div>
    );
}

const ToolbarButton = ({ icon, text, onClick }) => (
    <button
        onClick={onClick}
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            backgroundColor: '#0f0d2e',
            border: '1px solid #625896',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            color: 'white',
            transition: 'background-color 0.3s'
        }}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
    >
        {icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
        {text}
        {text === "No grouping" && <FaCaretDown style={{ marginLeft: '5px' }} />}
    </button>
);

export default People;