import React, { createContext, useContext } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

    const value = {
        baseUrl,
    };

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const getBaseUrl = () => {
    return process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
};