import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './App.css';
import Select from 'react-select';
import { FaSearch, FaColumns, FaCaretDown } from 'react-icons/fa';
import { MdViewColumn } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import NewDealModal from './NewDealModal'; // Import the new Modal
import { getBaseUrl } from './ApiContext';
import LoadingIndicator from './LoadingIndicator';



const baseUrl = getBaseUrl();

const STAGE_OPTIONS = [
    'Top of Funnel',
    'Radar Screen',
    'Digging In',
    'Hunting License',
    'Approved',
    'Closed',
    'Hold',
    'Passed',
    'Track',
    'Passed but Shareable',
    'Gave Term Sheet but Lost'
];

const ALL_COLUMNS = [
    'id', 'company_name', 'company_id', 'stage', 'kkc_lead', 'source', 'last_updated',
    'company_website', 'company_linkedin', 'notes'
];

const DEFAULT_COLUMNS = ['company_name', 'stage', 'kkc_lead', 'source'];

const STAGE_ORDER = [
    'Top of Funnel',
    'Radar Screen',
    'Digging In',
    'Hunting License',
    'Approved',
    'Closed',
    'Hold',
    'Passed',
    'Track',
    'Passed but Shareable',
    'Gave Term Sheet but Lost'
];

const KKC_LEAD_OPTIONS = [
    { value: 'SS', label: 'SS' },
    { value: 'AV', label: 'AV' },
    { value: 'LL', label: 'LL' },
    { value: 'SD', label: 'SD' },
    { value: 'Kyber Drive', label: 'Kyber Drive' },
];

function ColumnSelector({ isOpen, onClose, columns, selectedColumns, onApply }) {
    const [search, setSearch] = useState('');
    const [visibleColumns, setVisibleColumns] = useState(selectedColumns);

    const filteredColumns = columns.filter(col =>
        col.toLowerCase().replace(/_/g, ' ').includes(search.toLowerCase())
    );

    const handleToggle = (column) => {
        setVisibleColumns(prev =>
            prev.includes(column) ? prev.filter(c => c !== column) : [...prev, column]
        );
    };

    const moveColumn = (index, direction) => {
        const newVisibleColumns = [...visibleColumns];
        const column = newVisibleColumns[index];
        newVisibleColumns.splice(index, 1);
        newVisibleColumns.splice(index + direction, 0, column);
        setVisibleColumns(newVisibleColumns);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="column-selector-modal">
            <h2>Edit columns</h2>
            <input
                type="text"
                placeholder="Search columns..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="search-input"
            />
            <div className="columns-container">
                <div className="all-columns">
                    <h3>All Columns</h3>
                    {filteredColumns.map(column => (
                        <label key={column} className="column-item">
                            <input
                                type="checkbox"
                                checked={visibleColumns.includes(column)}
                                onChange={() => handleToggle(column)}
                            />
                            {column.replace(/_/g, ' ')}
                        </label>
                    ))}
                </div>
                <div className="visible-columns">
                    <h3>Visible Columns</h3>
                    {visibleColumns.map((column, index) => (
                        <div key={column} className="selected-column">
                            {column.replace(/_/g, ' ')}
                            <div className="column-controls">
                                <button onClick={() => moveColumn(index, -1)} disabled={index === 0}>↑</button>
                                <button onClick={() => moveColumn(index, 1)} disabled={index === visibleColumns.length - 1}>↓</button>
                                <button onClick={() => handleToggle(column)}>×</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="action-buttons">
                <button className="clear-button" onClick={() => setVisibleColumns([])}>Clear</button>
                <button className="cancel-button" onClick={onClose}>Cancel</button>
                <button className="apply-button" onClick={() => onApply(visibleColumns)}>Apply</button>
            </div>
        </Modal>
    );
}

function Deals() {
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [groupBy, setGroupBy] = useState('stage');
    const [columnWidths, setColumnWidths] = useState({});
    const [editingCell, setEditingCell] = useState({ id: null, field: null });
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedCell, setSelectedCell] = useState({ id: null, field: null });
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [searchResults, setSearchResults] = useState({ fields: 0, cells: 0, records: 0 });
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [editValue, setEditValue] = useState('');
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [isDealPanelOpen, setIsDealPanelOpen] = useState(false);
    const [isNewDealFormOpen, setIsNewDealFormOpen] = useState(false);
    const [newDealData, setNewDealData] = useState({
        company_name: '',
        company_id: '',
        founder_ids: [],
        stage: '',
        kkc_lead: '',
        source: '',
    });
    const [companies, setCompanies] = useState([]);
    const [people, setPeople] = useState([]);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
    const [availableColumns, setAvailableColumns] = useState(ALL_COLUMNS);
    const [selectedColumns, setSelectedColumns] = useState(DEFAULT_COLUMNS);
    const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
    const [isGroupingOpen, setIsGroupingOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(0);
    const [initialSort, setInitialSort] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedDealId, setSelectedDealId] = useState(null);
    const [shouldAutoUpdateColumns, setShouldAutoUpdateColumns] = useState(true);
    const [selectedKKCLeads, setSelectedKKCLeads] = useState([]);
    const [isKKCLeadFilterActive, setIsKKCLeadFilterActive] = useState(false);

    // Function to update URL with current filters and collapsed groups
    const updateURL = useCallback((kkcLeads, collapsed) => {
        const searchParams = new URLSearchParams();

        if (kkcLeads.length > 0) {
            searchParams.set('kkcLeads', kkcLeads.map(lead => lead.value).join(','));
        }

        if (Object.keys(collapsed).length > 0) {
            searchParams.set('collapsed', JSON.stringify(collapsed));
        }

        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }, [navigate, location.pathname]);

    // Function to parse URL params on component mount
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        // Parse KKC Leads
        const kkcLeadsParam = searchParams.get('kkcLeads');
        if (kkcLeadsParam) {
            const leads = kkcLeadsParam.split(',').map(lead => ({ value: lead, label: lead }));
            setSelectedKKCLeads(leads);
        }

        // Parse collapsed groups
        const collapsedParam = searchParams.get('collapsed');
        if (collapsedParam) {
            try {
                const parsed = JSON.parse(collapsedParam);
                setCollapsedGroups(parsed);
            } catch (error) {
                console.error('Error parsing collapsed groups from URL:', error);
            }
        }
    }, [location.search]);

    // Update URL when filters or collapsed groups change
    useEffect(() => {
        updateURL(selectedKKCLeads, collapsedGroups);
    }, [selectedKKCLeads, collapsedGroups, updateURL]);

    useEffect(() => {
        console.log('Selected Columns:', selectedColumns);
    }, [selectedColumns]);

    const handleApplyColumns = (newColumns) => {
        setSelectedColumns(newColumns);
        setIsColumnSelectorOpen(false);
    };

    const handleMouseEnter = (id) => {
        setHoveredRow(id);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const handleClick = (id, field) => {
        setSelectedCell({ id, field });
    };

    const handleDoubleClick = (id, field, value) => {
        if (field === 'id') {
            fetchDealDetails(id);
        } else if (field === 'company_name') {
            // Open the deal in a new tab
            window.open(`/deal/${id}`, '_blank');
        } else {
            setEditingCell({ id, field });
            setEditValue(value || '');
        }
    };

    const DealDetailsPanel = ({ deal, onClose }) => {
        if (!deal) return null;

        const renderFounders = () => {
            if (!deal.founders || deal.founders.length === 0) {
                return <p>No founder information available</p>;
            }

            return deal.founders.map((founder, index) => (
                <div key={founder.id || index} className="founder-info">
                    <h4>Founder {index + 1}</h4>
                    {Object.entries(founder).map(([key, value]) => (
                        <p key={key}><strong>{key}:</strong> {String(value)}</p>
                    ))}
                </div>
            ));
        };

        return (
            <div className="deal-panel">
                <div className="deal-panel-header">
                    <h2>Deal Details</h2>
                    <button onClick={onClose}>Close</button>
                </div>
                <div className="deal-panel-content">
                    <div className="deal-info">
                        <h3>Deal Information</h3>
                        {Object.entries(deal).map(([key, value]) => (
                            key !== 'founders' && key !== 'company' && (
                                <p key={key}><strong>{key}:</strong> {String(value)}</p>
                            )
                        ))}
                    </div>
                    {deal.company && (
                        <div className="company-info">
                            <h3>Company Information</h3>
                            {Object.entries(deal.company).map(([key, value]) => (
                                <p key={key}><strong>{key}:</strong> {String(value)}</p>
                            ))}
                        </div>
                    )}
                    <div className="founders-info">
                        <h3>Founders</h3>
                        {renderFounders()}
                    </div>
                </div>
            </div>
        );
    };

    const handleInputChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleKeyDown = (e, id, field) => {
        if (e.key === 'Enter') {
            handleBlur(id, field, editValue);
        } else if (e.key === 'Escape') {
            setEditingCell({ id: null, field: null });
        }
    };

    const handleBlur = async (id, field, value) => {
        setEditingCell({ id: null, field: null });
        const now = new Date().toISOString();

        setData(prevData =>
            prevData.map(deal =>
                deal.id === id ? { ...deal, [field]: value, last_updated: now } : deal
            )
        );
        setFilteredData(prevData =>
            prevData.map(deal =>
                deal.id === id ? { ...deal, [field]: value, last_updated: now } : deal
            )
        );

        try {
            await axios.put(`${baseUrl}/api/deals/${id}`, {
                [field]: value,
                last_updated: now,
            });
            console.log('Data updated successfully');
        } catch (error) {
            console.error('Error updating data:', error.response ? error.response.data : error.message);
            // Revert the change if the API call fails
            setData(prevData =>
                prevData.map(deal =>
                    deal.id === id ? { ...deal, [field]: deal[field], last_updated: deal.last_updated } : deal
                )
            );
            setFilteredData(prevData =>
                prevData.map(deal =>
                    deal.id === id ? { ...deal, [field]: deal[field], last_updated: deal.last_updated } : deal
                )
            );
        }
    };

    const renderCellContent = (column, value, dealId) => {
        if (['company_website'].includes(column)) {
            return value ? (
                <a href={value.startsWith('http') ? value : `https://${value}`} target="_blank" rel="noopener noreferrer">
                    {value}
                </a>
            ) : null;
        }
        if (column === 'stage') {
            return (
                <select
                    value={value || ''}
                    onChange={(e) => handleBlur(dealId, 'stage', e.target.value)}
                    className="select-dropdown"
                >
                    <option value="">Select Stage</option>
                    {STAGE_OPTIONS.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            );
        }
        if (column === 'kkc_lead') {
            return (
                <select
                    value={value || ''}
                    onChange={(e) => handleBlur(dealId, 'kkc_lead', e.target.value)}
                    className="select-dropdown"
                >
                    <option value="">Select KCC Lead</option>
                    {['none', 'SS', 'AV', 'LL', 'SD', 'Kyber Drive'].map(option => (
                        <option key={option} value={option === 'none' ? '' : option}>
                            {option === 'none' ? 'None' : option}
                        </option>
                    ))}
                </select>
            );
        }
        if (column === 'source') {
            return value || ''; // Simply return the value without any special styling
        }
        return value;
    };

    const sortData = useCallback((data, column, direction) => {
        return [...data].sort((a, b) => {
            if (a[column] == null) return 1;
            if (b[column] == null) return -1;
            if (column === 'last_updated') {
                return direction === 'asc'
                    ? new Date(a[column]) - new Date(b[column])
                    : new Date(b[column]) - new Date(a[column]);
            }
            if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, []);

    const handleSort = (column) => {
        const newDirection = column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
        const sortedData = sortData(data, column, newDirection);
        setData(sortedData);
    };

    const fetchDealDetails = async (dealId) => {
        console.log('Fetching deal details for ID:', dealId);
        try {
            const response = await axios.get(`${baseUrl}/api/deals/${dealId}/details`);
            console.log('Received deal details:', response.data);
            setSelectedDeal(response.data);
            setIsDealPanelOpen(true);
        } catch (error) {
            console.error('Error fetching deal details:', error);
        }
    };

    const fetchAllData = async () => {
        setIsLoadingAll(true);
        try {
            const stageFilter = STAGE_OPTIONS.map(stage => `stage = '${stage}'`).join(' OR ');
            let filter = `(${stageFilter}) AND NOT (source IN ('Specter', 'Harmonic') AND stage IN ('Top of Funnel') AND kkc_lead = 'Kyber Drive')`;

            // Add KKC lead filter
            if (selectedKKCLeads.length > 0) {
                const kkcLeadFilter = selectedKKCLeads.map(lead => `kkc_lead = '${lead.value}'`).join(' OR ');
                filter += ` AND (${kkcLeadFilter})`;
            }

            const response = await axios.get(`${baseUrl}/api/deals`, {
                params: {
                    filter: filter,
                    fields: '*'
                }
            });
            if (response.data && response.data.deals) {
                let fetchedData = response.data.deals;

                if (initialSort) {
                    fetchedData = sortData(fetchedData, 'last_updated', 'desc');
                    setSortColumn('last_updated');
                    setSortDirection('desc');
                    setInitialSort(false);
                } else if (sortColumn) {
                    fetchedData = sortData(fetchedData, sortColumn, sortDirection);
                }

                setData(fetchedData);
                setFilteredData(fetchedData);
                filterAndHighlightData(fetchedData);

                // if (fetchedData.length > 0 && shouldAutoUpdateColumns) {
                //     const dataColumns = Object.keys(fetchedData[0]);
                //     setAvailableColumns(dataColumns);
                //     setSelectedColumns(prevSelected => {
                //         if (prevSelected.length === 0) {
                //             return dataColumns.slice(0, 5);
                //         }
                //         return prevSelected.filter(col => dataColumns.includes(col));
                //     });
                //     setShouldAutoUpdateColumns(false);  // Prevent future auto-updates
                // }

                setColumnWidths(prevWidths => {
                    const newWidths = { ...prevWidths };
                    availableColumns.forEach(column => {
                        if (!newWidths[column]) {
                            newWidths[column] = 150;
                        }
                    });
                    return newWidths;
                });
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching all data:', error);
        }
        setIsLoadingAll(false);
    };

    useEffect(() => {
        fetchAllData();
        fetchCompanies();
        fetchPeople();
    }, [searchTerm, sortColumn, sortDirection, initialSort, selectedKKCLeads]);

    const fetchCompanies = async () => {
        setIsLoadingCompanies(true);
        try {
            const response = await axios.get(`${baseUrl}/api/companies`);
            setCompanies(response.data.companies);
        } catch (error) {
            console.error('Error fetching companies:', error);
            // Optionally set an error state here
        } finally {
            setIsLoadingCompanies(false);
        }
    };

    const fetchPeople = async () => {
        setIsLoadingPeople(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people`);
            setPeople(response.data.people);
        } catch (error) {
            console.error('Error fetching people:', error);
            // Optionally set an error state here
        } finally {
            setIsLoadingPeople(false);
        }
    };

    const filterAndHighlightData = (dataToFilter) => {
        const lowercaseSearchTerm = searchTerm.toLowerCase();
        const results = dataToFilter.filter(item =>
            Object.values(item).some(value =>
                value != null && value.toString().toLowerCase().includes(lowercaseSearchTerm)
            )
        );

        setFilteredData(results);

        let fields = 0;
        let cells = 0;
        let records = 0;

        results.forEach(item => {
            let recordMatched = false;
            Object.entries(item).forEach(([key, value]) => {
                if (value != null && value.toString().toLowerCase().includes(lowercaseSearchTerm)) {
                    cells++;
                    fields++;
                    recordMatched = true;
                }
            });
            if (recordMatched) {
                records++;
            }
        });

        setSearchResults({ fields, cells, records });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        fetchAllData();
    };

    const handleGroupByChange = (e) => {
        setGroupBy(e.target.value);
    };

    const handleResize = (column, width) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [column]: width,
        }));
    };

    const groupData = (data, groupBy) => {
        if (!groupBy) return data;
        const grouped = data.reduce((acc, item) => {
            const key = item[groupBy] || 'Unknown';
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});

        // If grouping by stage, sort the keys based on STAGE_ORDER
        if (groupBy === 'stage') {
            return Object.keys(grouped)
                .sort((a, b) => {
                    const indexA = STAGE_ORDER.indexOf(a);
                    const indexB = STAGE_ORDER.indexOf(b);
                    if (indexA === -1) return 1;  // Unknown stages at the end
                    if (indexB === -1) return -1;
                    return indexA - indexB;
                })
                .reduce((obj, key) => {
                    obj[key] = grouped[key];
                    return obj;
                }, {});
        }

        return grouped;
    };

    useEffect(() => {
        // Initialize the "Passed" and "Top of Funnel" groups as collapsed when grouping by stage
        if (groupBy === 'stage') {
            setCollapsedGroups(prevState => ({
                ...prevState,
                'Passed': true,
                'Top of Funnel': true
            }));
        }
    }, [groupBy]);

    const toggleGroup = (group) => {
        setCollapsedGroups(prev => {
            const newCollapsed = { ...prev, [group]: !prev[group] };
            updateURL(selectedKKCLeads, newCollapsed);
            return newCollapsed;
        });
    };

    const renderTableHeader = () => (
        <thead className={isColumnSelectorOpen ? 'greyed-out-header' : ''}>
            <tr>
                {selectedColumns.map((column) => (
                    <th
                        key={column}
                        className="table-cell"
                        style={{ width: columnWidths[column] || 150, cursor: 'pointer' }}
                        onClick={() => handleSort(column)}
                    >
                        {column.replace(/_/g, ' ').toUpperCase()}
                        {sortColumn === column && (
                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );

    const renderTableBody = (dataToRender) => (
        <tbody>
            {dataToRender.map(deal => (
                <tr
                    key={deal.id}
                    onMouseEnter={() => handleMouseEnter(deal.id)}
                    onMouseLeave={handleMouseLeave}
                    onContextMenu={(e) => handleContextMenu(e, deal.id)}
                    className={hoveredRow === deal.id ? 'hovered-row' : ''}
                >
                    {selectedColumns.map(column => (
                        <td
                            key={column}
                            className={`table-cell ${selectedCell.id === deal.id && selectedCell.field === column ? 'selected-cell' : ''}`}
                            style={{ width: columnWidths[column] || 150 }}
                            onClick={() => handleClick(deal.id, column)}
                            onDoubleClick={() => handleDoubleClick(deal.id, column, deal[column])}
                        >
                            {editingCell.id === deal.id && editingCell.field === column ? (
                                <input
                                    type="text"
                                    value={editValue}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur(deal.id, column, editValue)}
                                    onKeyDown={(e) => handleKeyDown(e, deal.id, column)}
                                    autoFocus
                                />
                            ) : (
                                renderCellContent(column, deal[column], deal.id)
                            )}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );

    const renderGroupedData = () => {
        if (!groupBy) {
            return renderTableBody(filteredData);
        }

        const groupedData = groupData(filteredData, groupBy);

        return Object.entries(groupedData).flatMap(([group, groupData]) => [
            <tr key={`group-${group}`} className="group-header" onClick={() => toggleGroup(group)}>
                <td colSpan={selectedColumns.length}>
                    <span className="group-indicator">{collapsedGroups[group] ? '▶' : '▼'}</span>
                    <span className="group-name">{group}</span>
                    <span className="group-count">({groupData.length})</span>
                </td>
            </tr>,
            ...(!collapsedGroups[group] ? groupData.map(deal => (
                <tr
                    key={deal.id}
                    onMouseEnter={() => handleMouseEnter(deal.id)}
                    onMouseLeave={handleMouseLeave}
                    onContextMenu={(e) => handleContextMenu(e, deal.id)}
                    className={hoveredRow === deal.id ? 'hovered-row' : ''}
                >
                    {selectedColumns.map(column => (
                        <td
                            key={column}
                            className={`table-cell ${selectedCell.id === deal.id && selectedCell.field === column ? 'selected-cell' : ''}`}
                            style={{ width: columnWidths[column] || 150 }}
                            onClick={() => handleClick(deal.id, column)}
                            onDoubleClick={() => handleDoubleClick(deal.id, column, deal[column])}
                        >
                            {editingCell.id === deal.id && editingCell.field === column ? (
                                <input
                                    type="text"
                                    value={editValue}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur(deal.id, column, editValue)}
                                    onKeyDown={(e) => handleKeyDown(e, deal.id, column)}
                                    autoFocus
                                />
                            ) : (
                                renderCellContent(column, deal[column], deal.id)
                            )}
                        </td>
                    ))}
                </tr>
            )) : [])
        ]);
    };

    const handleNewDealInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'founder_ids') {
            const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
            setNewDealData(prevData => ({
                ...prevData,
                [name]: selectedOptions
            }));
        } else {
            setNewDealData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleNewDealSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedData = {
                ...newDealData,
                founder_ids: newDealData.founder_ids.join(','),
                company_id: newDealData.company_id || null
            };
            const response = await axios.post(`${baseUrl}/api/deals`, formattedData);
            console.log('New deal created:', response.data);
            setIsNewDealFormOpen(false);
            setNewDealData({
                company_name: '',
                company_id: '',
                founder_ids: [],
                stage: '',
                kkc_lead: '',
                source: '',
            });
            fetchAllData(); // Refresh the deals list
        } catch (error) {
            console.error('Error creating new deal:', error);
        }
    };

    const ToolbarButton = ({ icon, text, onClick, style }) => (
        <button
            onClick={onClick}
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                backgroundColor: '#0f0d2e',
                border: '1px solid #625896',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '14px',
                color: 'white',
                transition: 'background-color 0.3s',
                ...style
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
            onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
        >
            {icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
            {text}
            {text === "No grouping" && <FaCaretDown style={{ marginLeft: '5px' }} />}
        </button>
    );

    const PaginationControls = ({ currentPage, rowsPerPage, totalRows, onPageChange, onRowsPerPageChange }) => {
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        const startRow = (currentPage - 1) * rowsPerPage + 1;
        const endRow = Math.min(currentPage * rowsPerPage, totalRows);

        return (
            <div className="pagination-controls">
                <span>Rows per page:</span>
                <select value={rowsPerPage} onChange={(e) => onRowsPerPageChange(Number(e.target.value))}>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <span>{`${startRow} - ${endRow} of ${totalRows} deals`}</span>
                <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
            </div>
        );
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleContextMenu = (event, dealId) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        setSelectedDealId(dealId);

        // Add event listener to close context menu on click outside
        document.addEventListener('click', handleCloseContextMenu);
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
        setSelectedDealId(null);

        // Remove event listener
        document.removeEventListener('click', handleCloseContextMenu);
    };

    const handleDeleteDeal = async () => {
        if (selectedDealId) {
            try {
                await axios.delete(`${baseUrl}/api/deals/${selectedDealId}`);
                setData(prevData => prevData.filter(deal => deal.id !== selectedDealId));
                setFilteredData(prevData => prevData.filter(deal => deal.id !== selectedDealId));
                handleCloseContextMenu();
            } catch (error) {
                console.error('Error deleting deal:', error);
            }
        } else {
            console.error('No deal selected for deletion');
        }
    };

    // Modify the KKC Lead filter change handler
    const handleKKCLeadChange = (newValue) => {
        setSelectedKKCLeads(newValue);
        setIsKKCLeadFilterActive(newValue.length > 0);
        updateURL(newValue, collapsedGroups);
    };

    return (
        <div>
            <div style={{ color: 'grey', fontSize: '14px', marginBottom: '5px' }}>
                Explore /
            </div>
            <h1>Deals</h1>
            <div className="toolbar" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 15px',
                borderTop: '1px solid #625896',
                borderBottom: '1px solid #625896',
                marginBottom: '20px',
            }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <ToolbarButton icon={<FaColumns />} text="Edit Columns" onClick={() => setIsColumnSelectorOpen(true)} />
                    <div style={{ position: 'relative', marginLeft: '10px' }}>
                        <ToolbarButton
                            text={groupBy ? groupBy.replace(/_/g, ' ') : "No grouping"}
                            icon={<FaCaretDown />}
                            onClick={() => setIsGroupingOpen(!isGroupingOpen)}
                        />
                        {isGroupingOpen && (
                            <div style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                backgroundColor: '#0f0d2e',
                                border: '1px solid #625896',
                                borderRadius: '4px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                zIndex: 1000,
                            }}>
                                <div
                                    onClick={() => {
                                        handleGroupByChange({ target: { value: '' } });
                                        setIsGroupingOpen(false);
                                    }}
                                    style={{
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        backgroundColor: '#0f0d2e',
                                        transition: 'background-color 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                                    onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                                >
                                    No grouping
                                </div>
                                {selectedColumns.map(column => (
                                    <div
                                        key={column}
                                        onClick={() => {
                                            handleGroupByChange({ target: { value: column } });
                                            setIsGroupingOpen(false);
                                        }}
                                        style={{
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            color: 'white',
                                            backgroundColor: '#0f0d2e',
                                            transition: 'background-color 0.3s',
                                        }}
                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                                        onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                                    >
                                        {column.replace(/_/g, ' ')}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <ToolbarButton text="New Deal" onClick={() => setIsNewDealFormOpen(true)} style={{ marginLeft: '10px' }} />
                    <div style={{ marginLeft: '10px', width: '200px', position: 'relative', zIndex: 1002 }}>
                        <Select
                            isMulti
                            options={KKC_LEAD_OPTIONS}
                            value={selectedKKCLeads}
                            onChange={handleKKCLeadChange}
                            placeholder="Filter by KKC Lead"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: '30px',
                                    height: '30px',
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    height: '30px',
                                    padding: '0 6px',
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    margin: '0px',
                                }),
                                indicatorsContainer: (provided) => ({
                                    ...provided,
                                    height: '30px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                }),
                            }}
                        />
                    </div>
                    {isKKCLeadFilterActive}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        style={{
                            padding: '5px 10px',
                            height: '20px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    />
                </div>
            </div>
            <ColumnSelector
                isOpen={isColumnSelectorOpen}
                onClose={() => setIsColumnSelectorOpen(false)}
                columns={availableColumns}
                selectedColumns={selectedColumns}
                onApply={handleApplyColumns}
            />
            {isLoadingAll ? (
                <LoadingIndicator message="Loading deals..." />
            ) : (
                <div className="table-container">
                    <table>
                        {renderTableHeader()}
                        <tbody>
                            {renderGroupedData()}
                        </tbody>
                    </table>
                </div>
            )}
            {contextMenu !== null && (
                <div
                    style={{
                        position: 'fixed',
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX,
                        backgroundColor: '#0f0d2e',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                        borderRadius: '4px',
                        zIndex: 1001,
                        padding: '5px',
                    }}
                >
                    <div
                        style={{
                            padding: '8px 16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#1a1745'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = '#0f0d2e'}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDeal();
                        }}
                    >
                        Delete Deal
                    </div>
                </div>
            )}
            {isDealPanelOpen && (
                <DealDetailsPanel
                    deal={selectedDeal}
                    onClose={() => setIsDealPanelOpen(false)}
                />
            )}
            {isNewDealFormOpen && (
                <NewDealModal
                    isOpen={isNewDealFormOpen}
                    onClose={() => setIsNewDealFormOpen(false)}
                    onDealCreated={fetchAllData}
                />
            )}
        </div>
    );
}

export default Deals;