import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const storedAuth = localStorage.getItem('isAuthenticated');
        console.log('Initial isAuthenticated:', storedAuth);
        return storedAuth === 'true';
    });

    const [accessToken, setAccessToken] = useState(() => {
        const token = localStorage.getItem('accessToken');
        console.log('Initial accessToken:', token ? 'Present' : 'Not present');
        return token || null;
    });

    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user');
        console.log('Initial stored user:', storedUser);
        try {
            const parsedUser = storedUser ? JSON.parse(storedUser) : null;
            console.log('Parsed user:', parsedUser);
            return parsedUser;
        } catch (error) {
            console.error('Error parsing stored user:', error);
            return null;
        }
    });

    useEffect(() => {
        console.log('isAuthenticated changed:', isAuthenticated);
        localStorage.setItem('isAuthenticated', isAuthenticated.toString());
    }, [isAuthenticated]);

    useEffect(() => {
        console.log('User state updated:', user);
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    const login = useCallback((token, userData) => {
        console.log('Login called with:', { token, userData });
        if (!token || !userData) {
            console.error('Invalid login data');
            return;
        }
        setIsAuthenticated(true);
        setAccessToken(token);
        setUser(userData);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('accessToken', token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('authTimestamp', Date.now().toString());
    }, []);

    const logout = useCallback(() => {
        console.log('Logout called');
        setIsAuthenticated(false);
        setAccessToken(null);
        setUser(null);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        localStorage.removeItem('authTimestamp');
    }, []);

    useEffect(() => {
        const checkAuthExpiration = () => {
            const authTimestamp = localStorage.getItem('authTimestamp');
            if (authTimestamp) {
                const currentTime = Date.now();
                const authTime = parseInt(authTimestamp, 10);
                const timeDifference = currentTime - authTime;
                const hoursDifference = timeDifference / (1000 * 60 * 60);

                if (hoursDifference > 24) {
                    console.log('Auth expired, logging out');
                    logout();
                }
            }
        };

        const interval = setInterval(checkAuthExpiration, 60000);
        return () => clearInterval(interval);
    }, [logout]);

    const value = {
        isAuthenticated,
        accessToken,
        user,
        login,
        logout,
    };

    console.log('AuthContext value:', value);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};