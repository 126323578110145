import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LeftPanel from './LeftPanel';
import People from './People';
import Deals from './Deals';
import Companies from './Companies';
import Company from './Company';
import Login from './Login';
import { AuthProvider, useAuth } from './AuthContext';
import './App.css';
import './global.css';
import Modal from 'react-modal';
import Deal from './Deal';
import { Helmet } from 'react-helmet';
import { ApiProvider } from './ApiContext';
import OutboundEngine from './OutboundEngine';
import { useState } from 'react';

Modal.setAppElement('#root');

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const AuthenticatedApp = () => {
  const { login, isAuthenticated, user } = useAuth();
  const [isLeftPanelVisible, setIsLeftPanelVisible] = useState(true);

  React.useEffect(() => {
    console.log('AuthenticatedApp useEffect running');
    const storedToken = localStorage.getItem('accessToken');
    const storedUser = localStorage.getItem('user');
    console.log('Stored token:', storedToken ? 'Present' : 'Not present');
    console.log('Stored user:', storedUser);
    if (storedToken && storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        console.log('Parsed user:', parsedUser);
        login(storedToken, parsedUser);
      } catch (error) {
        console.error('Error parsing stored user:', error);
      }
    } else {
      console.log('No stored token or user');
    }
  }, [login]);

  console.log('AuthenticatedApp render - isAuthenticated:', isAuthenticated, 'user:', user);

  return (
    <Router>
      <Helmet>
        <title>Kyber Knight Capital</title>
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/helmet.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/helmet.png" />
        <link rel="shortcut icon" href="/assets/images/helmet.png" />
        <link rel="apple-touch-icon" href="/assets/images/helmet.png" />
      </Helmet>
      <div className={`app-container ${isLeftPanelVisible ? '' : 'full-width'}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={
            <ProtectedRoute>
              <LeftPanel
                isVisible={isLeftPanelVisible}
                setIsVisible={setIsLeftPanelVisible}
              />
              <div className={`main-content ${isLeftPanelVisible ? '' : 'full-width'}`}>
                <Routes>
                  <Route path="/people" element={<People />} />
                  <Route path="/deals" element={<Deals />} />
                  <Route path="/outbound-engine" element={<OutboundEngine />} />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/" element={<Navigate to="/deals" />} />
                  <Route path="/company/:id" element={<Company />} />
                  <Route path="/deal/:id" element={<Deal />} />
                </Routes>
              </div>
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
};

function App() {
  return (
    <GoogleOAuthProvider clientId="119572221971-e4c3irutjq3v8cbomil7h9558ij16h5b.apps.googleusercontent.com">
      <AuthProvider>
        <ApiProvider>
          <AuthenticatedApp />
        </ApiProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;