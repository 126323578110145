import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import Select from 'react-select';
import './NewDealModal.css'; // Import the CSS file for styling
import { getBaseUrl } from './ApiContext';



const baseUrl = getBaseUrl();

const STAGE_OPTIONS = [
    'Top of Funnel',
    'Radar Screen',
    'Digging In',
    'Hunting License',
    'Approved',
    'Closed',
    'Hold',
    'Passed',
    'Track',
    'Passed but Shareable',
    'Gave Term Sheet but Lost'
];

const customSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#1a1433',
        borderColor: '#625896',
        color: 'white',
        padding: '12px',
        borderRadius: '8px',
        fontSize: '14px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#625896',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#1a1433',
        color: 'white',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#a0a0a0',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#625896' : state.isFocused ? '#1a1433' : '#1a1433',
        color: 'white',
        '&:hover': {
            backgroundColor: '#625896',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#625896',
        color: 'white',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'white',
        '&:hover': {
            backgroundColor: '#7a6eb8',
            color: 'white',
        },
    }),
};

const NewDealModal = ({ isOpen, onClose, onDealCreated }) => {
    const [newDealData, setNewDealData] = useState({
        company_name: '',
        company_id: '',
        founder_ids: [],
        stage: '',
        kkc_lead: '',
        source: '',
    });
    const [companies, setCompanies] = useState([]);
    const [allPeople, setAllPeople] = useState([]); // State to store all people
    const [founderOptions, setFounderOptions] = useState([]); // State for founder options
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isOpen) {
            fetchCompanies();
            fetchAllPeople(); // Fetch all people when modal opens
        }
    }, [isOpen]);

    const fetchCompanies = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/companies`, {
                params: { fetch_all: true }
            });
            const companyOptions = response.data.companies.map(company => ({
                value: company.id,
                label: company.name
            }));
            setCompanies(companyOptions);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setError('Failed to load companies. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllPeople = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/people`, {
                params: { fetch_all: true }
            });
            const people = Array.isArray(response.data.people) ? response.data.people : [];
            setAllPeople(people);
            setFounderOptions(people.map(person => ({
                value: person.id,
                label: formatPersonLabel(person), // Ensure label is set
                email: person.email || '',
                linkedin: person.linkedin || '',
                location: person.location || '',
                title: person.title || ''
            })));
        } catch (error) {
            console.error('Error fetching people:', error);
            setError('Failed to load people data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatPersonLabel = (person) => {
        const name = `${person.first_name || ''} ${person.last_name || ''}`.trim();
        const email = person.email ? `(${person.email})` : '';
        const location = person.location ? `[${person.location}]` : '';
        const title = person.title ? `- ${truncateTitle(person.title)}` : '';
        return `${name} ${email} ${location} ${title}`.trim();
    };

    const truncateTitle = (title) => {
        if (title.length <= 20) return title;
        return title.substring(0, 20) + '...';
    };

    const searchPeople = (inputValue) => {
        if (inputValue.length < 2) {
            // Instead of setting to an empty array, we'll keep the selected options
            setFounderOptions(prevOptions =>
                prevOptions.filter(option => newDealData.founder_ids.includes(option.value))
            );
            return;
        }
        const filteredOptions = allPeople.filter(person => {
            const fullName = `${person.first_name || ''} ${person.last_name || ''}`.toLowerCase();
            const searchTerm = inputValue.toLowerCase();
            return fullName.includes(searchTerm) ||
                (person.email && person.email.toLowerCase().includes(searchTerm)) ||
                (person.first_name && person.first_name.toLowerCase().includes(searchTerm)) ||
                (person.last_name && person.last_name.toLowerCase().includes(searchTerm)) ||
                (person.location && person.location.toLowerCase().includes(searchTerm)) ||
                (person.title && person.title.toLowerCase().includes(searchTerm));
        }).map(person => ({
            value: person.id,
            label: formatPersonLabel(person),
            email: person.email || '',
            linkedin: person.linkedin || '',
            location: person.location || '',
            title: person.title || ''
        }));

        // Combine filtered options with selected options
        const selectedOptions = founderOptions.filter(option => newDealData.founder_ids.includes(option.value));
        setFounderOptions([...new Set([...selectedOptions, ...filteredOptions])]);
    };

    const handleNewDealInputChange = (e) => {
        const { name, value } = e.target;
        setNewDealData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCompanyChange = (selectedOption) => {
        setNewDealData(prevData => ({
            ...prevData,
            company_id: selectedOption ? selectedOption.value : '',
            company_name: selectedOption ? selectedOption.label : ''
        }));
    };

    const handleFounderChange = (selectedOptions) => {
        const selectedFounderIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setNewDealData(prevData => ({
            ...prevData,
            founder_ids: selectedFounderIds
        }));

        // Update founderOptions to include the selected options
        setFounderOptions(prevOptions => {
            const newOptions = [...prevOptions];
            selectedOptions.forEach(option => {
                if (!newOptions.some(existingOption => existingOption.value === option.value)) {
                    newOptions.push(option);
                }
            });
            return newOptions;
        });

        // Set company name to "Stealth (Founder Name)" if no company is selected
        if (!newDealData.company_id && selectedOptions.length > 0) {
            const founderName = selectedOptions[0].label.split(' ')[0]; // Get first name of first founder
            setNewDealData(prevData => ({
                ...prevData,
                company_name: `Stealth (${founderName})`
            }));
        }
    };

    const handleNewDealSubmit = async (e) => {
        e.preventDefault();
        try {
            let formattedData = {
                ...newDealData,
                founder_ids: `[${newDealData.founder_ids.join(',')}]`,
                company_id: newDealData.company_id || null
            };

            // If no company is selected, use the "Stealth (Founder Name)" as company_name
            if (!formattedData.company_id && formattedData.founder_ids.length > 0) {
                const founderName = founderOptions.find(option => option.value === newDealData.founder_ids[0]).label;
                formattedData.company_name = `Stealth (${founderName})`;
            }

            formattedData.source = formattedData.source || 'Manual Entry';

            const response = await axios.post(`${baseUrl}/api/deals`, formattedData);
            console.log('New deal created:', response.data);
            onClose();
            setNewDealData({
                company_name: '',
                company_id: '',
                founder_ids: [],
                stage: '',
                kkc_lead: '',
                source: '',
            });
            onDealCreated(); // Refresh the deals list
        } catch (error) {
            console.error('Error creating new deal:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="new-deal-modal" overlayClassName="new-deal-modal-overlay">
            <h2>Add New Company</h2>
            <form onSubmit={handleNewDealSubmit} className="new-deal-form">
                <div className="input-group">
                    <label>Company Name:</label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="company"
                        value={companies.find(option => option.value === newDealData.company_id)}
                        onChange={handleCompanyChange}
                        options={companies}
                        isClearable
                        placeholder="Select Company"
                        isLoading={isLoading}
                        styles={customSelectStyles} // Apply custom styles
                    />
                </div>
                <div className="input-group">
                    <label>Founder IDs:</label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="founders"
                        value={founderOptions.filter(option => newDealData.founder_ids.includes(option.value))}
                        onChange={handleFounderChange}
                        options={founderOptions}
                        isMulti
                        isClearable
                        placeholder="Search and select founders"
                        isLoading={isLoading}
                        onInputChange={searchPeople} // Ensure this prop is set
                        noOptionsMessage={({ inputValue }) => inputValue.length < 2 ? "Type to search" : "No options"}
                        styles={customSelectStyles} // Apply custom styles
                    />
                </div>
                <div className="input-group">
                    <label>Stage:</label>
                    <select
                        name="stage"
                        value={newDealData.stage}
                        onChange={handleNewDealInputChange}
                        required
                    >
                        <option value="">Select Stage</option>
                        {STAGE_OPTIONS.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="input-group">
                    <label>KKC Lead:</label>
                    <select
                        name="kkc_lead"
                        value={newDealData.kkc_lead}
                        onChange={handleNewDealInputChange}
                    >
                        <option value="">Select KCC Lead</option>
                        {['none', 'SS', 'AV', 'LL', 'SD', 'Kyber Drive'].map(option => (
                            <option key={option} value={option === 'none' ? '' : option}>
                                {option === 'none' ? 'None' : option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="input-group">
                    <label>Source:</label>
                    <input
                        type="text"
                        name="source"
                        value={newDealData.source}
                        onChange={handleNewDealInputChange}
                    />
                </div>
                <div className="action-buttons">
                    <button type="button" className="cancel-btn" onClick={onClose}>Cancel</button>
                    <button type="submit" className="create-btn">Create Deal</button>
                </div>
            </form>
        </Modal>
    );
};

export default NewDealModal;